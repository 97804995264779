import React, { useState, useEffect } from 'react';
import { Form, Button, Select, DatePicker, message, Spin, InputNumber } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const PayEmployeeSalary = ( { onClose, fetchData } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ listKaryawan, setListKaryawan ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ employeeId, setEmployeeId ] = useState( null );

    useEffect( () =>
    {
        const fetchListLahan = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/employee/list` );
                setListKaryawan( response.data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal memuat data dari server' );
            } finally
            {
                setLoading( false );
            }
        };
        fetchListLahan();
    }, [] );
    const handleListKaryawan = ( value ) =>
    {
        const employee = listKaryawan.find( ( data ) => data.employeeName === value );
        form.setFieldsValue( {
            employeeName: employee.employeeName,
            basicSalary: employee.basicSalary,
        } );
        setEmployeeId( employee._id );
    };
    const handleClose = () =>
    {
        form.resetFields();
        onClose();
        fetchData();
    };
    const onFinish = async ( values ) =>
    {
        const salaryData = {
            salary: values.basicSalary,
            additionalSalary: values.additionalSalary,
            dateSalary: dayjs( values.dateSalary ).format( 'DD/MM/YYYY' ),
            salaryMethod: values.salaryMethod,
            salaryForMonth: values.salaryForMonth,
        };
        try
        {
            setLoading( true );
            const req = await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/employee/${ employeeId }/salary`, salaryData );
            message.success( req.data.message );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( error.response.data.message );
            console.error( error )
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <Form.Item
                    style={ { width: '100%' } }
                    label="Nama Karyawan"
                    name="employeeName"
                    rules={ [ { required: true, message: 'Nama karyawan diperlukan!' } ] }
                >
                    <Select
                        placeholder="Pilih Nama Karyawan"
                        onChange={ handleListKaryawan }
                        options={ listKaryawan.map( item => ( {
                            value: item.employeeName,
                            label: item.employeeName
                        } ) ) }
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="basicSalary"
                    label="Gaji Pokok"
                    rules={ [ { required: true, message: 'Gaji pokok diperlukan!' } ] }
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder='Masukkan Gaji Pokok'
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="additionalSalary"
                    label="Tambahan Gaji"
                    tooltip='Tidak Wajib'
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder='Masukkan Tambahan Gaji'
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="salaryMethod"
                    label="Metode Bayar"
                    rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                >
                    <Select
                        style={ { width: '100%' } }
                        placeholder='Pilih Metode Bayar'
                        options={ [
                            { value: 'TUNAI', label: 'TUNAI' },
                            { value: 'BRI', label: 'BRI' },
                            { value: 'BCA', label: 'BCA' },
                            { value: 'BNI', label: 'BNI' },
                        ] }
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="dateSalary"
                    label="Tanggal Gajian"
                    rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                >
                    <DatePicker
                        placeholder='Pilih Tanggal'
                        style={ { width: '100%' } }
                        format="DD/MM/YYYY"
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="salaryForMonth"
                    label="Gaji Bulan"
                    rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                >
                    <DatePicker.MonthPicker
                        placeholder='Pilih Tanggal'
                        style={ { width: '100%' } }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    />
                </Form.Item>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Button type="primary" htmlType="submit">
                        Bayar Gaji
                    </Button>
                    <Button onClick={ handleClose }>Batal</Button>
                </div>
            </Form>
        </Spin>
    );
};

export default PayEmployeeSalary;
