import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const NewClient = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ data, setData ] = useState( [] );

    const fetchListData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list` );
            const data = response.data;
            setData( data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchListData();
    }, [] );

    const onFinish = async ( values ) =>
    {
        const isDuplicate = data.some( ( nasabah ) => nasabah.namaNasabah === values.namaNasabah );
        if ( isDuplicate )
        {
            message.error( `Data nasabah ${ values.namaNasabah } sudah ada` );
            return;
        }

        const dataNasabah = {
            ...values,
            namaNasabah: values.namaNasabah,
        };

        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/loan/list`, dataNasabah );
            message.success( 'Nasabah baru berhasil ditambahkan' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat menyimpan data' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <Form.Item
                    style={ { width: '100%' } }
                    label="Nama Nasabah"
                    name="namaNasabah"
                    rules={ [ { required: true, message: 'Nama diperlukan!' } ] }
                    normalize={ ( value ) => value && value.toUpperCase() }
                >
                    <Input placeholder="Masukkan Nama Nasabah" />
                </Form.Item>
                <Button type="primary" htmlType="submit" >
                    Tambah Nasabah
                </Button>
            </Form>
        </Spin>
    );
};

export default NewClient;
