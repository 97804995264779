import React, { useState } from 'react';
import { Form, Button, DatePicker, message, Spin, InputNumber, Input, Select } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const AddAdditionalIncome = ( { onClose, fetchData } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const onFinish = async ( values ) =>
    {
        const pttData = {
            ...values,
            dateGet: dayjs( values.dateGet ).format( 'DD/MM/YYYY' )
        }
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/additionalIncome/data`, pttData );
            message.success( 'Pengiriman data berhasil' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            onClose();
            console.error( 'Terjadi kesalahan saat menyimpan data:', error );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Sumber pemasukan"
                        name="ofWhom"
                        rules={ [ { required: true, message: 'Sumber diperlukan!' } ] }
                    >
                        <Input placeholder="Masukkan Sumber" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="dateGet"
                        label="Tanggal Pemasukan"
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker
                            placeholder='Pilih Tanggal'
                            style={ { width: '100%' } }
                            format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="howMuch"
                        label="Jumlah Pemasukan"
                        rules={ [ { required: true, message: 'Jumlah diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="incomeMethod"
                        label="Pilih Metode"
                        rules={ [ { required: true, message: 'Metode diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" >
                    Bayar
                </Button>
            </Form>
        </Spin>
    );
};

export default AddAdditionalIncome;
