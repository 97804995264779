import React, { useState } from 'react';
import { Form, Button, message, Spin, Input, InputNumber, Select, Modal } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const NewPlotList = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const onFinish = async ( values ) =>
    {
        const newPlot = {
            ...values,
            statusKav: 'READY',
            block: values.block || 'TANPABLOK',
        };
        const plotVerification = () =>
        {
            Modal.confirm( {
                title: 'Apakah data kavling sudah benar ?',
                content: (
                    <>
                        <p>Lokasi Kavling: { values.lokasiKav }</p>
                        <p>Blok Kavling: { values.block ? `${ values.block }${ values.number }` : values.number }</p>
                        <p>Ukuran Kavling: { values.sizeKav }</p>
                        <p>Luas Kavling: { values.luasKav } M²</p>
                        <p>Harga Kavling: { convertToIDR( values.hargaKav ) }</p>
                    </>
                ),
                onOk: async () =>
                {
                    try
                    {
                        setLoading( true );
                        await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/data`, newPlot );
                        message.success( 'Kavling baru berhasil ditambahkan' );
                        form.resetFields();
                        onClose();
                        fetchData();
                    } catch ( error )
                    {
                        if ( error.response && error.response.status === 400 )
                        {
                            message.error( error.response.data.message );
                        } else
                        {
                            message.error( 'Terjadi kesalahan saat menyimpan data' );
                        }
                    } finally
                    {
                        setLoading( false );
                    }
                },
                onCancel ()
                {
                    message.info( 'Penambahan kavling dibatalkan' );
                },
            } );
        };
        plotVerification();
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Lokasi Kavling"
                        name="lokasiKav"
                        rules={ [ { required: true, message: 'Lokasi Kavling diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Lokasi Kavling"
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Blok Kavling"
                        name="block"
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input maxLength={ 2 } placeholder="Masukkan Blok Kavling" />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        label="Nomor Kavling"
                        style={ { width: '100%' } }
                        name="number"
                        rules={ [
                            {
                                required: true,
                                message: 'Nomor Kavling wajib diisi',
                            },
                            {
                                pattern: /^[a-zA-Z0-9]*$/,
                                message: 'Nomor tidak valid!',
                            },
                        ] }
                    >
                        <Input
                            maxLength={ 3 }
                            placeholder="Masukkan Nomor Kavling"
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Ukuran Kavling"
                        name="sizeKav"
                        rules={ [
                            {
                                required: true,
                                message: 'Ukuran Kavling wajib diisi',
                            },
                            {
                                pattern: /^[0-9X,\s]*$/,
                                message: 'Ukuran tidak valid!',
                            },
                        ] }
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder="Masukkan Ukuran Kavling" />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Luas Kavling"
                        name="luasKav"
                        rules={ [
                            {
                                required: true,
                                message: 'Luas Kavling wajib diisi',
                            },
                            {
                                pattern: /^[0-9,]*$/,
                                message: 'Luas tidak valid!',
                            },
                        ] }
                    >
                        <Input placeholder="Masukkan Luas Kavling" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Harga Kavling"
                        name="hargaKav"
                        rules={ [ { required: true, message: 'Harga Kavling diperlukan!' } ] }
                    >
                        <InputNumber style={ { width: '100%' } } min={ 1 } placeholder="Masukkan Harga Kavling" />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit">
                    Tambah Kavling
                </Button>
            </Form>
        </Spin>
    );
};

export default NewPlotList;
