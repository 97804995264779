import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, message, Modal, Typography, Popconfirm, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Authentication/contexts/authContext';
import { PiKeyThin } from 'react-icons/pi';
import CreateAxiosInstance from '../../Authentication/contexts/axiosConfig';
const InputAccessPin = () =>
{
    const { logout, userData } = useAuth();
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ pin, setPin ] = useState( '' );
    const [ isModalVisible, setIsModalVisible ] = useState( false );
    const [ countdown, setCountdown ] = useState( 10 );
    const navigate = useNavigate();
    const pinInputRef = useRef( null );

    const handleSubmit = async () =>
    {
        if ( pin )
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.post( `${ apiUrl }/api/access-pin/validate`, { pin } );
                if ( response.status === 200 )
                {
                    localStorage.setItem( 'access_key', JSON.stringify( { keyAccess: pin } ) );
                    setIsModalVisible( true );
                } else
                {
                    message.error( 'PIN salah, coba lagi!' );
                }
            } catch ( error )
            {
                console.error( error );
            } finally
            {
                setLoading( false );
            }
        } else
        {
            message.error( 'Masukkan pin!' );
        }
    };
    const handleLogout = () =>
    {
        logout();
        navigate( '/' );
    };

    useEffect( () =>
    {
        let timer;
        if ( isModalVisible )
        {
            setCountdown( 10 );
            timer = setInterval( () =>
            {
                setCountdown( ( prev ) =>
                {
                    if ( prev <= 1 )
                    {
                        clearInterval( timer );
                        setIsModalVisible( false );
                        message.success( 'PIN akses diterima!' );
                        navigate( '/' );
                        return 0;
                    }
                    return prev - 1;
                } );
            }, 1000 );
        }
        return () => clearInterval( timer );
    }, [ isModalVisible, navigate ] );

    const handleRequestPin = async () =>
    {
        setLoading( true );
        try
        {
            const req = await axiosConfig.get( `${ apiUrl }/api/access-pin/generate` );
            message.success( req.data.message );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    const handleModalPin = () =>
    {
        Modal.confirm( {
            title: 'Masukkan PIN Verifikasi Untuk Mendapatkan PIN Akses Login',
            content: (
                <Input
                    placeholder="Masukkan Pin"
                    style={ { width: '100%' } }
                    ref={ pinInputRef }
                />
            ),
            onOk: async () =>
            {
                const pinValue = pinInputRef.current?.input.value;
                try
                {
                    const response = await axiosConfig.post( `${ apiUrl }/api/access-pin/verification`, {
                        PIN: pinValue,
                    } );
                    message.success( response.data.message );
                } catch ( error )
                {
                    console.error( error );
                };
            },
            onCancel: () => { },
        } );
    };

    return (
        <Spin spinning={ loading }>
            <div style={ { width: '100vw', marginTop: -25, display: 'grid', gridTemplateColumns: '1,1fr', justifyContent: 'center' } }>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Input
                        type="password"
                        placeholder="Masukkan PIN"
                        value={ pin }
                        onChange={ ( e ) => setPin( e.target.value ) }
                        style={ { width: '30vw' } }
                    />
                    <Button type="primary" onClick={ handleSubmit } icon={ <PiKeyThin /> } />
                </div>
                <div className='infocust-flex'>
                    <Popconfirm
                        title={ `Logout dari akun ${ userData.name } ?` }
                        onConfirm={ handleLogout }
                        okText="Yes"
                        cancelText="No"
                        placement='right'
                    >
                        <Typography.Link style={ { width: 'fit-content' } }>Ganti akun ?</Typography.Link>
                    </Popconfirm>
                    <p style={ { color: 'rgb(66, 126, 250)' } }>/</p>
                    <Typography.Link onClick={ handleRequestPin }>Minta PIN</Typography.Link>
                    <p style={ { color: 'rgb(66, 126, 250)' } }>/</p>
                    <Typography.Link onClick={ handleModalPin }>Masukkan PIN</Typography.Link>
                </div>
                <Modal
                    title="Verifikasi PIN"
                    visible={ isModalVisible }
                    footer={ null }
                    closable={ false }
                >
                    <p>Waktu tersisa: { countdown } detik</p>
                </Modal>
            </div>
        </Spin>
    );
};

export default InputAccessPin;
