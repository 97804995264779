import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LOGO from '../../Function/LOGO.png';
import './webpay.css';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { Button, Modal, Result, Select, Table, message } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

dayjs.locale( 'id' );

const PaymentWeb = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const { code } = useParams();
    const [ billingData, setBillingData ] = useState( [] );
    const [ action, setAction ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ billingTable, setBillingTable ] = useState( [] );
    const navigate = useNavigate();

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };

    useEffect( () =>
    {
        const fetchBillingData = async () =>
        {
            try
            {
                const response = await axiosConfig.get( `${ apiUrl }/billing/payment/${ code }` );
                const data = response.data || [];
                setBillingData( data )
                setBillingTable( data.tableTagihan );
            } catch ( error )
            {
                if ( error.response.status === 404 )
                {
                    navigate( 'page-not-found' )
                }
                console.error( 'Gagal memuat data pembayaran', error );
            } finally
            {
                setLoading( false );
            }
        };
        fetchBillingData();
    }, [] );

    if ( billingData.sisaDenda === 0
        && ( ( billingData.kekuranganAngsuran
            - billingData.kelebihanAngsuran )
            - billingData.angsuranTidakBayar === 0 ) )
    {
        return (
            <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' } }>
                <Result
                    status="success"
                    title="Semua Tagihan Telah Dibayar"
                    subTitle={ `Tidak ada tagihan sampai bulan ${ dayjs( billingData.tanggalTagihan ).format( 'MMMM YYYY' ) }` }
                />
            </div>
        );
    }

    const billingColumns = [
        {
            title: 'No',
            align: 'center',
            key: 'no',
            width: '8%',
            render: ( _, __, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>({ billingTable.length }) Tagihan</p>,
            dataIndex: 'tagihan',
            key: 'tagihan',
            align: 'left',
        },
        {
            title: `Jumlah ${ convertToIDR( billingData.kekuranganAngsuran + billingData.angsuranTidakBayar ) }`,
            dataIndex: 'angsuran',
            key: 'jumlah',
            align: 'center',
            render: value => convertToIDR( value )
        },
    ];

    const handleSelectOption = ( value ) =>
    {
        setAction( value );
    };

    const handlePaymentTransfer = () =>
    {
        if ( !action )
        {
            message.info( 'Pilih Opsi!' );
            return;
        }

        Modal.info( {
            title: 'Rekening Pembayaran',
            content: (
                <div>
                    <p className='billing-payment-value'>
                        Jumlah Pembayaran { convertToIDR(
                            action === 'bulanIni'
                                ? billingData.angsuranCustomer
                                : action === 'semuaTagihan'
                                    ? billingData.angsuranTidakBayar + billingData.sisaDenda
                                    : action === 'bulanIni+semuaDenda'
                                        ? billingData.angsuranCustomer + billingData.sisaDenda
                                        : null
                        ) }
                    </p>
                    <Table
                        size='small'
                        pagination={ false }
                        bordered
                        style={ { width: 'fit-content' } }
                        dataSource={ [
                            { nama: 'Syahendra Rahmadany', bank: 'BRI', norek: '318001031953534' },
                            { nama: 'Syahendra Rahmadany', bank: 'BNI', norek: '0722829890' },
                            { nama: 'Syahendra Rahmadany', bank: 'MANDIRI', norek: '1780004059602' },
                        ] }
                        columns={ [
                            { title: <p style={ { textAlign: 'center' } }>Nama</p>, dataIndex: 'nama', align: 'left', width: 'auto' },
                            { title: 'BANK', dataIndex: 'bank', align: 'center', width: 'auto' },
                            { title: 'Nomor Rekening', dataIndex: 'norek', align: 'center', width: 'auto' },
                        ] }
                    />
                </div>
            ),
            onOk ()
            {
                Modal.info( {
                    title: 'Konfirmasi Pembayaran',
                    content: (
                        <p>
                            Jika Anda telah melakukan pembayaran transfer, mohon tunggu paling lama 1 x 24 jam
                            untuk perubahan status pada tagihan online.
                        </p>
                    ),
                    onOk ()
                    {
                        Modal.confirm( {
                            title: 'Konfirmasi Bukti Transfer',
                            content: 'Apakah Anda sudah mengirim bukti transfer?',
                            okText: 'Kirim Bukti Transfer',
                            cancelText: 'Belum',
                            onOk ()
                            {
                                const phoneNumber = '6281214516622';
                                const whatsappLink = `https://wa.me/${ phoneNumber }`;
                                window.open( whatsappLink, '_blank' );
                            },
                            onCancel ()
                            {
                                message.info( 'Segera lakukan pembayaran untuk menghindari denda' );
                            },
                        } );
                    }
                } );
            }
        } );
    };

    return (
        <section className='billing-section'>
            <div className='billing-box'>
                <img className='billing-img' src={ LOGO } alt='Logo' />
                <p className='billing-title'>Tagihan Bulanan</p>
                <div className='billing-div-customer'>
                    <p className='item-div-billing'>Nama Customer : { billingData.customerName }</p>
                    <p className='item-div-billing'>Blok Kavling : { billingData.customerBlock }</p>
                    <p className='item-div-billing'>Lokasi Kavling : { billingData.customerLocation }</p>
                    { billingData.kelebihanAngsuran > 0 ? (
                        <p className='item-div-billing'>Lebihan Angsuran Sebelumnya : { convertToIDR( billingData.kelebihanAngsuran ) }</p>
                    ) : null }
                </div>
                <Table
                    className='billing-table'
                    columns={ billingColumns }
                    dataSource={ billingTable }
                    size='small'
                    bordered
                    pagination={ false }
                    loading={ loading }
                />
            </div>
            <div className='billing-action'>
                <Select
                    onChange={ handleSelectOption }
                    placeholder='Pilih Opsi'
                    className='billing-select'
                    options={ [
                        { value: 'bulanIni', label: 'Bayar Bulan Ini' },
                        { value: 'bulanIni+semuaDenda', label: 'Bayar Bulan Ini + Lunasi Denda' },
                        { value: 'semuaTagihan', label: 'Lunasi Seluruh Tagihan' },
                    ] }
                />
                <Button
                    type='primary'
                    onClick={ handlePaymentTransfer }
                    disabled={ !action }
                >
                    Bayar
                </Button>
            </div>
        </section>
    );
};

export default PaymentWeb;
