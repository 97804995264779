import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Modal } from 'antd';
import '../payments.css';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrBefore );
dayjs.locale( 'id' );

const { Option } = Select;
const BayarAngsuran = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const updateData = response.data;
            setCustomers( updateData );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] );
    useEffect( () =>
    {
        setPlotBlockList( [] )
    }, [ plotLocations ] );
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasiKavling === value );
        const sortLokasi = lokasiKav.sort( ( a, b ) => a.blokKavling - b.blokKavling );
        setPlotLocations( sortLokasi );
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'namaCustomer',
            'angsKe',
            'jumlahByrAngs',
            'methodByrAngs',
            'tanggalByrAngs',
            'dendaAngs',
        ] );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
    }
    const handleBlokChange = ( value ) =>
    {
        const blokKav = plotLocations.filter( item => item.blokKavling === value );
        const sortBlok = blokKav.sort( ( a, b ) => a.noKavling - b.noKavling );
        setPlotBlockList( sortBlok );
        form.resetFields( [
            'noKavling',
            'namaCustomer',
            'angsKe',
            'jumlahByrAngs',
            'methodByrAngs',
            'tanggalByrAngs',
            'dendaAngs',
        ] )
        if ( blokKav.length > 0 )
        {
            message.success( `${ blokKav.length } nomor ditemukan` );
        } else
        {
            message.error( 'Tidak ada nomor ditemukan' );
        }
    }
    const handleKavlingNumber = ( value ) =>
    {
        const noKav = plotBlockList.find( ( item ) => item.noKavling === value );
        if ( !noKav )
        {
            message.error( 'Kavling tidak ditemukan' );
            return;
        }
        const angsuranData = noKav.pembayaranAngsuran;
        const batasAngsuran2 = noKav.batasAngsuran2 ? dayjs( noKav.batasAngsuran2, 'DD/MM/YYYY' ) : null;
        if ( angsuranData.length === 0 )
        {
            form.setFieldsValue( {
                namaCustomer: noKav.namaCustomer,
                angsKe: 1,
                jumlahByrAngs: noKav.angsuranKavling
            } );
            setCustomerId( noKav._id );
            return;
        }
        const lastAngsuran = angsuranData[ angsuranData.length - 1 ];
        const lastPaymentDate = dayjs( lastAngsuran.tanggalByrAngs, 'DD/MM/YYYY' );
        const hasSameMonthAsBatas = angsuranData.some( angsuran =>
            batasAngsuran2 &&
            dayjs( angsuran.tanggalByrAngs, 'DD/MM/YYYY' ).isSame( batasAngsuran2, 'month' )
        );
        if ( batasAngsuran2 && lastPaymentDate.isSame( batasAngsuran2, 'month' ) )
        {
            form.setFieldsValue( {
                namaCustomer: noKav.namaCustomer,
                angsKe: 1,
                jumlahByrAngs: noKav.angsuranKavling2
            } );
        } else
        {
            form.setFieldsValue( {
                namaCustomer: noKav.namaCustomer,
                angsKe: lastAngsuran.angsKe + 1,
                jumlahByrAngs: hasSameMonthAsBatas ? noKav.angsuranKavling2 : noKav.angsuranKavling
            } );
        }
        setCustomerId( noKav._id );
    };
    const onFinish = async ( values ) =>
    {
        const dataPayment = {
            ...values,
            angsKe: values.angsKe,
            methodByrAngs: values.methodByrAngs,
            jumlahByrAngs: values.jumlahByrAngs,
            tanggalByrAngs: dayjs( values.tanggalByrAngs ).format( 'DD/MM/YYYY' ),
            tanggalByrAngs2: values.tanggalByrAngs2 ? dayjs( values.tanggalByrAngs2 ).format( 'DD/MM/YYYY' ) : undefined
        }
        const paymentValidate = () =>
        {
            Modal.confirm( {
                title: 'Verifikasi Data Sebelum Dikirim',
                content: (
                    <div>
                        <p>Angsuran Ke: { dataPayment.angsKe }</p>
                        <p>Metode Pembayaran: { dataPayment.methodByrAngs }</p>
                        <p>Jumlah Pembayaran: { convertToIDR( dataPayment.jumlahByrAngs ) }</p>
                        <p>Tanggal Pembayaran: { dayjs( dataPayment.tanggalByrAngs, 'DD/MM/YYYY' ).format( 'dddd, DD MMMM YYYY' ) }</p>
                        { dataPayment.tanggalByrAngs2 && (
                            <p>Untuk Pembayaran Bulan: { dayjs( dataPayment.tanggalByrAngs2, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }</p>
                        ) }
                    </div>
                ),
                onOk: async () =>
                {
                    try
                    {
                        setLoading( true );
                        const response = await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/angsuran`, dataPayment );
                        form.resetFields();
                        message.success( 'Pembayaran berhasil ditambahkan' );
                        fetchCustomers();
                        onClose();
                        fetchData();
                    } catch ( error )
                    {
                        console.error( error );
                    } finally
                    {
                        setLoading( false );
                    }
                },
                onCancel ()
                {
                    message.info( 'Pengiriman data dibatalkan' );
                },
            } );
        };

        paymentValidate();
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }>
                            { plotBlockList.map( ( no ) => (
                                <Option key={ no._id } value={ no.noKavling }>
                                    { no.noKavling }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pembeli"
                    >
                        <Input placeholder="Nama Pembeli" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="angsKe"
                        label="Pembayaran Ke"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore='Ke'
                            placeholder="Pembayaran ke" />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrAngs"
                        label="Jumlah Angsuran"
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="methodByrAngs"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] } />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalByrAngs"
                        label="Tanggal Pembayaran"
                        rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                    >
                        <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalByrAngs2"
                        label="Untuk Pembayaran Bulan"
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            format="DD/MM/YYYY"
                            placeholder='Pilih Bulan' />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit">
                    Bayar
                </Button>
            </Form>
        </Spin>
    );
};

export default BayarAngsuran;
