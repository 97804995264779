import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Popconfirm } from 'antd';
import '../payments.css';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const EditDP = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const [ dataDp, setDataDp ] = useState( [] );
    const [ selectedYear, setSelectedYear ] = useState( [] )
    const [ paymentId, setPaymentId ] = useState( null );
    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const data = response.data;
            const updateData = data
            setCustomers( updateData );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] );
    useEffect( () =>
    {
        setPlotBlockList( [] );
    }, [ plotLocations ] )
    useEffect( () =>
    {
        setSelectedYear( [] );
    }, [ plotBlockList, plotLocations ] );
    const handleDelete = () =>
    {
        axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/dp/${ paymentId }` );
        message.success( 'Berhasil menghapus data angsuran' );
        fetchCustomers();
        form.resetFields();
        onClose();
        fetchData();
    };
    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasiKavling === value );
        setPlotLocations( lokasiKav );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            setPlotLocations( [] );
            message.error( 'Tidak ada blok ditemukan' );
        }
        form.resetFields( [
            'tahunPembayaran',
            'blokKavling',
            'noKavling',
            'namaCustomer',
            'listPembayaran',
            'jumlahByrDp',
            'metodeBayarDp',
            'tanggalByrDp',
        ] )
    }
    const handleBlokChange = ( value ) =>
    {
        const blokKav = plotLocations.filter( item => item.blokKavling === value );
        setPlotBlockList( blokKav );
        if ( blokKav.length > 0 )
        {
            message.success( `${ blokKav.length } nomor ditemukan` );
        } else
        {
            setPlotBlockList( [] );
            message.error( 'Tidak ada nomor ditemukan' );
        }
        form.resetFields( [
            'tahunPembayaran',
            'noKavling',
            'namaCustomer',
            'listPembayaran',
            'jumlahByrDp',
            'metodeBayarDp',
            'tanggalByrDp',
        ] );
    }
    const handleKavlingNumber = ( value ) =>
    {
        const noKav = plotBlockList.find( ( item ) => item.noKavling === value );
        const dpData = noKav.pembayaranDP;
        form.setFieldsValue( {
            namaCustomer: noKav.namaCustomer,
        } )
        setDataDp( dpData );
        setCustomerId( noKav._id );
        if ( dpData.length > 0 )
        {
            message.success( `${ dpData.length } pembayaran ditemukan` );
        } else
        {
            setDataDp( [] );
            message.error( 'Tidak ada pembayaran ditemukan' );
        }
        form.resetFields( [
            'tahunPembayaran',
            'jumlahByrDp',
            'listPembayaran',
            'metodeBayarDp',
            'tanggalByrDp',
        ] );
    }
    const handleYearChange = ( date ) =>
    {
        const filterTahun = dataDp.filter( item => dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).isSame( date, 'year' ) );
        const sortYearData = filterTahun.sort( ( a, b ) => a.dpKe - b.dpKe );
        setSelectedYear( sortYearData );
        if ( filterTahun.length > 0 )
        {
            message.success( `${ filterTahun.length } pembayaran ditemukan` );
        } else
        {
            setSelectedYear( [] );
            message.error( 'Tidak ada pembayaran ditemukan' );
        }
        form.resetFields( [
            'listPembayaran',
            'jumlahByrDp',
            'metodeBayarDp',
            'dendaAngs',
            'tanggalByrDp'
        ] );
    }
    const handleChangePayment = ( value ) =>
    {
        const paymentList = selectedYear.find( item => item._id === value );
        form.setFieldsValue( {
            jumlahByrDp: paymentList.jumlahByrDp,
            tanggalByrDp: dayjs( paymentList.tanggalByrDp, 'DD/MM/YYYY' ),
            metodeBayarDp: paymentList.metodeBayarDp,
            dendaAngs: paymentList.dendaAngs,
        } );
        setPaymentId( value );
    }
    const onFinish = async ( values ) =>
    {
        const dataPayment = {
            ...values,
            metodeBayarDp: values.metodeBayarDp,
            jumlahByrDp: values.jumlahByrDp,
            tanggalByrDp: dayjs( values.tanggalByrDp ).format( 'DD/MM/YYYY' ),
        }
        try
        {
            setLoading( true );
            const req = await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/dp/${ paymentId }`, dataPayment );
            form.resetFields();
            message.success( req.data.message );
            fetchCustomers();
            onClose();
            fetchData()
        } catch ( error )
        {
            message.error( error.response.data.message );
        } finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }
                            options={ plotBlockList.map( li => (
                                { value: li.noKavling, label: li.noKavling }
                            ) ) }
                        />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pembeli"
                    >
                        <Input placeholder="Nama Pembeli" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Tahun Pembayaran"
                        name='tahunPembayaran'
                    >
                        <DatePicker.YearPicker
                            style={ { width: '100%' } }
                            onChange={ handleYearChange }
                            placeholder="Pilih Tahun Pembayaran"
                            allowClear={ false }
                            format='YYYY' />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="listPembayaran"
                        label="List Pembayaran"
                    >
                        <Select
                            placeholder='Pilih List Pembayaran'
                            onChange={ handleChangePayment }
                            style={ { width: '100%' } }>
                            { selectedYear.map( ( list ) => (
                                <Option value={ list._id }>
                                    { list.dpKe }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrDp"
                        label="Jumlah Bayar"
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan' } ] }
                    >
                        <InputNumber addonBefore='Rp' style={ { width: '100%' } } placeholder='Masukkan Jumlah Pembayaran' />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeBayarDp"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalByrDp"
                        label="Tanggal Pembayaran"
                        rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                    >
                        <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Button type="primary" htmlType="submit">
                        Update Pembayaran
                    </Button>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        { paymentId && (
                            <Button danger type='default' dangerloading={ loading }>
                                Hapus
                            </Button>
                        ) }
                    </Popconfirm>
                </div>
            </Form>
        </Spin>
    );
};

export default EditDP;
