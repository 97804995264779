import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input, Select, InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const NewLoan = ( { onClose, fetchData } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ data, setData ] = useState( [] );
    const [ clientId, setClientId ] = useState( null );
    useEffect( () =>
    {
        const fetchListData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list` );
                const data = response.data;
                setData( data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };
        fetchListData();
    }, [] )
    const handleSelectClient = ( value ) =>
    {
        if ( value )
        {
            const dataNasabah = data.find( item => item._id === value );
            setClientId( dataNasabah._id );
        }
    }
    const onFinish = async ( values ) =>
    {
        const pinjamanbaru = {
            ...values,
            loanValue: values.loanValue,
            loanDate: dayjs( values.loanDate ).format( 'DD/MM/YYYY' ),
            loanMethod: values.loanMethod,
            loanDescription: values.loanDescription,
            loanAssurance: values.loanAssurance,
        };
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ clientId }/newLoan`, pinjamanbaru );
            message.success( 'Pinjaman baru berhasil ditambahkan' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Pinjaman baru gagal ditambahkan' );
        }
        finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Nama Nasabah"
                        name="namaNasabah"
                        rules={ [ { required: true, message: 'Pilihan diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Nasabah"
                            style={ { width: '100%' } }
                            allowClear
                            showSearch
                            onChange={ handleSelectClient }>
                            { data.map( nama => (
                                <Option key={ nama._id } value={ nama._id }>
                                    { nama.namaNasabah }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='loanDate'
                        label='Tanggal Pinjaman'
                        rules={ [ { required: true, message: 'Tanggal pinjaman diperlukan!' } ] }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal Pinjaman'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='loanValue'
                        label='Jumlah Pinjaman'
                        rules={ [ { required: true, message: 'Jumlah pinjaman diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah Pinjaman' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='loanMethod'
                        label='Metode Pinjaman'
                        rules={ [ { required: true, message: 'Jumlah pinjaman diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode Pinjaman'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] } />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='loanAssurance'
                        label='Jaminan Pinjaman'
                        tooltip='Tidak Wajib'
                    >
                        <Input
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jaminan Pinjaman' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='loanDescription'
                        label='Keperluan Pinjaman'
                        tooltip='Tidak Wajib'
                    >
                        <Input
                            style={ { width: '100%' } }
                            placeholder='Masukkan Keperluan Pinjaman' />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit">
                    Tambah Pinjaman
                </Button>
            </Form>
        </Spin>
    );
};

export default NewLoan;
