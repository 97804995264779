import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Modal } from 'antd';
import '../payments.css';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const { Option } = Select;
const PayCashTempo = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const updateData = response.data
                .filter( f => f.jenisPembelian === 'CASH' || f.jenisPembelian === 'CASH TEMPO' );
            setCustomers( updateData );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] );
    useEffect( () =>
    {
        setPlotBlockList( [] )
    }, [ plotLocations ] );
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasiKavling === value );
        const sortLokasi = lokasiKav.sort( ( a, b ) => a.blokKavling - b.blokKavling );
        setPlotLocations( sortLokasi );
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'namaCustomer',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
    }
    const handleBlokChange = ( value ) =>
    {
        const blokKav = plotLocations.filter( item => item.blokKavling === value );
        const sortBlok = blokKav.sort( ( a, b ) => a.noKavling - b.noKavling );
        setPlotBlockList( sortBlok );
        form.resetFields( [
            'noKavling',
            'namaCustomer',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] )
        if ( blokKav.length > 0 )
        {
            message.success( `${ blokKav.length } nomor ditemukan` );
        } else
        {
            message.error( 'Tidak ada nomor ditemukan' );
        }
    }
    const handleKavlingNumber = ( value ) =>
    {
        const noKav = plotBlockList.find( ( item ) => item.noKavling === value );
        const tempoData = noKav.pembayaranTempo;
        form.setFieldsValue( {
            namaCustomer: noKav.namaCustomer,
            bayarTempoKe: tempoData.length + 1,
        } );
        setCustomerId( noKav._id );
    }
    const onFinish = async ( values ) =>
    {
        const dataPayment = {
            ...values,
            bayarTempoKe: values.bayarTempoKe,
            metodeByrTempo: values.metodeByrTempo,
            jumlahByrTempo: values.jumlahByrTempo,
            tanggalByrTempo: dayjs( values.tanggalByrTempo ).format( 'DD/MM/YYYY' ),
        }
        const paymentValidate = () =>
        {
            Modal.confirm( {
                title: 'Verifikasi Data Sebelum Dikirim',
                content: (
                    <div>
                        <p>Angsuran Ke: { dataPayment.bayarTempoKe }</p>
                        <p>Metode Pembayaran: { dataPayment.metodeByrTempo }</p>
                        <p>Jumlah Pembayaran: { convertToIDR( dataPayment.jumlahByrTempo ) }</p>
                        <p>Tanggal Pembayaran: { dayjs( dataPayment.tanggalByrTempo, 'DD/MM/YYYY' ).format( 'dddd, DD MMMM YYYY' ) }</p>
                    </div>
                ),
                onOk: async () =>
                {
                    try
                    {
                        setLoading( true );
                        const response = await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/cashTempo`, dataPayment );
                        form.resetFields();
                        message.success( 'Pembayaran berhasil ditambahkan' );
                        fetchCustomers();
                        onClose();
                        fetchData();
                    } catch ( error )
                    {
                        message.error( 'Gagal mengirim data ke server' );
                    } finally
                    {
                        setLoading( false );
                    }
                },
                onCancel ()
                {
                    message.info( 'Pengiriman data dibatalkan' );
                },
            } );
        };

        paymentValidate();
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }>
                            { plotBlockList.map( ( no ) => (
                                <Option key={ no._id } value={ no.noKavling }>
                                    { no.noKavling }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pembeli"
                    >
                        <Input placeholder="Nama Pembeli" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bayarTempoKe"
                        label="Pembayaran Ke"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore='Ke'
                            placeholder="Pembayaran ke" />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrTempo"
                        label="Jumlah Pembayaran"
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeByrTempo"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] } />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="tanggalByrTempo"
                    label="Tanggal Pembayaran"
                    rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                >
                    <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Bayar
                </Button>
            </Form>
        </Spin>
    );
};

export default PayCashTempo;
