import { message } from 'antd';
import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ( { children } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const [ token, setToken ] = useState( null );
    const [ userData, setUserData ] = useState( null );
    const [ isAuthenticated, setAuthenticated ] = useState( false );
    const [ isLoading, setIsLoading ] = useState( true );

    const removeStorage = () =>
    {
        localStorage.removeItem( 'user_data' );
        localStorage.removeItem( 'access_key' );
    };

    useEffect( () =>
    {
        const storedData = localStorage.getItem( 'user_data' );
        if ( storedData )
        {
            try
            {
                const parsedData = JSON.parse( storedData );
                if ( parsedData.userToken && parsedData.user )
                {
                    const { userToken, user } = parsedData;
                    setToken( userToken );
                    setUserData( user );
                    setAuthenticated( true );
                } else
                {
                    throw new Error( 'Invalid stored data format' );
                }
            } catch ( error )
            {
                console.error( error );
                removeStorage();
            }
        }
        setIsLoading( false );
    }, [] );

    const login = ( newToken, newData ) =>
    {
        if ( newData && newData.name && newData.role )
        {
            localStorage.setItem(
                'user_data',
                JSON.stringify( { userToken: newToken, user: { ...newData, name: newData.name, role: newData.role } } )
            );
            setToken( newToken );
            setUserData( newData );
            setAuthenticated( true );
        } else
        {
            console.error( 'Invalid user data' );
        }
    };

    const logout = async () =>
    {
        setIsLoading( true );
        try
        {
            const logData = localStorage.getItem( 'user_data' );
            if ( logData )
            {
                const authData = JSON.parse( logData );
                const req = await axios.post( `${ apiUrl }/api/auth/logout`, { userMail: authData.user.email } );

                if ( req.status === 200 )
                {
                    setToken( null );
                    setUserData( null );
                    setAuthenticated( false );

                    removeStorage();
                    message.success( req.data.message );
                }
            } else
            {
                removeStorage();
            }
        } catch ( error )
        {
            if ( error.response.status )
            {
                message.error( error.response.data.error );
                removeStorage();
            } else
            {
                removeStorage();
                console.error( error );
            };
        } finally
        {
            setIsLoading( false );
        }
    };



    return (
        <AuthContext.Provider value={ { token, isAuthenticated, isLoading, login, logout, userData } }>
            { children }
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext( AuthContext );
