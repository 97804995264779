import { Card, Collapse, Progress, Spin, Statistic, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './statisticFinance.css'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Panel } = Collapse;
const FinanceStatistic = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ statisticData, setStatisticData ] = useState( [] );
    const fetchStatistic = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/statistic/data/statistic` );
            setStatisticData( response.data );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        };
    };

    useEffect( () =>
    {
        fetchStatistic();
    }, [] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    return (
        <Spin spinning={ loading }>
            <Typography.Title style={ { textAlign: 'center' } }>Statistik Keuangan</Typography.Title>
            <div className='layout-dashboardFinance'>
                <Collapse accordion defaultActiveKey='1' className='collapse'>
                    {/* <Panel header='Statistik Pembayaran Customer' key='0'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Piutang'
                                value={ `${ convertToIDR ( totaPinjamanDibayar ) }/${ convertToIDR ( totalDipinjam ) }` }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) } % ` }
                            percent={ ( totaPinjamanDibayar / totalDipinjam ) * 100 } />
                    </Panel> */}
                    <Panel header='Data Pembayaran Lahan' key='1'>
                        { statisticData.dataLahan?.map( ( lahan ) => (
                            <Card bordered className='statistic'>
                                <Statistic
                                    title={ `Pembayaran Lahan ${ lahan.landCode } - ${ lahan.farmerName }` }
                                    value={ ` Terbayar ${ convertToIDR( lahan.landPayment
                                        .reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) )
                                        } dari 
                                ${ convertToIDR( lahan.landPrice ) } ` }
                                />
                                <Progress
                                    format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                                    percent={ ( lahan.landPayment
                                        .reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) /
                                        lahan.landPrice ) * 100 } />
                            </Card>
                        ) ) }
                    </Panel>
                    <Panel header='Total Pembiayaan Material' key='2'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Material'
                                value={ convertToIDR( statisticData.totalPengeluaranMaterial ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Gaji Karyawan' key='4'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Gaji Karyawan'
                                value={ convertToIDR( statisticData.totalPengeluaranGajiKaryawan ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Tukang' key='5'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Gaji Tukang'
                                value={ convertToIDR( statisticData.totalPengeluaranGajiTukang ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Komisi' key='6'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Pembiayaan Komisi'
                                value={ convertToIDR( statisticData.totalSemuaKomisi ) }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                            percent={ ( statisticData.totalKomisiTerbayar / statisticData.totalSemuaKomisi ) * 100 } />
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Komisi Telah Di Ambil'
                                    value={ convertToIDR( statisticData.totalKomisiTerbayar ) } />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Komisi Belum Di Ambil'
                                    value={ convertToIDR( statisticData.totalSemuaKomisi - statisticData.totalKomisiTerbayar ) } />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembiayaan Pinjaman' key='7'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Pembiayaan Pinjaman'
                                value={ convertToIDR( statisticData.totalPinjaman ) }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                            percent={ ( statisticData.totalPembayaran / statisticData.totalPinjaman ) * 100 } />
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Telah Dibayar'
                                    value={ convertToIDR( statisticData.totalPembayaran ) }
                                />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Belum Dibayar'
                                    value={ convertToIDR( statisticData.totalPembayaran - statisticData.totalPinjaman ) }
                                />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembayaran Lain' key='8'>
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Jumlah Pembiayaan Media'
                                    value={ convertToIDR( statisticData.totalPengeluaranMedia?.totalPembayaranMedia ) } />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Jumlah Pembiayaan Lain'
                                    value={ convertToIDR( statisticData.totalPengeluaranMedia?.totalPembayaranLain ) } />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembiayaan Operasional Dan Lain-Lain' key='9'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan'
                                value={ convertToIDR( statisticData.totalPengeluaranLain2 ) }
                            />
                        </Card>
                    </Panel>
                </Collapse>
            </div>
        </Spin>
    );
};

export default FinanceStatistic;
