import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, InputNumber, DatePicker, Spin } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const TambahCustomer = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ kavlingList, setKavlingList ] = useState( [] );
    const [ listBlock, setListBlock ] = useState( [] );
    const [ propertyType, setPropertyType ] = useState();
    const [ typePay, setTypePay ] = useState();
    const [ idKavling, setIdKavling ] = useState();
    const [ plotNumbers, setPlotNumbers ] = useState( [] );
    const [ statusBuy, setStatusBuy ] = useState( null );
    useEffect( () =>
    {
        const fetchListKavling = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/data/filter` );
                const data = response.data;
                setKavlingList( data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal memuat data lahan' );
            } finally
            {
                setLoading( false );
            };
        };
        fetchListKavling();
    }, [ idKavling, onClose ] );

    useEffect( () =>
    {
        setPlotNumbers( [] );
    }, [ listBlock ] );

    const handleLokasiKavling = ( value ) =>
    {
        const dataLokasi = kavlingList.filter( ( list ) => list.lokasiKav === value );
        setListBlock( dataLokasi );
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'ukuranKavling',
            'luasKavling',
            'hargaKavling',

        ] );
        if ( dataLokasi.length > 0 )
        {
            message.success( `${ dataLokasi.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        };
    }
    const handleBlokKavling = ( value ) =>
    {
        const blockList = listBlock.filter( ( data ) => data.block === value );
        if ( blockList )
        {
            setPlotNumbers( blockList );
            form.resetFields( [
                'noKavling',
                'ukuranKavling',
                'luasKavling',
                'hargaKavling',
            ] );
            message.success( `${ blockList.length } nomor ditemukan` );
        } else
        {
            setPlotNumbers( [] );
            message.error( 'Tidak ada nomor ditemukan' );
        }
    };
    const handleNoKavling = ( value ) =>
    {
        const numbersPlot = plotNumbers.find( ( data ) => data.number === value );
        if ( numbersPlot )
        {
            form.setFieldsValue( {
                ukuranKavling: numbersPlot.sizeKav,
                luasKavling: numbersPlot.luasKav,
                hargaKavling: numbersPlot.hargaKav,
            } );
            setIdKavling( numbersPlot._id );
        } else
        {
            setIdKavling( null );
        }
    };
    const handleJenisProperty = ( value ) =>
    {
        form.resetFields( [
            'luasBangunanKavling'
        ] )
        setPropertyType( value );
    }
    const handlePaymentType = ( value ) =>
    {
        form.resetFields( [
            'tenorKavling',
            'angsuranKavling'
        ] )
        setTypePay( value );
    }
    const handleClear = () =>
    {
        form.resetFields();
    };
    const handleStatusBuy = ( value ) =>
    {
        setStatusBuy( value );
    };
    const onFinish = async ( values ) =>
    {
        if ( !idKavling )
        {
            message.error( 'Id Kavling tidak ditemukan' );
            return;
        }
        const newData = {
            namaCustomer: values.namaCustomer,
            alamatCustomer: values.alamatCustomer,
            telpCustomer: values.telpCustomer,
            lokasiKavling: values.lokasiKavling,
            blokKavling: values.blokKavling,
            jenisProperty: values.jenisProperty,
            tanggalBooking: dayjs( values.tanggalBooking, 'DD/MM/YYYY' ).format( 'DD/MM/YYYY' ),
            tanggalIJB: dayjs( values.tanggalIJB, 'DD/MM/YYYY' ).format( 'DD/MM/YYYY' ),
            jenisPembelian: values.jenisPembelian,
            ukuranKavling: values.ukuranKavling,
            luasKavling: values.luasKavling,
            luasBangunanKavling: values.luasBangunanKavling,
            hargaKavling: values.hargaKavling - ( values.potonganHarga || 0 ),
            uangMukaKavling: values.uangMukaKavling,
            angsuranKavling: values.angsuranKavling,
            tenorKavling: values.tenorKavling,
            jumlahUTJ: values.jumlahUTJ,
            potonganHarga: values.potonganHarga,
            noKavling: values.noKavling,
            statusPembelian: values.statusPembelian,
            methodByrUTJ: values.methodByrUTJ,
            air: values.air === 'ya' ? values.air : null,
        };
        const newStatus = {
            statusKav: values.statusPembelian === 'BATAL'
                ? 'SOLD'
                : 'READY'
        };
        try
        {
            setLoading( true );
            await axios.all( [
                axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/customer/newCustomer`, newData ),
                axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/status/${ idKavling }`, newStatus ),
            ] );
            message.success( 'Data customer baru berhasil ditambahkan' );
            fetchData();
            form.resetFields();
            onClose();
            setIdKavling( null );
        } catch ( error )
        {
            message.error( 'Gagal mengirim data customer ke server.' );
        }
        finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Lokasi Kavling'
                            onChange={ handleLokasiKavling }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok Kavling'
                            onChange={ handleBlokKavling }
                            allowClear
                        >
                            { Array.from( new Set( listBlock.map( list => list.block ) ) ).map( ( block, index ) => (
                                <Option key={ index } value={ block }>
                                    { block }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok Kavling'
                            onChange={ handleNoKavling }
                        >
                            { plotNumbers.map( ( list ) => (
                                <Option key={ list._id } value={ list.number }>
                                    { list.number }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="ukuranKavling"
                        label='Ukuran Kavling'
                    >
                        <Input
                            placeholder='Pilih Blok Kavling'
                            readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="hargaKavling"
                        label='Harga Kavling'
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Pilih Blok Kavling' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="luasKavling"
                        label='Luas Kavling'
                    >
                        <Input
                            placeholder='Pilih Blok Kavling'
                            readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="luasBangunanKavling"
                        label="Luas Bangunan"
                        rules={ [
                            {
                                validator: ( _, value ) =>
                                {
                                    if ( !value || /^[0-9,]*$/.test( value ) )
                                    {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject( new Error( 'Gunakan angka / koma!' ) );
                                },
                            },
                        ] }
                    >
                        <Input
                            disabled={ propertyType !== 'RUMAH' || !propertyType }
                            prefix="M²"
                            style={ { width: '100%' } }
                            placeholder="Masukkan Luas Bangunan"
                        />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pelanggan"
                        rules={ [ { required: true, message: 'Nama diperlukan!' } ] }
                    >
                        <Input placeholder='Masukkan Nama Pelanggan' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="alamatCustomer"
                        label="Alamat Pelanggan"
                        rules={ statusBuy !== 'BATAL' ? [ { required: true, message: 'Alamat diperlukan!' } ]
                            : [ { required: false } ] }
                    >
                        <Input placeholder='Masukkan Alamat Pelanggan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="telpCustomer"
                        label="Telepon Customer"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore='+62'
                            min={ 1 }
                            placeholder='Masukkan Nomor Telepon' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jenisProperty"
                        label="Jenis Properti"
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Properti'
                            onChange={ handleJenisProperty }
                            rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }

                            options={ [
                                { value: 'KAVLING', label: 'KAVLING' },
                                { value: 'RUMAH', label: 'RUMAH' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jenisPembelian"
                        label="Jenis Pembelian"
                        rules={ statusBuy !== 'BATAL' ? [ { required: true, message: 'Opsi diperlukan!' } ]
                            : [ { required: false } ] }
                    >
                        <Select
                            placeholder='Pilih Jenis'
                            allowClear
                            onChange={ handlePaymentType }
                            options={ [
                                { value: 'KREDIT', label: 'KREDIT' },
                                { value: 'CASH', label: 'CASH' },
                                { value: 'CASH TEMPO', label: 'CASH TEMPO' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="uangMukaKavling"
                        label="Uang Muka"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Uang Muka' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="angsuranKavling"
                        label="Angsuran"
                        rules={ typePay === 'KREDIT' || statusBuy !== 'BATAL' ? [ { required: true, message: 'Angsuran diperlukan!' } ] : [ { required: false } ] }
                    >
                        <InputNumber
                            disabled={ typePay !== 'KREDIT' }
                            style={ { width: '100%' } }
                            placeholder='Masukkan Angsuran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tenorKavling"
                        label="Jangka Waktu"
                        rules={ typePay === 'CASH' || !typePay || statusBuy === 'BATAL' ? [ { required: false } ]
                            : [ { required: true, message: 'Angsuran diperlukan!' } ] }
                    >
                        <InputNumber
                            disabled={ typePay === 'CASH' || !typePay }
                            style={ { width: '100%' } }
                            placeholder='Pilih Jangka Waktu'
                            suffix='Bulan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahUTJ"
                        label="Jumlah UTJ"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah UTJ' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="methodByrUTJ"
                        label="Metode Bayar UTJ"
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Metode Bayar UTJ'
                            options={ [
                                { value: 'CASH', label: 'CASH' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'BRI', label: 'BRI' }
                            ] } />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        name='statusPembelian'
                        label='Status Pembelian'
                        style={ { width: '100%' } }
                    >
                        <Select
                            placeholder='Pilih Status Pembelian'
                            onChange={ handleStatusBuy }
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'BELUM LUNAS', label: 'BELUM LUNAS' },
                                { value: 'LUNAS', label: 'LUNAS' },
                                { value: 'BATAL', label: 'BATAL' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="potonganHarga"
                        label="Jumlah Diskon"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah Potongan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalBooking"
                        label="Tanggal Booking"
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalIJB"
                        label="Tanggal IJB"
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>

                <Form.Item
                    label='Air'
                    name='air'
                    style={ { width: '100%' } }
                >
                    <Select
                        style={ { width: '100%' } }
                        placeholder='Pilih Opsi'
                        options={ [
                            { value: 'ya', label: 'YA' },
                            { value: 'tidak', label: 'TIDAK' },
                        ] } />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Tambah Customer
                    </Button>
                    <Button onClick={ handleClear } style={ { marginLeft: 5 } }>Bersihkan</Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default TambahCustomer;
