import React, { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import useLogin from '../hooks/useLogin';
import { Alert, Button, Card, Form, Input, message, Spin, Typography } from 'antd';
import { Navigate } from 'react-router-dom';
import './styleauth.css';
import axios from 'axios';

const LoginAuth = () =>
{
    const [ form ] = Form.useForm();
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const { isAuthenticated, login } = useAuth();
    const [ loading, setLoading ] = useState( false );

    if ( isAuthenticated )
    {
        return <Navigate to="/homepage" />;
    }

    const handleLogin = async ( values ) =>
    {
        setLoading( true );
        try
        {
            const response = await axios.post( `${ apiUrl }/api/auth/login`, values );
            const data = response.data;
            if ( data.token && data.user )
            {
                message.success( data.message );
                login( data.token, data.user );
            } else
            {
                throw new Error( 'Terjadi kesalahan saat mencoba login' );
            };
        } catch ( error )
        {
            if ( error.response.status === 404 )
            {
                message.error( error.response.data.error );
                const storageData = localStorage.getItem( 'user_data' );
                if ( storageData )
                {
                    localStorage.removeItem( 'user_data' );
                };
            } else if ( error.response.status === 400 )
            {
                message.error( error.response.data.error );
                const storageData = localStorage.getItem( 'user_data' );
                if ( storageData )
                {
                    localStorage.removeItem( 'user_data' );
                };
            };
            console.error( error )
        } finally
        {
            setLoading( false );
        };
    };

    return (
        <div className='auth-form'>
            <Card className='auth-container'>
                <div className='auth-head'>
                    <Typography.Text className='auth-title'>
                        Login
                    </Typography.Text>
                    <Typography.Text style={ { fontStyle: 'italic' } }>
                        Fortuna audaces iuvat
                    </Typography.Text>
                </div>
                <Form layout='vertical' form={ form } onFinish={ handleLogin } autoComplete='off'>
                    <Form.Item
                        label={ <span className='auth-label'>Email</span> }
                        name='email'
                        rules={ [
                            {
                                required: true,
                                message: 'Masukkan Email Akun Anda!',
                            },
                            {
                                type: 'email',
                                message: 'Email Tidak Valid!',
                            },
                        ] }
                    >
                        <Input size='large' placeholder='Masukkan Email Anda' />
                    </Form.Item>

                    <Form.Item
                        label={ <span className='auth-label'>Password</span> }
                        name='password'
                        rules={ [
                            {
                                required: true,
                                message: 'Masukkan Password Akun Anda!',
                            },
                        ] }
                    >
                        <Input.Password size='large' placeholder='Masukkan Password Anda' />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            className='auth-button'
                            loading={ loading }
                        >Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginAuth;
