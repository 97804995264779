import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Button, message, DatePicker, Spin, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditBayarMaterial = ( { fetchData, onClose, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ calculatePaidType, setCalculatePaidType ] = useState( '' );
    const [ savedValues, setSavedValues ] = useState( { hargaSatuan: null, unitMaterial: null, hargaMaterial: null } );
    const [ loading, setLoading ] = useState( false );
    useEffect( () =>
    {
        const fetchDataById = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/materials/list/${ recordId }` );
                const data = response.data;
                form.setFieldsValue( {
                    ...data,
                    tanggalBeliMaterial: data.tanggalBeliMaterial ? dayjs( data.tanggalBeliMaterial, 'DD/MM/YYYY' ) : null,
                } );
                setSavedValues( { hargaSatuan: data.hargaSatuan, unitMaterial: data.unitMaterial, hargaMaterial: data.hargaMaterial } );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId, form, onClose ] );
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/materials/list/${ recordId }` );
            onClose();
            message.success( 'Berhasil menghapus material' );
            fetchData();
        } catch ( error )
        {
            console.error( error );
            message.error( 'Gagal menghapus material' );
        }
    };

    const handleCalculatePaidTypeChange = ( value ) =>
    {
        if ( value === 'custom' )
        {
            setSavedValues( ( prevState ) => ( {
                ...prevState,
                hargaSatuan: form.getFieldValue( 'hargaSatuan' ),
                unitMaterial: form.getFieldValue( 'unitMaterial' ),
                hargaMaterial: form.getFieldValue( 'hargaMaterial' ),
            } ) );
            form.setFieldsValue( { hargaSatuan: null, unitMaterial: null, hargaMaterial: savedValues.hargaMaterial } );
        } else if ( value === 'auto' )
        {
            form.setFieldsValue( {
                hargaSatuan: savedValues.hargaSatuan,
                unitMaterial: savedValues.unitMaterial,
                hargaMaterial: null,
            } );
        }
        setCalculatePaidType( value );
    };
    const onFinish = async ( values ) =>
    {
        let finalValues = { ...values };

        if ( calculatePaidType === 'auto' )
        {
            const hargaSatuan = form.getFieldValue( 'hargaSatuan' );
            const unitMaterial = form.getFieldValue( 'unitMaterial' );
            if ( hargaSatuan && unitMaterial )
            {
                finalValues.hargaMaterial = hargaSatuan * unitMaterial;
            }
        } else if ( calculatePaidType === 'custom' )
        {
            finalValues.hargaSatuan = null;
            finalValues.unitMaterial = null;
        }

        const formattedValues = {
            ...finalValues,
            tanggalBeliMaterial: finalValues.tanggalBeliMaterial.format( 'DD/MM/YYYY' ),
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/materials/list/${ recordId }`, formattedValues );
            message.success( 'Data Berhasil Diperbarui' );
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal memperbarui data' );
        } finally
        {
            setLoading( false );
        }
    };
    return (
        <Spin spinning={ loading }>
            <Form layout='vertical' onFinish={ onFinish } form={ form }>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaToko'
                        label='Nama Toko Galangan'
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder='Masukkan Nama Toko' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaMaterial'
                        label='Nama Material'
                        rules={ [ { required: true, message: 'Nama material diperlukan!' } ] }
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder='Masukkan Nama Item' />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label='Jenis Kalkulasi'
                        name='calculatePaidType'
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Jenis Kalkulasi'
                            onChange={ handleCalculatePaidTypeChange }
                            options={ [
                                { value: 'auto', label: 'Auto' },
                                { value: 'custom', label: 'Kustom' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='hargaMaterial'
                        label='Jumlah Bayar'
                        rules={ [
                            calculatePaidType !== 'auto' ? { required: true, message: 'Jumlah bayar diperlukan!' } : { required: false }
                        ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            disabled={ calculatePaidType === 'auto' }
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='hargaSatuan'
                        label='Harga Satuan'
                        rules={ [
                            calculatePaidType !== 'custom' ? { required: true, message: 'Harga satuan diperlukan!' } : { required: false }
                        ] }
                    >
                        <InputNumber
                            disabled={ calculatePaidType === 'custom' }
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='satuanMaterial'
                        label='Nama Satuan'
                        rules={ [ { required: true, message: 'Satuan diperlukan!' } ] }
                    >
                        <Input placeholder='Masukkan Nama Satuan'
                        />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='unitMaterial'
                        label='Jumlah Unit'
                        rules={ [
                            calculatePaidType !== 'custom' ? { required: true, message: 'Jumlah unit diperlukan!' } : { required: false }
                        ] }
                    >
                        <Input
                            addonAfter='Pcs'
                            disabled={ calculatePaidType === 'custom' }
                            placeholder='Masukkan Jumlah Unit'
                            style={ { width: '100%' } }
                            step={ 0.1 }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='metodeBayarMaterial'
                        label='Metode Bayar'
                        rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Metode Bayar'
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name='tanggalBeliMaterial'
                    label='Tanggal Bayar'
                    rules={ [ { required: true, message: 'Tanggal bayar diperlukan' } ] }
                >
                    <DatePicker
                        placeholder='Pilih Tanggal'
                        format='DD/MM/YYYY'
                        style={ { width: '100%' } }
                    />
                </Form.Item>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary'  >
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='default' danger >
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin >
    );
};

export default EditBayarMaterial;
