import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input, InputNumber, Select, Popconfirm } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditPlotList = ( { onClose, fetchData, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );

    useEffect( () =>
    {
        const fetchPlotData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/${ recordId }` );
                form.setFieldsValue( response.data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data kavling' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchPlotData();
        }
    }, [ recordId, form, onClose ] );

    const onFinish = async ( values ) =>
    {
        const updatePlot = {
            statusKav: values.statusKav,
            number: values.number,
            block: values.block || 'TANPABLOK',
            luasKav: values.luasKav,
            sizeKav: values.sizeKav,
            lokasiKav: values.lokasiKav,
            hargaKav: values.hargaKav,
        }
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/${ recordId }`, updatePlot );
            onClose()
            message.success( 'Kavling berhasil diperbarui' );
            fetchData();
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Lokasi Kavling"
                        name="lokasiKav"
                        rules={ [ { required: true, message: 'Lokasi Kavling diperlukan!' } ] }
                    >
                        <Select placeholder="Pilih Lokasi Kavling"
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Blok Kavling"
                        name="block"
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input
                            maxLength={ 2 }
                            placeholder="Masukkan Blok Kavling" />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        label="Nomor Kavling"
                        style={ { width: '100%' } }
                        name="number"
                        rules={ [
                            {
                                required: true,
                                message: 'Nomor Kavling wajib diisi',
                            },
                            {
                                pattern: /^[a-zA-Z0-9]*$/,
                                message: 'Nomor tidak valid!',
                            },
                        ] }
                    >
                        <Input
                            maxLength={ 2 }
                            placeholder="Masukkan Nomor Kavling"
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Ukuran Kavling"
                        name="sizeKav"
                        rules={ [
                            {
                                required: true,
                                message: 'Ukuran Kavling wajib diisi',
                            },
                            {
                                pattern: /^[0-9X,\s]*$/,
                                message: 'Ukuran tidak valid!',
                            },
                        ] }
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder="Masukkan Ukuran Kavling" />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Luas Kavling"
                        name="luasKav"
                        rules={ [
                            {
                                required: true,
                                message: 'Luas Kavling wajib diisi',
                            },
                            {
                                pattern: /^[0-9,]*$/,
                                message: 'Luas tidak valid!',
                            },
                        ] }
                    >
                        <Input placeholder="Masukkan Luas Kavling" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Harga Kavling"
                        name="hargaKav"
                        rules={ [ { required: true, message: 'Harga Kavling diperlukan!' } ] }
                    >
                        <InputNumber style={ { width: '100%' } } min={ 1 } placeholder="Masukkan Harga Kavling" />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    label="Status Kavling"
                    name="statusKav"
                    rules={ [ { required: true, message: 'Status Kavling diperlukan!' } ] }
                >
                    <Select
                        style={ { width: '50%' } }
                        placeholder="Pilih Status Kavling"
                        options={ [
                            { value: 'READY', label: 'READY' },
                            { value: 'SOLD', label: 'SOLD' }
                        ] } />
                </Form.Item>
                <Popconfirm
                    title="Apakah kamu yakin melakukan update data ini ?"
                    onConfirm={ () => form.submit() }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type='primary' >
                        Update
                    </Button>
                </Popconfirm>
            </Form>
        </Spin>
    );
};

export default EditPlotList;
