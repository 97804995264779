import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrBefore );
dayjs.locale( 'id' );

const { Option } = Select;
const EditReturnedPay = ( { onClose, fetchData } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const [ customerList, setCustomerList ] = useState( [] );
    const [ payments, setPayments ] = useState( [] );
    const [ paymentList, setPaymentList ] = useState( [] );
    const [ paymentId, setPaymentId ] = useState( null );

    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/customer/canceled-list/payment` );
            setCustomers( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] );
    useEffect( () =>
    {
        setPlotBlockList( [] )
    }, [ plotLocations ] );
    useEffect( () =>
    {
        form.resetFields( [
            'blok'
        ] );
    }, [ plotLocations ] );
    useEffect( () =>
    {
        form.resetFields( [
            'noKav'
        ] );
    }, [ plotBlockList ] );
    useEffect( () =>
    {
        form.resetFields( [
            'nama'
        ] );
    }, [ customerList ] );
    useEffect( () =>
    {
        form.resetFields( [
            'mySelect'
        ] );
    }, [ payments ] );

    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasi === value );
        const sortLokasi = lokasiKav.sort( ( a, b ) => a.blok - b.blok );
        setPlotLocations( sortLokasi );
        form.resetFields( [
            'blok',
            'noKav',
            'nama',
            'mySelect',
            'dataSelect',
            'jumlahReturn',
            'metodeReturn',
            'tanggalReturn',
        ] );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
    }
    const handleBlokChange = ( value ) =>
    {
        const blokKav = plotLocations.filter( item => item.blok === value );
        const sortBlok = blokKav.sort( ( a, b ) => a.noKav - b.noKav );
        setPlotBlockList( sortBlok );
        form.resetFields( [
            'noKav',
            'nama',
            'mySelect',
            'dataSelect',
            'jumlahReturn',
            'metodeReturn',
            'tanggalReturn',
        ] )
        if ( blokKav.length > 0 )
        {
            message.success( `${ blokKav.length } nomor ditemukan` );
        } else
        {
            message.error( 'Tidak ada nomor ditemukan' );
        };
    };

    const handleKavlingNumber = ( value ) =>
    {
        const noKavling = plotLocations.filter( item => item.noKav === value );
        const sortBlok = noKavling.sort( ( a, b ) => a.noKav - b.noKav );
        setCustomerList( sortBlok );
        form.resetFields( [
            'nama',
            'mySelect',
            'dataSelect',
            'jumlahReturn',
            'metodeReturn',
            'tanggalReturn',
        ] )
        if ( noKavling.length > 0 )
        {
            message.success( `${ noKavling.length } pelanggan ditemukan` );
        } else
        {
            message.error( 'Tidak ada pelanggan' );
        };
    };
    const handleNameSelect = ( value ) =>
    {
        const cust = plotBlockList.find( item => item.id === value );
        const lengthPayment = cust.paymentData.length;

        if ( lengthPayment > 0 )
        {
            const paymentsData = cust.paymentData || [].sort( ( a, b ) =>
                dayjs( a.tanggalReturn, 'DD/MM/YYYY' ).toDate()
                - dayjs( b.tanggalReturn, 'DD/MM/YYYY' ).toDate() );
            setPayments( paymentsData );
            message.success( `${ lengthPayment } pembayaran ditemukan` );
        } else
        {
            message.error( 'Tidak ada pembayaran' );
        };

        form.resetFields( [
            'mySelect',
            'dataSelect',
            'jumlahReturn',
            'metodeReturn',
            'tanggalReturn',
        ] );
        setCustomerId( value );
    };

    const handleSelectYM = ( date ) =>
    {
        const filterYM = payments.filter( item =>
            dayjs( item.tanggalReturn, 'DD/MM/YYYY' ).isSame( date, 'month' )
        );

        if ( filterYM.length > 0 )
        {
            message.success( `${ filterYM.length } pembayaran ditemukan` );
        } else
        {
            message.error( 'Tidak ada pembayaran' );
        }

        form.resetFields( [
            'dataSelect',
            'jumlahReturn',
            'metodeReturn',
            'tanggalReturn',
        ] );
        setPaymentList( filterYM );
    };

    const handleSelectedPayment = ( value ) =>
    {
        const payment = paymentList.find( item => item._id === value );
        if ( !payment )
        {
            message.error( 'Pembayaran tidak valid!' );
        };

        form.setFieldsValue( {
            jumlahReturn: payment.jumlahReturn,
            metodeReturn: payment.metodeReturn,
            tanggalReturn: dayjs( payment.tanggalReturn, 'DD/MM/YYYY' )
        } );
        setPaymentId( value );
    };

    const handleDelete = async () =>
    {
        if ( !paymentId )
        {
            return message.error( 'Id pembayaran tidak valid!' );
        };

        setLoading( true );
        try
        {
            const response = await axiosConfig.delete( `${ apiUrl }/customer/canceled/${ customerId }/payment/${ paymentId }` );
            message.success( response.data.message );
            form.resetFields();
            setPaymentId( null );
            setCustomerId( null );
            fetchData();
            onClose();
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        };
    };
    const onFinish = async ( values ) =>
    {
        const { jumlahReturn, tanggalReturn, metodeReturn } = values;

        const paymentData = {
            jumlahReturn,
            tanggalReturn: dayjs( tanggalReturn ).format( 'DD/MM/YYYY' ),
            metodeReturn,
        };

        setLoading( true );
        try
        {
            const response = await axiosConfig.put( `${ apiUrl }/customer/canceled/${ customerId }/payment/${ paymentId }`, paymentData );
            message.success( response.data.message );
            fetchCustomers();
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };


    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasi"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blok"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blok ) ) ]
                                    .map( ( blok, index ) => (
                                        <Option key={ index } value={ blok }>
                                            { blok }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKav"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }>
                            {
                                [ ...new Set( plotBlockList.map( noKav => noKav.noKav ) ) ]
                                    .map( ( noKav, index ) => (
                                        <Option key={ index } value={ noKav }>
                                            { noKav }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="nama"
                    label="Nama Pembeli"
                >
                    <Select
                        placeholder='Pilih Pelanggan'
                        showSearch
                        style={ { width: '100%' } }
                        onChange={ handleNameSelect }
                    >
                        {
                            customerList.map( item => (
                                <Option key={ item.id } value={ item.id }>
                                    { item.nama }
                                </Option>
                            ) )
                        }
                    </Select>
                </Form.Item>
                <div className='payments-flex' >
                    <Form.Item
                        name='mySelect'
                        style={ { width: '100%' } }
                        label="Bulan - Tahun pengembalian"
                        rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                    >
                        <DatePicker.MonthPicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Bulan'
                            picker='month'
                            onChange={ handleSelectYM }
                            allowClear={ false }
                            format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        />
                    </Form.Item>
                    <Form.Item
                        label='Pilih Data'
                        name='dataSelect'
                        style={ { width: '100%' } }
                        rules={ [ { required: true, message: 'Pilih data' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih data'
                            onChange={ handleSelectedPayment }
                            options={ paymentList.map( item => (
                                { label: item.tanggalReturn, value: item._id }
                            ) ) } />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="tanggalReturn"
                    label="Tanggal pengembalian"
                    rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                >
                    <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                </Form.Item>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahReturn"
                        label="Jumlah Pengembalian"
                        rules={ [ { required: true, message: 'Jumlah pengembalian diperlukan' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            min={ 1 }
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeReturn"
                        label="Metode pengembalian"
                        rules={ [ { required: true, message: 'Metode pengembalian diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] } />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin mengupdate data ini?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' >
                            Update
                        </Button>
                    </Popconfirm>
                    { paymentId ? (
                        <Popconfirm
                            title="Apakah kamu yakin menghapus data ini?"
                            onConfirm={ handleDelete }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type='default' >
                                Hapus
                            </Button>
                        </Popconfirm>
                    ) : null }
                </div>
            </Form>
        </Spin>
    );
};

export default EditReturnedPay;
