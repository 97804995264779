import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Popconfirm } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;

const EditFee = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ sales, setSales ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ listBlok, setListBlok ] = useState( [] );
    const [ listNumber, setListNumber ] = useState( [] );
    const [ idSales, setIdSales ] = useState( null );
    const [ komisi, setKomisi ] = useState( [] );
    const [ yearData, setYearData ] = useState( [] );
    const [ idKomisi, setIdKomisi ] = useState( null );
    const fetchMarketings = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/sales/data` );
            setSales( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data sales' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchMarketings();
    }, [] );
    useEffect( () =>
    {
        setListNumber( [] )
    }, [ listBlok ] );
    useEffect( () =>
    {
        setKomisi( [] )
    }, [ listNumber ] );
    useEffect( () =>
    {
        setYearData( [] )
    }, [ komisi ] );
    useEffect( () => { }, [ idKomisi ] )
    const handleLocationChange = ( location ) =>
    {
        const filterLocation = sales.filter( item => item.lahanUser === location );
        const filteredSales = filterLocation.filter( sales =>
        {
            const totalByrKms = sales.dataKomisi.reduce( ( total, komisi ) => total + komisi.jumlahByrKms, 0 );
            return totalByrKms < sales.totalKomisi;
        } );
        form.resetFields( [
            'blokKavUser',
            'noKavUser',
            'tahunPembayaran',
            'listKomisi',
            'jumlahByrKms',
            'tanggalByrKms',
            'metodeByrKms',
            'komisiKe',
            'namaSales',
        ] );
        setListBlok( filteredSales );
        if ( filteredSales.length > 0 )
        {
            message.success( `${ filteredSales.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
    }
    const handleBlokChange = ( blok ) =>
    {
        const filterBlok = listBlok.filter( item => item.blokKavUser === blok );
        setListNumber( filterBlok );
        form.resetFields( [
            'noKavUser',
            'tahunPembayaran',
            'listKomisi',
            'jumlahByrKms',
            'tanggalByrKms',
            'metodeByrKms',
            'komisiKe',
            'namaSales',
        ] )
        if ( filterBlok.length > 0 )
        {
            message.success( `${ filterBlok.length } kavling ditemukan` );
        } else
        {
            message.error( 'Tidak ada kavling ditemukan' );
        }
    }
    const handleNumberChange = ( number ) =>
    {
        const dataSales = listNumber.find( item => item.noKavUser === number );
        const komisiData = dataSales.dataKomisi;
        form.setFieldsValue( {
            namaSales: dataSales.namaSales,
            komisiKe: komisiData.length + 1,
        } );
        setKomisi( komisiData );
        setIdSales( dataSales._id );
        if ( komisiData.length > 0 )
        {
            message.success( `${ komisiData.length } komisi ditemukan` );
        } else
        {
            message.error( 'Tidak ada komisi ditemukan' );
        }
    }
    const handleYearChange = ( date ) =>
    {
        const yearFilter = komisi.filter( item => dayjs( item.tanggalByrKms, 'DD/MM/YYYY' ).isSame( date, 'year' ) );
        form.resetFields( [
            'listKomisi',
            'jumlahByrKms',
            'tanggalByrKms',
            'metodeByrKms',
        ] )
        if ( yearFilter )
        {
            message.success( `${ yearFilter.length } list komisi ditemukan` );
            setYearData( yearFilter );
        } else
        {
            message.error( 'Tidak ada komisi ditemukan' );
        }
    }
    const handlePaymentChange = ( list ) =>
    {
        const listPayment = yearData.find( item => item._id === list );
        form.setFieldsValue( {
            jumlahByrKms: listPayment.jumlahByrKms,
            metodeByrKms: listPayment.metodeByrKms,
            komisiKe: listPayment.komisiKe,
            tanggalByrKms: dayjs( listPayment.tanggalByrKms, 'DD/MM/YYYY' ),
        } );
        setIdKomisi( listPayment._id )
    };
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/sales/data/${ idSales }/komisi/${ idKomisi }` );
            onClose();
            fetchData();
            message.success( 'Berhasil menghapus komisi terpilih' );
        } catch ( error )
        {
            message.error( 'Gagal menghapus komisi terpilih' );
        }
    };
    const onFinish = async ( values ) =>
    {
        const updatePay = {
            jumlahByrKms: values.jumlahByrKms,
            tanggalByrKms: dayjs( values.tanggalByrKms ).format( 'DD/MM/YYYY' ),
            metodeByrKms: values.metodeByrKms,
        }
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/sales/data/${ idSales }/komisi/${ idKomisi }`, updatePay );
            form.resetFields();
            onClose();
            message.success( 'Berhasil memperbarui data komisi' );
            fetchData();
        } catch ( error )
        {
            message.error( `Operation Failed: ${ error.message }` );
        } finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout='vertical'>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='lahanUser'
                        label='Lokasi Kavling'
                        rules={ [ { required: true, message: 'Lokasi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Lokasi Kavling'
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='blokKavUser'
                        label='Blok Kavling'
                        rules={ [ { required: true, message: 'Blok Kavling diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok'
                            style={ { width: '100%' } }
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( listBlok.map( blok => blok.blokKavUser ) ) ]
                                    .map( ( blokKavUser, index ) => (
                                        <Option key={ index } value={ blokKavUser }>
                                            { blokKavUser }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='noKavUser'
                        label='Blok Kavling'
                        rules={ [ { required: true, message: 'Blok Kavling diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok'
                            style={ { width: '100%' } }
                            onChange={ handleNumberChange }>
                            {
                                [ ...new Set( listNumber.map( no => no.noKavUser ) ) ]
                                    .map( ( noKavUser, index ) => (
                                        <Option key={ index } value={ noKavUser }>
                                            { noKavUser }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaSales'
                        label='Nama Marketing'
                    >
                        <Input placeholder='Nama Marketing' readOnly />
                    </Form.Item>
                    <Form.Item
                        label='Tahun Pembayaran'
                        name='tahunPembayaran'
                        style={ { width: '100%' } }
                    >
                        <DatePicker.YearPicker
                            format='YYYY'
                            style={ { width: '100%' } }
                            placeholder='Pilih Tahun'
                            onChange={ handleYearChange } />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        label='List Komisi'
                        style={ { width: '100%' } }
                        name='listKomisi'
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih List Komisi'
                            onChange={ handlePaymentChange }
                        >
                            { yearData.map( kms => (
                                <Option key={ kms._id } value={ kms._id }>
                                    { kms.komisiKe }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='metodeByrKms'
                        label='Metode Pembayaran'
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select placeholder='Pilih Metode' options={ [
                            { value: 'TUNAI', label: 'TUNAI' },
                            { value: 'BRI', label: 'BRI' },
                            { value: 'BNI', label: 'BNI' },
                        ] } />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='jumlahByrKms'
                        label='Jumlah Bayar'
                        rules={ [ { required: true, message: 'Jumlah diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder='Masukkan jumlah'
                            style={ { width: '100%' } } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='tanggalByrKms'
                        label='Tanggal Pembayaran'
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker style={ { width: '100%' } } format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary'  >
                            Update
                        </Button>
                    </Popconfirm>
                    { idKomisi && (
                        <Popconfirm
                            title="Apakah kamu yakin menghapus data ini ?"
                            onConfirm={ handleDelete }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='default' danger  >
                                Hapus
                            </Button>
                        </Popconfirm>
                    ) }
                </div>
            </Form>
        </Spin>
    );
};

export default EditFee;
