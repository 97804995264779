import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Unauthorized from './Authentication/protectedRoute/UnauthorizedRoute';
import { NotFound } from './Authentication/routes/routesNotFound';
import LoginAuth from './Authentication/defaultPage/login';
import ProtectedRouteWithLayout from './Authentication/protectedRoute/ProtectedRoute';
import Homepage from './components/home';
import FinanceStatistic from './components/DataKeuangan/statistic/statisticFinance';
import DataGajiTukang from './components/DataKeuangan/GajiTukang/tukangGajiData';
import DataWartawan from './components/DataKeuangan/Wartawan/wartawanData';
import LahanData from './components/DataKeuangan/Lahan/DataLahan';
import KomisiPenjualan from './components/DataKeuangan/Komisi/feeMarketing';
import Material from './components/DataKeuangan/Material/materials';
import Pengeluaran from './components/DataKeuangan/statistic/RekabPengeluaran';
import PiutangCust from './components/DataPelanggan/piutang/piutang';
import DataAngsuran from './components/DataPelanggan/payments/angsuran/AngsuranData';
import EditAngsuran from './components/DataPelanggan/payments/angsuran/AngsuranEdit';
import DataDp from './components/DataPelanggan/payments/uangmuka/DpData';
import EditDP from './components/DataPelanggan/payments/uangmuka/DpEdit';
import DataCustomer from './components/DataPelanggan/customer/infoCustomer';
import EmployeeSalaryData from './components/DataKeuangan/GajiKaryawan/dataKaryawan';
import UnexpectedExpenses from './components/DataKeuangan/PengeluaranLain/pttData';
import ListLoan from './components/DataKeuangan/KasBon/loanList';
import DataLoan from './components/DataKeuangan/KasBon/pinjaman/loanData';
import LoanPaymentData from './components/DataKeuangan/KasBon/pembayaran/paymentLoanData';
import PlotListData from './components/DataPelanggan/KavlingList/ListPlotData';
import WaterAndElectricData from './components/DataPelanggan/airLampu/dataAirLampu';
import Pemasukan from './components/DataKeuangan/statistikIn/rekabPemasukan';
import IncomeAdditionalData from './components/DataKeuangan/statistikIn/dataPemasukan';
import CashFlow from './components/DataKeuangan/ArusKas/ArusKas';
import CashTempoData from './components/DataPelanggan/payments/cashTempo/dataCashTempo';
import FineData from './components/DataPelanggan/payments/denda/dataDenda';
import DataLogs from './components/Log/logData';
import PaymentWeb from './components/DataPelanggan/gateway/webpay';
import BillingData from './components/DataPelanggan/gateway/dataPenagihan';
import UnderDevelopment from './Authentication/protectedRoute/unDevelopment';
import ListClientAccount from './components/Function/clientaccount/listClientAccount';
import CanceledData from './components/DataPelanggan/cancel/canceledCustomer';
import PaymentCancelData from './components/DataPelanggan/cancel/paymentCancel';

const App = () =>
{
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Navigate to='/login' /> } />
        <Route path='*' element={ <NotFound /> } />
        <Route path='/unauthorized' element={ <Unauthorized /> } />
        <Route path='/login' element={ <LoginAuth /> } />
        <Route path="/homepage" element={
          <ProtectedRouteWithLayout>
            <Homepage />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-customers" element={
          <ProtectedRouteWithLayout>
            <DataCustomer />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/piutang-cust" element={
          <ProtectedRouteWithLayout>
            <PiutangCust />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-denda" element={
          <ProtectedRouteWithLayout>
            <FineData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-angsuran" element={
          <ProtectedRouteWithLayout>
            <DataAngsuran />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/bayarlampudanair" element={
          <ProtectedRouteWithLayout>
            <WaterAndElectricData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-logs" element={
          <ProtectedRouteWithLayout>
            <DataLogs />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/edit-angsuran" element={
          <ProtectedRouteWithLayout>
            <EditAngsuran />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-uangmuka" element={
          <ProtectedRouteWithLayout>
            <DataDp />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/edit-uangmuka" element={
          <ProtectedRouteWithLayout>
            <EditDP />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-cash-tempo" element={
          <ProtectedRouteWithLayout>
            <CashTempoData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/statistic-finance" element={
          <ProtectedRouteWithLayout>
            <FinanceStatistic />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/daily-cash-flow" element={
          <ProtectedRouteWithLayout>
            <CashFlow />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/unexpected-expenses" element={
          <ProtectedRouteWithLayout>
            <UnexpectedExpenses />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/daftar-pinjaman" element={
          <ProtectedRouteWithLayout>
            <ListLoan />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-pinjamanKasbon" element={
          <ProtectedRouteWithLayout>
            <DataLoan />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-pembayaranKasbon" element={
          <ProtectedRouteWithLayout>
            <LoanPaymentData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-kavling" element={
          <ProtectedRouteWithLayout>
            <PlotListData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/employee-salary" element={
          <ProtectedRouteWithLayout>
            <EmployeeSalaryData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/pengeluaran" element={
          <ProtectedRouteWithLayout>
            <Pengeluaran />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/pemasukan" element={
          <ProtectedRouteWithLayout>
            <Pemasukan />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/tambahan-pemasukan" element={
          <ProtectedRouteWithLayout>
            <IncomeAdditionalData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/material" element={
          <ProtectedRouteWithLayout>
            <Material />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/lahan-kavling" element={
          <ProtectedRouteWithLayout>
            <LahanData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/payment-billing/:code" element={
          <PaymentWeb />
        } />
        <Route path="/billing-data" element={
          <ProtectedRouteWithLayout>
            <BillingData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/komisi-penjualan" element={
          <ProtectedRouteWithLayout>
            <KomisiPenjualan />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-wartawan" element={
          <ProtectedRouteWithLayout>
            <DataWartawan />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/data-gajiTukang" element={
          <ProtectedRouteWithLayout>
            <DataGajiTukang />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/underdevelopment-page" element={
          <ProtectedRouteWithLayout>
            <UnderDevelopment />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/create-account" element={
          <ProtectedRouteWithLayout>
            <ListClientAccount />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/canceled-customers" element={
          <ProtectedRouteWithLayout>
            <CanceledData />
          </ProtectedRouteWithLayout>
        } />
        <Route path="/canceled-payment-data" element={
          <ProtectedRouteWithLayout>
            <PaymentCancelData />
          </ProtectedRouteWithLayout>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default App;