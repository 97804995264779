import React, { useEffect, useState } from 'react';
import { Table, message, DatePicker, Button, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const Pemasukan = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ paidTypeFilters, setPaidTypeFilters ] = useState( [] );
    const [ totalIncome, setTotalIncome ] = useState();
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig( `${ apiUrl }/statistic/data/rekabinc`, {
                params: {
                    selectedMonth: dayjs( selectedMonth ).format( 'DD/MM/YYYY' ),
                },
            } );

            const { filteredData, totalJumlah, methodList } = response.data;
            setTotalIncome( totalJumlah );
            setData( filteredData );
            setPaidTypeFilters( methodList );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );

    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };

    useEffect( () =>
    {
        fetchData();
    }, [ selectedMonth ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };

    const handleRefresh = () =>
    {
        fetchData();
    };

    const columns = [
        {
            title: 'No',
            key: 'no',
            width: '5%',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Dari</p>,
            dataIndex: 'infoPaid',
            align: 'left',
            width: '25vw',
        },
        {
            title: 'Tanggal Bayar',
            dataIndex: 'tanggal',
            align: 'center',
            width: '10vw',
            sorter: ( a, b ) => dayjs( a.tanggal, 'DD/MM/YYYY' ).diff( dayjs( b.tanggal, 'DD/MM/YYYY' ) ),
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value ),
            sorter: ( a, b ) => a.jumlah - b.jumlah,
        },
        {
            title: 'Tujuan',
            dataIndex: 'tujuan',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Metode Bayar',
            dataIndex: 'paidType',
            width: 'auto',
            align: 'center',
            filters: paidTypeFilters.map( item => ( { text: item, value: item } ) ),
            onFilter: ( value, record ) => record.paidType.includes( value ),
        },
    ];

    return (
        <div >
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', padding: '10px 0', gap: 5, alignItems: 'center' } }>
                    <Button type="default" onClick={ handleRefresh } >
                        Refresh
                    </Button>
                    <DatePicker.MonthPicker
                        placeholder='Tanggal'
                        onChange={ handleMonthChange }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        picker='month'
                        allowClear={ false }
                        value={ selectedMonth }
                    />
                    <InputNumber
                        style={ {
                            display: 'flex',
                            width: '100%'
                        } }
                        readOnly
                        placeholder={ totalIncome < 1 && 'Tidak ada pengeluaran' }
                        addonBefore={ totalIncome >= 1 && 'Total' }
                        value={ totalIncome >= 1 && convertToIDR( totalIncome ) } />
                </div>
                <Table
                    bordered
                    showSorterTooltip={ false }
                    columns={ columns }
                    dataSource={ data }
                    rowKey={ ( record, index ) => index }
                    loading={ loading }
                    size='small'
                    scroll={ { y: 450 } }
                />
            </div>
        </div>
    );
};

export default Pemasukan;
