import { Button, Form, InputNumber, message, Popconfirm, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const DrawerEditGajiLembur = ( { fetchData, onClose, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ employeeData, setEmployeeData ] = useState( [] );
    const [ gajiData, setGajiData ] = useState();
    const days = [ 'sabtu', 'minggu', 'senin', 'selasa', 'rabu', 'kamis', 'jumat' ];
    const [ loading, setLoading ] = useState( false );

    useEffect( () =>
    {
        const fetchEmployeById = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }` );
                const response1 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/worker/list` );
                setEmployeeData( response1.data );
                form.setFieldsValue( response.data );
                setGajiData( response.data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchEmployeById();
        }
    }, [ recordId, form, onClose ] );

    const onFinish = async ( values ) =>
    {
        let totalGajiLembur = 0;
        values.gajiLembur.forEach( ( item ) =>
        {
            days.forEach( ( day ) =>
            {
                totalGajiLembur += item[ day ] || 0;
            } );
        } );

        const totalPembayaranGaji = totalGajiLembur + ( gajiData?.totalGajiMingguan || 0 ) + ( gajiData?.uangMakanTukang || 0 );

        const paymentData = {
            ...values,
            totalGajiLembur,
            totalPembayaranGaji,
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }`, paymentData );
            form.resetFields();
            onClose();
            fetchData();
            message.success( 'Data gaji lembur berhasil diperbarui' );
        } catch ( error )
        {
            message.error( 'Data gagal diperbarui' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } name='lembur_form' onFinish={ onFinish } layout='vertical'>
                <Form.List name='gajiLembur'>
                    { ( fields, { add, remove } ) => (
                        <>
                            { fields.map( ( { key, name, fieldKey, ...restField } ) => (
                                <div key={ key }>
                                    <Form.Item
                                        { ...restField }
                                        name={ [ name, 'nama' ] }
                                        style={ { width: '100%' } }
                                        fieldKey={ [ fieldKey, 'nama' ] }
                                        label='Nama Pekerja'
                                        rules={ [ { required: true, message: 'Nama pekerja diperlukan!' } ] }
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            style={ { width: '100%' } }
                                            options={ employeeData.map( ( emp ) => ( {
                                                value: emp.nama,
                                                label: emp.nama,
                                            } ) ) }
                                        />
                                    </Form.Item>
                                    <div style={ { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '5px' } }>
                                        { days.map( ( day ) => (
                                            <Form.Item
                                                key={ day }
                                                label={ `Gaji ${ day.charAt( 0 ).toUpperCase() + day.slice( 1 ) }` }
                                                name={ [ name, day ] }
                                                fieldKey={ [ fieldKey, day ] }
                                            >
                                                <InputNumber
                                                    style={ { width: '100%' } }
                                                    min={ 0 }
                                                    addonBefore='Rp'
                                                    placeholder='Masukkan gaji'
                                                />
                                            </Form.Item>
                                        ) ) }
                                    </div>
                                    <Button
                                        style={ { marginBottom: 10, width: '200px' } }
                                        danger
                                        onClick={ () => remove( name ) }
                                    >
                                        Hapus Lembur Pekerja
                                    </Button>
                                </div>
                            ) ) }
                            <Button onClick={ () => add() } style={ { width: '200px' } }>
                                Tambah Lembur Pekerja
                            </Button>
                        </>
                    ) }
                </Form.List>
                <Form.Item>
                    <Popconfirm
                        placement='right'
                        title='Apakah kamu yakin melakukan update data ini?'
                        onConfirm={ () => form.submit() }
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button style={ { marginTop: 10, width: '200px' } } type='primary' >
                            Update Gaji Lembur
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default DrawerEditGajiLembur;
