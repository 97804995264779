import React, { useState, useEffect } from 'react';
import { Form, Button, DatePicker, message, Spin, InputNumber, Input, Popconfirm, Select } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const EditAdditionalIncome = ( { onClose, fetchData, recordId } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    useEffect( () =>
    {
        const fetchListPTT = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/additionalIncome/data/${ recordId }` )
                const data = response.data;
                form.setFieldsValue( {
                    ...data,
                    dateGet: dayjs( data.dateGet, 'DD/MM/YYYY' ),
                } )
                setLoading( false );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };
        if ( recordId )
        {
            fetchListPTT();
        }
    }, [ recordId, form, onClose ] );

    const onFinish = async ( values ) =>
    {
        const pttData = {
            ...values,
            dateGet: dayjs( values.dateGet ).format( 'DD/MM/YYYY' )
        }
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/additionalIncome/data/${ recordId }`, pttData );
            message.success( 'Pembaruan data PTT berhasil' );
            form.resetFields();
            setLoading( false );
            onClose();
            fetchData();
        } catch ( error )
        {
            setLoading( false );
            console.error( 'Terjadi kesalahan saat menyimpan data:', error );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Sumber Pemasukan"
                        name="ofWhom"
                        rules={ [ { required: true, message: 'Sumber diperlukan!' } ] }
                    >
                        <Input placeholder="Masukkan Sumber" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="dateGet"
                        label="Tanggal Pemasukan"
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker
                            placeholder='Pilih Tanggal'
                            style={ { width: '100%' } }
                            format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="howMuch"
                        label="Jumlah Pemasukan"
                        rules={ [ { required: true, message: 'Jumlah diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="incomeMethod"
                        label="Pilih Metode"
                        rules={ [ { required: true, message: 'Metode diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Popconfirm
                    title="Apakah kamu yakin melakukan update data ini ?"
                    onConfirm={ () => form.submit() }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type='primary' style={ { marginRight: 5 } } loading={ loading }>
                        Update
                    </Button>
                </Popconfirm>
            </Form>
        </Spin>
    );
};

export default EditAdditionalIncome;
