import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, InputNumber, Table, message } from 'antd';
import dayjs from 'dayjs';
import { CiEdit } from 'react-icons/ci';
import { SlRefresh } from "react-icons/sl";
import 'dayjs/locale/id';
import NewLoan from './pinjamanBaru';
import EditLoan from './editPinjaman';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const DataLoan = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerTambah, setDrawerTambah ] = useState( false );
    const [ totalRemainingLoan, setTotalRemainingLoan ] = useState();
    const [ idLoan, setIdLoan ] = useState( null );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalPinjaman, setTotalPinjaman ] = useState();

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list` );
            const data = response.data;
            const tableData = data.flatMap( db =>
            {
                return db.riwayatPinjaman.map( pinjaman => ( {
                    nama: db.namaNasabah,
                    jumlah: pinjaman.loanValue,
                    tanggal: pinjaman.loanDate,
                    jaminan: pinjaman.loanAssurance,
                    metode: pinjaman.loanMethod,
                    kebutuhan: pinjaman.loanDescription,
                    idLoan: pinjaman._id,
                    idClient: db._id,
                } ) );
            } );
            const remainingLoan = data.reduce( ( total, item ) => total + item.sisaPinjaman, 0 )
            setData( tableData );
            setTotalRemainingLoan( remainingLoan );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };

    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };

    const filteredData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.tanggal, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const totalPinjaman1Bln = filteredData.reduce( ( total, item ) =>
            {
                return total + item.jumlah;
            }, 0 );
            setTotalPinjaman( totalPinjaman1Bln );
        } else
        {
            const totalSemuaPinjaman = data.reduce( ( total, item ) =>
            {
                return total + item.jumlah;
            }, 0 );
            setTotalPinjaman( totalSemuaPinjaman );
        }
    }, [ filteredData, selectedMonth, data ] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleOpenEdit( record ) } icon={ <CiEdit /> } />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Nasabah</p>,
            dataIndex: 'nama',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Pinjaman',
            dataIndex: 'jumlah',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value )
        },
        {
            title: 'Tanggal',
            dataIndex: 'tanggal',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Metode',
            dataIndex: 'metode',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Jaminan',
            dataIndex: 'jaminan',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Keterangan',
            dataIndex: 'kebutuhan',
            width: 'auto',
            align: 'center',
        },
    ];
    const handleCloseAdd = () =>
    {
        setDrawerTambah( false );
    };
    const handleOpenAdd = () =>
    {
        setDrawerTambah( true );
    };
    const handleOpenEdit = ( record ) =>
    {
        setIdLoan( record.idLoan )
        setEditRecordId( record.idClient );
        setDrawerEdit( true );
    };
    const handleCloseEdit = () =>
    {
        setDrawerEdit( false );
        setEditRecordId( null );
        setIdLoan( null )
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleOpenAdd }>
                        Tambah Pinjaman
                    </Button>
                    <InputNumber
                        addonBefore={ totalPinjaman >= 1 && `Total Pinjaman ${ dayjs( selectedMonth ).format( 'MMMM YYYY' ) }` }
                        readOnly
                        style={ { width: '100%' } }
                        placeholder={ totalPinjaman < 1 && 'Tidak ada pinjaman' }
                        value={ totalPinjaman >= 1 && convertToIDR( totalPinjaman ) } />
                    <DatePicker.MonthPicker
                        style={ { width: '30%' } }
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker="month"
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        placeholder='Pilih Bulan'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ filteredData }
                    loading={ loading }
                    rowKey="_id"
                    pagination={ { pageSize: 20 } }
                    size="small"
                    scroll={ { y: 420 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Pinjaman"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEdit }
                    visible={ drawerEdit }
                    width="50%"
                >
                    <EditLoan
                        key={ `edit-form-key-${ editRecordId }` }
                        recordId={ editRecordId }
                        loanId={ idLoan }
                        onClose={ handleCloseEdit }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Tambah Pinjaman"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseAdd }
                    visible={ drawerTambah }
                    width="40%"
                >
                    <NewLoan onClose={ handleCloseAdd } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default DataLoan;
