import { Descriptions, message, Typography, List, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from "../../../Authentication/contexts/axiosConfig";
dayjs.extend( isSameOrBefore );
dayjs.locale( 'id' );

const { YearPicker } = DatePicker;

const CekDataCustomer = ( { recordId, onClose } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ database, setDatabase ] = useState( [] );
    const [ historyPayment, setHistoryPayment ] = useState( [] );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );

    useEffect( () =>
    {
        const fetchDataById = async () =>
        {
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data/check/${ recordId }` );
                const data = response.data;
                const dataAngsuran = data.sortedDataAngsuran;
                const dataDp = data.sortedDataDp;
                const dataTempo = data.sortedDataTempo;
                const dataDenda = data.sortedDataDenda;
                const dataFasum = data.sortedDataFasum;
                const dataCancel = data.sortedDataCancel;
                const payments = [
                    ...dataAngsuran,
                    ...dataDp,
                    ...dataTempo,
                    ...dataDenda,
                    ...dataFasum,
                    ...dataCancel,
                ];
                setHistoryPayment( payments );
                setDatabase( data );
            } catch ( error )
            {
                message.error( "Gagal mengambil data dari server" );
            }
        };

        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId, onClose ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const handleYearChange = ( date ) =>
    {
        setSelectedYear( date );
    };
    const filteredData = historyPayment
        .filter( ( item ) =>
            dayjs( item.tanggalByrAngs || item.tanggalByrDp || item.tanggalByrTempo || item.tanggalReturn, 'DD/MM/YYYY' )
                .isSame( selectedYear, 'year' ) );
    return (
        <div>
            { database && (
                <Descriptions title={ `${ database.customerName }` } column={ 2 }>
                    <Descriptions.Item label="Alamat Pelanggan">{ database.customerAddress }</Descriptions.Item>
                    { database.customerNumber ? (
                        <Descriptions.Item label="Nomor Pelanggan">{ `0${ database.customerNumber }` }</Descriptions.Item>
                    ) : null }
                    <Descriptions.Item label="Harga Properti">{ convertToIDR( database.propertyPrice || 0 ) }</Descriptions.Item>
                    { database.discountPrice ? (
                        <Descriptions.Item label="Potongan Harga">{ convertToIDR( database.discountPrice || 0 ) }</Descriptions.Item>
                    ) : null }
                    <Descriptions.Item label="Rencana DP">{ convertToIDR( database.downPaymentPlan || 0 ) }</Descriptions.Item>
                    <Descriptions.Item label="Angsuran Properti">{ convertToIDR( database.installmentAmount || 0 ) }</Descriptions.Item>
                    <Descriptions.Item label="Tenor Properti">{ `${ database.tenorProperty } BULAN` }</Descriptions.Item>
                    <Descriptions.Item label="Blok Properti">{ database.customerBlock }</Descriptions.Item>
                    <Descriptions.Item label="Jumlah UTJ">{ convertToIDR( database.customerUtj ) }</Descriptions.Item>
                    <Descriptions.Item label="Tanggal Booking">{ database.customerUtjDate }</Descriptions.Item>
                    <Descriptions.Item label="Tanggal IJB">{ database.customerIjbDate }</Descriptions.Item>
                    <Descriptions.Item label="Ukuran Kavling">{ database.customerPlotSize }</Descriptions.Item>
                    { database.customerBuildingWide &&
                        <Descriptions.Item label="Luas Bangunan">{ database.customerBuildingWide }</Descriptions.Item>
                    }
                    <Descriptions.Item label="Jenis Properti">{ database.customerProperty }</Descriptions.Item>
                    <Descriptions.Item label="Jenis Pembelian">{ database.customerBuyyingType }</Descriptions.Item>
                    <Descriptions.Item label="Lokasi Properti">{ database.customerPropertyAddress }</Descriptions.Item>
                    { database.customerStatus !== 'BATAL' ? (
                        <>
                            <Descriptions.Item label="Total Uang Masuk">{ convertToIDR( database.totalUangMasuk ) }</Descriptions.Item>
                            <Descriptions.Item label="Total Piutang">{ convertToIDR( database.kekuranganPembayaran ) }</Descriptions.Item>
                            <Descriptions.Item label="Angsuran Masuk">{ convertToIDR( database.totalAngsuranMasuk ) }</Descriptions.Item>
                            <Descriptions.Item label="DP Masuk">{ convertToIDR( database.totalDpMasuk ) }</Descriptions.Item>
                            <Descriptions.Item label="Bunga">{ convertToIDR( database.bungaProperty ) }</Descriptions.Item>
                        </>
                    ) : null }
                    { database.sisaDenda > 0 && (
                        <Descriptions.Item label="Denda">{ convertToIDR( database.sisaDenda ) }</Descriptions.Item>
                    ) }
                    { database.customerStatus === 'BATAL' ? (
                        <>
                            <Descriptions.Item label="Total Uang Masuk">{ convertToIDR( database.cancelInTotal || 0 ) }</Descriptions.Item>
                            <Descriptions.Item label="Total Pengembalian">{ convertToIDR( database.cancelReturned || 0 ) }</Descriptions.Item>
                        </>
                    ) : null }
                </Descriptions>
            ) }
            <YearPicker
                style={ { width: '100%', marginBottom: 5 } }
                onChange={ handleYearChange }
                value={ selectedYear }
                picker="year"
                format='YYYY'
                placeholder='Pilih Tahun'
            />
            <List
                bordered
                dataSource={ filteredData }
                header='Riwayat Pembayaran'
                renderItem={ ( item ) => (
                    <List.Item>
                        <div>
                            { item.jumlahByrAngs ? (
                                <>
                                    <Typography.Text strong>Jumlah Bayar Angsuran: </Typography.Text>{ convertToIDR( item.jumlahByrAngs ) }<br />
                                </>
                            ) : null }
                            { item.tanggalByrAngs ? (
                                <>
                                    <Typography.Text strong>Tanggal Bayar Angsuran: </Typography.Text>{ dayjs( item.tanggalByrAngs, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.tanggalByrAngs2 ? (
                                <>
                                    <Typography.Text strong>Untuk Angsuran Bulan: </Typography.Text>{ dayjs( item.tanggalByrAngs2, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.metodeByrAngs ? (
                                <>
                                    <Typography.Text strong>Metode Bayar Angsuran: </Typography.Text>{ item.metodeByrAngs }<br />
                                </>
                            ) : null }
                            { item.angsKe ? (
                                <>
                                    <Typography.Text strong>Bayar Angsuran Ke: </Typography.Text>{ item.angsKe }<br />
                                </>
                            ) : null }
                            { item.dendaAngs ? (
                                <>
                                    <Typography.Text>Bayar Denda Angsuran: </Typography.Text>{ convertToIDR( item.dendaAngs ) }<br />
                                </>
                            ) : null }
                            { item.jumlahByrDp ? (
                                <>
                                    <Typography.Text strong>Jumlah Bayar DP: </Typography.Text>{ convertToIDR( item.jumlahByrDp ) }<br />
                                </>
                            ) : null }
                            { item.tanggalByrDp ? (
                                <>
                                    <Typography.Text strong>Tanggal Bayar DP: </Typography.Text>{ dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.metodeByrDp ? (
                                <>
                                    <Typography.Text strong>Metode Bayar DP: </Typography.Text>{ item.metodeByrDp }<br />
                                </>
                            ) : null }
                            { item.dpKe ? (
                                <>
                                    <Typography.Text strong>Bayar DP Ke: </Typography.Text>{ item.dpKe }<br />
                                </>
                            ) : null }

                            { item.jumlahByrTempo ? (
                                <>
                                    <Typography.Text strong>Jumlah Bayar Tempo: </Typography.Text>{ convertToIDR( item.jumlahByrTempo ) }<br />
                                </>
                            ) : null }
                            { item.tanggalByrTempo ? (
                                <>
                                    <Typography.Text strong>Tanggal Bayar Tempo: </Typography.Text>{ dayjs( item.tanggalByrTempo, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.metodeByrTempo ? (
                                <>
                                    <Typography.Text strong>Metode Bayar Tempo: </Typography.Text>{ item.metodeByrTempo }<br />
                                </>
                            ) : null }
                            { item.bayarTempoKe ? (
                                <>
                                    <Typography.Text strong>Bayar Tempo Ke: </Typography.Text>{ item.bayarTempoKe }<br />
                                </>
                            ) : null }
                            { database.customerStatus === 'BATAL' ?
                                ( <>
                                    <Typography.Text strong>Tanggal Pengembalian: </Typography.Text>{ item.tanggalReturn }<br />
                                    <Typography.Text strong>Metode Pengembalian: </Typography.Text>{ item.metodeReturn }<br />
                                    <Typography.Text strong>Jumlah Pengembalian: </Typography.Text>{ convertToIDR( item.jumlahReturn ) }<br />
                                </> ) : null }
                        </div>
                    </List.Item>
                ) }
            />
        </div>
    );
};

export default CekDataCustomer;
