import React, { useEffect, useState } from 'react'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
import dayjs from 'dayjs';
import { SlRefresh } from 'react-icons/sl';
import { Button, DatePicker, Drawer, InputNumber, Select, Table } from 'antd';
import AddNewReturnPay from './addReturnPay';
import { PiCursorClickThin } from 'react-icons/pi';
import EditReturnedPay from './editReturnPay';
const PaymentCancelData = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ paymentData, setPaymentData ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ returnedPayment, setReturnedPayment ] = useState( 0 );
    const [ remainingReturn, setRemainingReturn ] = useState( 0 );
    const [ drawerPay, setDrawerPay ] = useState( false );
    const [ drawerEditPay, setDrawerEditPay ] = useState( false );
    const [ selectedAction, setSelectedAction ] = useState( null );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const fetchPayment = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/customer/canceled-list/data`, {
                params: {
                    selectedMonth: dayjs( selectedMonth ).format( 'DD/MM/YYYY' ),
                },
            } );
            const { csData, returnedPayment, remainingReturn, locationList } = response.data;
            setPaymentData( csData )
            setReturnedPayment( returnedPayment );
            setRemainingReturn( remainingReturn );
            setFilterLocation( locationList );
        } catch ( error )
        {
            console.error( error )
        } finally
        {
            setLoading( false );
        };
    };

    useEffect( () =>
    {
        fetchPayment();
    }, [] );

    const handleSelectMonth = ( date ) =>
    {
        setSelectedMonth( date )
    };

    useEffect( () =>
    {
        fetchPayment();
    }, [ selectedMonth ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };

    const handleSelectChange = ( value ) =>
    {
        setSelectedAction( value );
    };

    const handleDoAction = () =>
    {
        if ( selectedAction === 'Bayar' )
        {
            setDrawerPay( true );
        } else if ( selectedAction === 'Edit' )
        {
            setDrawerEditPay( true );
        };
    };

    const tableColumns = [
        {
            title: 'Blok',
            dataIndex: 'blok',
            width: '6%',
            align: 'center',
            sorter: ( a, b ) =>
            {
                const regex = /^(\d+|[a-zA-Z]+)(\d+|[a-zA-Z]*)$/;
                const aMatch = a.blok.match( regex );
                const bMatch = b.blok.match( regex );

                if ( !aMatch || !bMatch ) return 0;

                const aPart1 = aMatch[ 1 ];
                const aPart2 = aMatch[ 2 ] || '';
                const bPart1 = bMatch[ 1 ];
                const bPart2 = bMatch[ 2 ] || '';

                if ( isNaN( aPart1 ) && isNaN( bPart1 ) )
                {
                    if ( aPart1 !== bPart1 )
                    {
                        return aPart1.localeCompare( bPart1 );
                    }
                    return aPart2.localeCompare( bPart2 );
                };

                if ( !isNaN( aPart1 ) && !isNaN( bPart1 ) )
                {
                    const numCompare = parseInt( aPart1 ) - parseInt( bPart1 );
                    if ( numCompare !== 0 )
                    {
                        return numCompare;
                    }
                    return aPart2.localeCompare( bPart2 );
                };
                return isNaN( aPart1 ) ? 1 : -1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama</p>,
            dataIndex: 'nama',
            width: 'auto',
            align: 'left'
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            width: '10%',
            align: 'center',
            filters: filterLocation.map( item => ( { text: item, value: item } ) ),
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Pengembalian',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Total',
                    dataIndex: 'totalReturned',
                    width: '12%',
                    align: 'center',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Sisa',
                    dataIndex: 'diffPayment',
                    width: '12%',
                    align: 'center',
                    render: value => value === 0 ? 'LUNAS' : convertToIDR( value ),
                },
            ]
        },
        {
            title: 'Riwayat Transaksi',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Jumlah',
                    dataIndex: 'jumlah',
                    width: '12%',
                    align: 'center',
                    render: value => value === 0 ? '' : convertToIDR( value ),
                },
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                    width: '12%',
                    align: 'center',
                    sorter: ( a, b ) =>
                    {
                        const dateA = a.tanggal ? dayjs( a.tanggal, 'DD/MM/YYYY' ).toDate() : null;
                        const dateB = b.tanggal ? dayjs( b.tanggal, 'DD/MM/YYYY' ).toDate() : null;
                        if ( dateA && !dateB ) return -1;
                        if ( !dateA && dateB ) return 1;
                        if ( dateA && dateB ) return dateA - dateB;
                        return 0;
                    },
                    sortDirections: [ 'descend', 'ascend' ],
                    defaultSortOrder: 'descend',
                },
                {
                    title: 'Metode',
                    dataIndex: 'metode',
                    width: '12%',
                    align: 'center'
                },
            ]
        },
    ]
    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { padding: '10px 0', display: 'flex', gap: 5 } }>
                <Button
                    style={ { padding: 5 } }
                    icon={ <SlRefresh /> }
                    onClick={ () => fetchPayment() } />
                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleDoAction }
                    icon={ <PiCursorClickThin /> } />
                <Select
                    style={ { width: '50%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    options={ [
                        { label: 'Bayar', value: 'Bayar' },
                        { label: 'Edit', value: 'Edit' }
                    ] } />
                <InputNumber
                    style={ { width: '100%' } }
                    readOnly
                    addonBefore={ remainingReturn > 0 && 'Sisa' }
                    value={ remainingReturn > 0 && convertToIDR( remainingReturn ) }
                    placeholder={ remainingReturn <= 0 && 'Tidak ada piutang pengembalian' } />
                <InputNumber
                    style={ { width: '100%' } }
                    readOnly
                    addonBefore={ returnedPayment > 0 && 'Terbayar' }
                    value={ returnedPayment > 0 && convertToIDR( returnedPayment ) }
                    placeholder={ returnedPayment <= 0 && 'Tidak ada pengembalian' } />
                <DatePicker.MonthPicker
                    style={ { width: '50%' } }
                    placeholder='Pilih Bulan'
                    picker='month'
                    onChange={ handleSelectMonth }
                    allowClear={ false }
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                />
            </div>
            <Table
                dataSource={ paymentData }
                columns={ tableColumns }
                size='small'
                loading={ loading }
                bordered
                scroll={ { y: 420 } }
            />
            <div>
                <Drawer
                    title='Pembayaran Pengembalian'
                    placement='right'
                    width='40%'
                    closable={ true }
                    onClose={ () => setDrawerPay( false ) }
                    visible={ drawerPay }
                >
                    <AddNewReturnPay
                        fetchData={ fetchPayment }
                        onClose={ () => setDrawerPay( false ) } />
                </Drawer>
                <Drawer
                    title='Edit Pembayaran Pengembalian'
                    placement='right'
                    width='40%'
                    closable={ true }
                    onClose={ () => setDrawerEditPay( false ) }
                    visible={ drawerEditPay }
                >
                    <EditReturnedPay
                        fetchData={ fetchPayment }
                        onClose={ () => setDrawerEditPay( false ) } />
                </Drawer>
            </div>
        </div>
    )
}

export default PaymentCancelData
