import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;

const AddFee = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ sales, setSales ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ listBlok, setListBlok ] = useState( [] );
    const [ listNumber, setListNumber ] = useState( [] );
    const [ idSales, setIdSales ] = useState( null );
    const [ sisaKomisiSales, setSisaKomisiSales ] = useState( 0 );
    const fetchMarketings = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/sales/data` );
            setSales( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data sales' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchMarketings();
    }, [] );
    useEffect( () =>
    {
        setListNumber( [] );
    }, [ listBlok ] );
    const handleLocationChange = ( location ) =>
    {
        const filterLocation = sales.filter( item => item.lahanUser === location );
        const filteredSales = filterLocation.filter( sales =>
        {
            const totalByrKms = sales.dataKomisi.reduce( ( total, komisi ) => total + komisi.jumlahByrKms, 0 );
            return totalByrKms < sales.totalKomisi;
        } );
        setListBlok( filteredSales );
        if ( filteredSales.length > 0 )
        {
            message.success( `${ filteredSales.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
        form.resetFields( [
            'blokKavUser',
            'noKavUser',
            'jumlahByrKms',
            'tanggalByrKms',
            'metodeByrKms',
            'komisiKe',
            'namaSales',
        ] );
    }
    const handleBlokChange = ( blok ) =>
    {
        const filterBlok = listBlok.filter( item => item.blokKavUser === blok );
        setListNumber( filterBlok );
        if ( filterBlok.length > 0 )
        {
            message.success( `${ filterBlok.length } kavling ditemukan` );
        } else
        {
            message.error( 'Tidak ada kavling ditemukan' );
        }
        form.resetFields( [
            'noKavUser',
            'jumlahByrKms',
            'tanggalByrKms',
            'metodeByrKms',
            'komisiKe',
            'namaSales',
        ] );
    };
    const handleNumberChange = ( number ) =>
    {
        const dataSales = listNumber.find( item => item.noKavUser === number );
        const komisiData = dataSales.dataKomisi;
        const komisiDbyr = komisiData.reduce( ( total, item ) => total + item.jumlahByrKms, 0 );
        form.setFieldsValue( {
            namaSales: dataSales.namaSales,
            komisiKe: komisiData.length + 1,
        } );
        setSisaKomisiSales( dataSales.totalKomisi - komisiDbyr );
        setIdSales( dataSales._id );
    }
    const onFinish = async ( values ) =>
    {
        const newPayment = {
            jumlahByrKms: values.jumlahByrKms,
            tanggalByrKms: dayjs( values.tanggalByrKms ).format( 'DD/MM/YYYY' ),
            komisiKe: values.komisiKe,
            metodeByrKms: values.metodeByrKms,
        }
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/sales/data/${ idSales }/komisi`, newPayment );
            form.resetFields();
            onClose();
            message.success( 'Berhasil menambahkan data komisi' );
            fetchData();
        } catch ( error )
        {
            message.error( `Operation Failed: ${ error.message }` );
        } finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout='vertical'>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='lahanUser'
                        label='Lokasi Kavling'
                        rules={ [ { required: true, message: 'Lokasi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Lokasi Kavling'
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='blokKavUser'
                        label='Blok Kavling'
                        rules={ [ { required: true, message: 'Blok Kavling diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok'
                            style={ { width: '100%' } }
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( listBlok.map( blok => blok.blokKavUser ) ) ]
                                    .map( ( blokKavUser, index ) => (
                                        <Option key={ index } value={ blokKavUser }>
                                            { blokKavUser }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='noKavUser'
                        label='Blok Kavling'
                        rules={ [ { required: true, message: 'Blok Kavling diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok'
                            style={ { width: '100%' } }
                            onChange={ handleNumberChange }>
                            {
                                [ ...new Set( listNumber.map( no => no.noKavUser ) ) ]
                                    .map( ( noKavUser, index ) => (
                                        <Option key={ index } value={ noKavUser }>
                                            { noKavUser }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaSales'
                        label='Nama Marketing'
                    >
                        <Input placeholder='Nama Marketing' readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='komisiKe'
                        label='Komisi Ke'
                    >
                        <InputNumber addonBefore='Ke' readOnly style={ { width: '100%' } } />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='metodeByrKms'
                        label='Metode Pembayaran'
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select placeholder='Pilih Metode' options={ [
                            { value: 'TUNAI', label: 'TUNAI' },
                            { value: 'BRI', label: 'BRI' },
                            { value: 'BNI', label: 'BNI' },
                        ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='jumlahByrKms'
                        label='Jumlah Bayar'
                        rules={ [ { required: true, message: 'Jumlah diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            max={ sisaKomisiSales }
                            placeholder='Masukkan jumlah'
                            style={ { width: '100%' } } />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='tanggalByrKms'
                        label='Tanggal Pembayaran'
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker style={ { width: '100%' } } format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <Button type='primary' htmlType='submit' >
                    Bayar Komisi
                </Button>
            </Form>
        </Spin>
    );
};

export default AddFee;
