import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const { Option } = Select;

const PayLampAndWater = ( { fetchData, onClose } ) =>
{
  const axiosConfig = CreateAxiosInstance();
  const [ form ] = Form.useForm();
  const [ loading, setLoading ] = useState( false );
  const [ clientData, setClientData ] = useState( [] );
  const [ locationData, setLocationData ] = useState( [] );
  const [ blockData, setBlockData ] = useState( [] );
  const [ clientId, setClientId ] = useState( null );

  const fetchClient = async () =>
  {
    try
    {
      setLoading( true );
      const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
      const data = response.data;
      const filteredData = data.filter( item => item.air === 'ya' );
      setClientData( filteredData );
    } catch ( error )
    {
      onClose();
      message.error( 'Gagal mengambil data customer' );
    } finally
    {
      setLoading( false );
    }
  };

  useEffect( () =>
  {
    fetchClient();
  }, [] );

  useEffect( () =>
  {
    setBlockData( [] );
  }, [ locationData ] );

  const handleLocationSelect = ( value ) =>
  {
    const filterLocation = clientData.filter( item => item.lokasiKavling === value );
    const sortLocation = filterLocation.sort( ( a, b ) => a.blokKavling - b.blokKavling );
    if ( filterLocation.length > 0 )
    {
      setLocationData( sortLocation );
      message.success( `${ filterLocation.length } blok ditemukan` );
    } else
    {
      setLocationData( [] );
      message.error( 'Tidak ada blok ditemukan' );
      form.resetFields( [
        'blokKavling',
        'noKavling',
        'jumlahByrAir',
        'jumlahKubik',
        'tanggalByrAir',
        'metodeByrAir',
        'jumlahByrLm',
        'tanggalByrLm',
        'metodeByrLm',
      ] );
    }
  };

  const handleBlockSelect = ( value ) =>
  {
    const filterBlock = locationData.filter( item => item.blokKavling === value );
    const sortBlock = filterBlock.sort( ( a, b ) => a.noKavling - b.noKavling );
    if ( filterBlock.length > 0 )
    {
      setBlockData( sortBlock );
      message.success( `${ filterBlock.length } kavling ditemukan` );
    } else
    {
      setBlockData( [] );
      message.error( 'Tidak ada kavling ditemukan' );
      form.resetFields( [
        'noKavling',
        'jumlahByrAir',
        'jumlahKubik',
        'tanggalByrAir',
        'metodeByrAir',
        'jumlahByrLm',
        'tanggalByrLm',
        'metodeByrLm',
      ] );
    }
  };

  const handleKavlingSelect = ( value ) =>
  {
    const dataPel = blockData.find( item => item._id === value );
    form.setFieldsValue( {
      namaCustomer: dataPel.namaCustomer,
    } );
    setClientId( value );
  };
  const convertToIDR = value =>
  {
    return new Intl.NumberFormat( 'id-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 0
    } ).format( value );
  };
  const onFinish = async ( values ) =>
  {

    if ( !values.jumlahByrAir && !values.jumlahByrLm )
    {
      message.info( 'Tidak ada pembayaran untuk dibayar' );
      return;
    }
    if ( values.jumlahByrAir && !values.metodeByrAir )
    {
      message.error( 'Tentukan metode pembayaran!' )
      return;
    }
    if ( values.metodeByrAir && !values.tanggalByrAir )
    {
      message.error( 'Tentukan tanggal pembayaran!' )
      return;
    }
    if ( values.jumlahByrLm && !values.metodeByrLm )
    {
      message.error( 'Tentukan metode pembayaran!' )
      return;
    }
    if ( values.metodeByrLm && !values.tanggalByrLm )
    {
      message.error( 'Tentukan tanggal pembayaran!' )
      return;
    }
    const payment = {
      ...values,
      tanggalByrAir: values.tanggalByrAir ? dayjs( values.tanggalByrAir ).format( 'DD/MM/YYYY' ) : null,
      tanggalByrLm: values.tanggalByrLm ? dayjs( values.tanggalByrLm ).format( 'DD/MM/YYYY' ) : null,
    };
    const paymentValidate = () =>
    {
      Modal.confirm( {
        title: 'Apakah pembayaran sudah benar?',
        content: (
          <>
            <p style={ { fontSize: 20, fontStyle: 'italic' } }>Air</p>
            <p>Jumlah Kubik: { payment.jumlahKubik }</p>
            <p>Bayar Air: { convertToIDR( payment.jumlahByrAir ) }</p>
            <p>Tanggal Bayar: { dayjs( payment.tanggalByrAir, 'DD/MM/YYYY' ).format( 'dddd, DD MMMM YYYY' ) }</p>
            <p style={ { marginBottom: 20 } }>Metode Bayar: { payment.metodeByrAir }</p>
            { payment.jumlahByrLm && (
              <>
                <p style={ { fontSize: 20, fontStyle: 'italic' } }>Listrik</p>
                <p>Jumlah Bayar: { convertToIDR( payment.jumlahByrLm ) }</p>
                <p>Tanggal Bayar: { dayjs( payment.tanggalByrLm, 'DD/MM/YYYY' ).format( 'dddd DD MMMM YYYY' ) }</p>
                <p>Metode Bayar: { payment.metodeByrLm }</p>
              </>
            ) }
          </>
        ), onOk: async () =>
        {
          try
          {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/customer/${ clientId }/airLampu`, payment );
            form.resetFields();
            fetchClient();
            message.success( 'Berhasil menambahkan pembayaran' );
            fetchData();
            onClose();
          } catch ( error )
          {
            message.error( 'Gagal menambahkan pembayaran' );
          } finally
          {
            setLoading( false );
          }
        }, onCancel ()
        {
          message.info( 'Pembayaran dibatalkan' );
        }
      } );
    };
    paymentValidate();
  };

  return (
    <Spin spinning={ loading }>
      <Form form={ form } layout="vertical" onFinish={ onFinish }>
        <div className="payments-flex">
          <Form.Item
            label="Pilih Lokasi"
            name="lokasiKavling"
            style={ { width: '100%' } }
            rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
          >
            <Select
              style={ { width: '100%' } }
              placeholder="Pilih Lokasi"
              onChange={ handleLocationSelect }
              options={ [
                { value: 'PETAL', label: 'PETAL' },
                { value: 'DOMAS', label: 'DOMAS' },
                { value: 'TANJUNG', label: 'TANJUNG' },
              ] }
            />
          </Form.Item>
          <Form.Item
            label="Pilih Blok"
            name="blokKavling"
            style={ { width: '100%' } }
            rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
          >
            <Select
              style={ { width: '100%' } }
              placeholder="Pilih Blok"
              onChange={ handleBlockSelect }
            >
              {
                [ ...new Set( locationData.map( blok => blok.blokKavling ) ) ]
                  .map( ( blokKavling, index ) => (
                    <Option key={ index } value={ blokKavling }>
                      { blokKavling }
                    </Option>
                  ) )
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="No Kavling"
            style={ { width: '100%' } }
            name="noKavling"
            rules={ [ { required: true, message: 'Pilih kavling!' } ] }
          >
            <Select
              placeholder="Pilih Kavling"
              onChange={ handleKavlingSelect }
              style={ { width: '100%' } }
              options={ blockData.map( c => (
                { value: c._id, label: c.noKavling }
              ) ) }
            />
          </Form.Item>
        </div>
        <div className="payments-flex">
          <Form.Item
            label="Nama Pelanggan"
            name="namaCustomer"
            style={ { width: '100%' } }
          >
            <Input style={ { width: '100%' } } placeholder="Nama Pelanggan" readOnly />
          </Form.Item>
          <Form.Item
            label="Kubik Air"
            name="jumlahKubik"
            style={ { width: '100%' } }
          >
            <InputNumber
              style={ { width: '100%' } }
              addonAfter="Kubik"
              placeholder="Masukkan Jumlah"
            />
          </Form.Item>
        </div>
        <div className="payments-flex">
          <Form.Item
            label="Bayar Air"
            name="jumlahByrAir"
            style={ { width: '100%' } }
          >
            <InputNumber
              style={ { width: '100%' } }
              addonBefore="Rp"
              placeholder="Masukkan Jumlah"
            />
          </Form.Item>
          <Form.Item
            label="Bayar Lampu"
            name="jumlahByrLm"
            style={ { width: '100%' } }
          >
            <InputNumber
              style={ { width: '100%' } }
              addonBefore="Rp"
              placeholder="Masukkan Jumlah"
            />
          </Form.Item>
        </div>
        <div className="payments-flex">
          <Form.Item
            label="Metode Air"
            name="metodeByrAir"
            style={ { width: '100%' } }
          >
            <Select
              style={ { width: '100%' } }
              placeholder="Pilih Opsi"
              options={ [
                { value: 'TUNAI', label: 'TUNAI' },
                { value: 'BRI', label: 'BRI' },
                { value: 'BNI', label: 'BNI' },
              ] }
            />
          </Form.Item>
          <Form.Item
            label="Metode Lampu"
            name="metodeByrLm"
            style={ { width: '100%' } }
          >
            <Select
              style={ { width: '100%' } }
              placeholder="Pilih Opsi"
              options={ [
                { value: 'TUNAI', label: 'TUNAI' },
                { value: 'BRI', label: 'BRI' },
                { value: 'BNI', label: 'BNI' },
              ] }
            />
          </Form.Item>
        </div>
        <div className="payments-flex">
          <Form.Item
            label="Tanggal Air"
            name="tanggalByrAir"
            style={ { width: '100%' } }
          >
            <DatePicker
              style={ { width: '100%' } }
              placeholder="Pilih Tanggal"
              format="DD/MM/YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Tanggal Lampu"
            name="tanggalByrLm"
            style={ { width: '100%' } }
          >
            <DatePicker
              style={ { width: '100%' } }
              placeholder="Pilih Tanggal"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </div>
        <Button htmlType="submit" type="primary">Bayar</Button>
      </Form>
    </Spin>
  );
};

export default PayLampAndWater;
