import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;

const BayarLahan = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ dataLahan, setDataLahan ] = useState( [] );
    const [ filteredLocation, setFilteredLocation ] = useState( [] );
    const [ idLahan, setIdLahan ] = useState( null );
    const fetchListLahan = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data` );
            setDataLahan( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data lahan' );
        } finally
        {
            setLoading( false );
        };
    };
    useEffect( () =>
    {
        fetchListLahan();
    }, [] );
    const handleLokasiLahan = ( value ) =>
    {
        const filterLokasi = dataLahan.filter( item => item.landLocation === value );
        setFilteredLocation( filterLokasi );
        if ( filterLokasi.length > 0 )
        {
            message.success( `${ filterLokasi.length } lahan ditemukan` );
        } else
        {
            message.error( 'Tidak ada lahan ditemukan' );
        }
        form.resetFields( [
            'landCode',
            'farmerName',
            'bayarLhnKe',
            'metodeByrLhn',
            'jumlahByrLhn',
            'kompensasiLhn',
            'tanggalByrLhn',
        ] )
    };
    const handleKodeLahan = ( value ) =>
    {
        const lahanTerpilih = filteredLocation.find( item => item.landCode === value );
        const pembayaranTerakhir = lahanTerpilih.landPayment.filter( item => item.bayarLhnKe )
        form.setFieldsValue( {
            farmerName: lahanTerpilih.farmerName,
            landLocation: lahanTerpilih.landLocation,
            bayarLhnKe: pembayaranTerakhir.length + 1,
        } )
        setIdLahan( lahanTerpilih._id );
    };
    const onFinish = async ( values ) =>
    {
        const minimumData = ( !values.jumlahByrLhn || values.jumlahByrLhn === 0 ) && ( !values.kompensasiLhn || values.kompensasiLhn === 0 );
        if ( minimumData )
        {
            message.info( 'Tidak ada pembayaran untuk ditambahkan' );
            return;
        }

        const paymentData = {
            jumlahByrLhn: values.jumlahByrLhn,
            metodeByrLhn: values.metodeByrLhn,
            tanggalByrLhn: dayjs( values.tanggalByrLhn ).format( 'DD/MM/YYYY' ),
            kompensasiLhn: values.kompensasiLhn,
            bayarLhnKe: values.bayarLhnKe,
        };

        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data/${ idLahan }/data`, paymentData );
            message.success( 'Pembayaran lahan berhasil ditambahkan' );
            await fetchListLahan();
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan pembayaran' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Lokasi Lahan"
                        name="landLocation"
                        rules={ [ { required: true, message: 'Lokasi diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Lokasi Lahan"
                            onChange={ handleLokasiLahan }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG', label: 'TANJUNG' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landCode"
                        label="Kode Lahan Kavling"
                        rules={ [ { required: true, message: 'Kode lahan diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Kode Lahan"
                            onChange={ handleKodeLahan }
                        >
                            { filteredLocation.map( ( lahan ) => (
                                <Option key={ lahan.landCode } value={ lahan.landCode }>
                                    { lahan.landCode }
                                </Option>
                            )
                            ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="farmerName"
                        label="Nama Petani"
                    >
                        <Input placeholder="Nama Petani" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bayarLhnKe"
                        label="Pembayaran Lahan"
                    >
                        <InputNumber
                            addonBefore='Ke' readOnly
                            placeholder='Bayar Lahan Ke'
                            style={ { width: '100%' } } />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeByrLhn"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode Pembayaran diperlukan!' } ] }
                    >
                        <Select placeholder="Pilih Metode">
                            <Option value="TUNAI">TUNAI</Option>
                            <Option value="BRI">BRI</Option>
                            <Option value="BNI">BNI</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrLhn"
                        label="Jumlah Pembayaran"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder="Masukkan Jumlah Pembayaran"
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="kompensasiLhn"
                        label="Kompensasi Pembayaran"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder="Masukkan Kompensasi Pembayaran"
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalByrLhn"
                        label="Tanggal Pembayaran"
                        rules={ [ { required: true, message: 'Tanggal Pembayaran diperlukan!' } ] }
                    >
                        <DatePicker placeholder='Pilih Tanggal' style={ { width: '100%' } } format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit">
                    Bayar Lahan
                </Button>
            </Form>
        </Spin>
    );
};

export default BayarLahan;
