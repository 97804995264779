import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input, Select, InputNumber, DatePicker, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';

const EditLoanPayment = ( { onClose, fetchData, recordId, loanId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );

    useEffect( () =>
    {
        const fetchListData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }` );
                const data = response.data;
                const paymentData = data.riwayatPembayaran.find( item => item._id === loanId );
                form.setFieldsValue( {
                    namaNasabah: data.namaNasabah,
                    paymentDate: dayjs( paymentData.paymentDate, 'DD/MM/YYYY' ),
                    paymentValue: paymentData.paymentValue,
                    paymentMethod: paymentData.paymentMethod,
                } );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchListData();
        }
    }, [ recordId, form, loanId, onClose ] );

    const handleDelete = async () =>
    {
        try
        {
            setLoading( true );
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }/data/${ loanId }` );
            message.success( 'Pembayaran berhasil dihapus' );
            form.resetFields();
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat menghapus pembayaran' );
        } finally
        {
            setLoading( false );
        }
    };

    const onFinish = async ( values ) =>
    {
        if ( !recordId )
        {
            message.error( 'Nasabah tidak ditemukan' );
            return;
        }

        const updatePinjaman = {
            ...values,
            paymentValue: values.paymentValue,
            paymentDate: dayjs( values.paymentDate ).format( 'DD/MM/YYYY' ),
            paymentMethod: values.paymentMethod,
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }/data/${ loanId }`, updatePinjaman );
            form.resetFields();
            fetchData();
            message.success( 'Pembayaran pinjaman berhasil diperbarui' );
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal memperbarui pembayaran pinjaman' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Nama Nasabah"
                        name="namaNasabah"
                        rules={ [ { required: true, message: 'Pilihan diperlukan!' } ] }
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentDate'
                        label='Tanggal Pembayaran'
                        rules={ [ { required: true, message: 'Tanggal pembayaran diperlukan!' } ] }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal Pembayaran'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentValue'
                        label='Jumlah Pembayaran'
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan!' } ] }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            min={ 1 }
                            addonBefore='Rp'
                            placeholder='Masukkan Jumlah Pembayaran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentMethod'
                        label='Metode Pembayaran'
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode Pembayaran'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] } />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' style={ { marginRight: 5 } }>
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus pembayaran ini?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='default' danger>
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin>
    );
};

export default EditLoanPayment;
