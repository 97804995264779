import React from 'react';
import { DatePicker, Form, InputNumber, Button, message } from 'antd';
import dayjs from 'dayjs';

const HitungDenda = () =>
{
    const [ form ] = Form.useForm();

    const onFinish = ( values ) =>
    {
        const { dayStart, dayEnd, penaltyValue } = values;

        if ( !dayStart || !dayEnd || !penaltyValue )
        {
            return message.info( 'Lengkapi form!' );
        }

        const daysDifference = dayjs( dayEnd ).diff( dayjs( dayStart ), 'day' );

        if ( daysDifference < 0 )
        {
            return message.error( 'Tanggal Akhir harus setelah Tanggal Mulai!' );
        }

        const totalPenalty = daysDifference * penaltyValue;

        form.setFieldsValue( { totalPenalty } );
    };

    const onValuesChange = ( changedValues ) =>
    {
        if ( changedValues.dayStart || changedValues.dayEnd || changedValues.penaltyValue )
        {
            form.setFieldsValue( { totalPenalty: null } );
        }
    };

    return (
        <Form form={ form } onFinish={ onFinish } onValuesChange={ onValuesChange }>
            <Form.Item
                name="dayStart"
                label="Tanggal Mulai"
            >
                <DatePicker
                    style={ { width: '100%' } }
                    placeholder="Pilih Tanggal"
                />
            </Form.Item>
            <Form.Item
                name="dayEnd"
                label="Tanggal Akhir"
            >
                <DatePicker
                    style={ { width: '100%' } }
                    placeholder="Pilih Tanggal"
                />
            </Form.Item>
            <Form.Item
                name="penaltyValue"
                label="Denda Perhari"
            >
                <InputNumber
                    style={ { width: '100%' } }
                    min={ 1 }
                    placeholder="Masukkan Nilai Denda"
                />
            </Form.Item>
            <Form.Item
                name="totalPenalty"
                label="Total Denda"
            >
                <InputNumber
                    style={ { width: '100%' } }
                    readOnly
                    placeholder="Lengkapi Form Kosong Untuk Menampilkan Hasil"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" >
                    Hitung Denda
                </Button>
            </Form.Item>
        </Form>
    );
};

export default HitungDenda;
