import React, { useEffect, useState } from 'react'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
import { Button, Drawer, Input, message, Modal, Popconfirm, Table } from 'antd';
import { MdOutlineVpnKeyOff } from "react-icons/md";
import { CiTrash } from 'react-icons/ci';
import CreateAccount from './createAccount';
import { RiUserSearchLine } from "react-icons/ri";
import { SlRefresh } from 'react-icons/sl';
import dayjs from 'dayjs';
import AccessPin from '../pinAccess';
const ListClientAccount = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ listaccount, setListAccount ] = useState( [] );
    const [ drawerCreate, setDrawerCreate ] = useState( false );
    const [ searchName, setSearchName ] = useState( '' );
    const [ modalSignup, setModalSignup ] = useState( false );

    const fetchListAccount = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/auth/client/list-account` );
            const data = response.data;
            setListAccount( data );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    }
    useEffect( () =>
    {
        fetchListAccount();
    }, [] );
    const handleReset = ( clientId, clientName ) =>
    {
        let newPassword = '';
        Modal.confirm( {
            title: 'Masukkan Password Baru',
            content: (
                <Input.Password
                    placeholder={ `AKUN MILIK ${ clientName.slice( 0, 15 ) }` }
                    onChange={ ( e ) => ( newPassword = e.target.value ) }
                />
            ),
            onOk: async () =>
            {
                if ( !newPassword )
                {
                    message.error( 'Password tidak boleh kosong!' );
                    return;
                }

                try
                {
                    await axiosConfig.post( `${ apiUrl }/auth/client/reset-password`, {
                        clientId,
                        newPassword
                    } );

                    message.success( 'Password berhasil direset!' );
                } catch ( error )
                {
                    message.error( 'Gagal mereset password!' );
                    console.error( error );
                }
            },
        } );
    };
    const handleOpenDrawer = () =>
    {
        setDrawerCreate( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerCreate( false );
    };
    const handleDeleteAccount = async ( clientId ) =>
    {
        try
        {
            const response = await axiosConfig.delete( `${ apiUrl }/auth/client/delete-account/${ clientId }` );
            message.success( response.data.message );
            fetchListAccount();
        } catch ( error )
        {
            console.error( error );
            message.error( 'Gagal menghapus akun' );
        }
    };

    const handleRefresh = () =>
    {
        fetchListAccount();
    };
    const handleSearch = ( e ) =>
    {
        const { value } = e.target;
        setSearchName( value );
    };
    const filteredListAccount = listaccount.filter( client =>
        client.clientName.toLowerCase().includes( searchName.toLowerCase() )
    );

    const handleCopyEmail = ( email ) =>
    {
        navigator.clipboard.writeText( email );
        message.success( 'Email disalin ke clipboard' );
    };

    const handleShowModalSignup = () =>
    {
        setModalSignup( true );
    };

    const accountColumns = [
        {
            title: 'Aksi',
            width: '8%',
            align: 'center',
            render: ( ( record ) => (
                <div style={ { display: 'flex', gap: 5, justifyContent: 'center' } }>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus akun ini?"
                        placement='bottom'
                        onConfirm={ () => handleDeleteAccount( record.clientId ) }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type='default' icon={ <CiTrash /> } />
                    </Popconfirm>
                    <Button
                        icon={ <MdOutlineVpnKeyOff /> }
                        type='default'
                        onClick={ () => handleReset( record.clientId, record.clientName ) } />
                </div>
            ) ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Pemilik</p>,
            width: '28vw',
            align: 'left',
            dataIndex: 'clientName',
            render: value => value.slice( 0, 25 ),
            sorter: ( a, b ) => a.clientName.localeCompare( b.clientName ),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Email',
            width: 'auto',
            align: 'center',
            dataIndex: 'clientEmail',
            render: ( email ) => (
                <p style={ { cursor: 'pointer' } } onClick={ () => handleCopyEmail( email ) }>
                    { email }
                </p>
            )
        },
        {
            title: 'Login Status',
            width: 'auto',
            align: 'center',
            dataIndex: 'clientStatus',
            render: value => value === 'Offline'
                ? <p style={ { color: 'red' } }>{ value }</p>
                : <p style={ { color: 'green' } }>{ value }</p>
        },
        {
            title: 'Hari Login',
            width: 'auto',
            align: 'center',
            dataIndex: 'clientLastLoginDay',
            render: value => value ? dayjs( value, 'DD/MM/YYYY' ).format( 'dddd, DD/MM/YYYY' ) : null
        },
        {
            title: 'Hari Logout',
            width: 'auto',
            align: 'center',
            dataIndex: 'clientLastLogoutDay',
            render: value => value ? dayjs( value, 'DD/MM/YYYY' ).format( 'dddd, DD/MM/YYYY' ) : null
        },
    ]
    return (
        <div style={ { margin: '0 5px' } }>
            <div style={ { margin: '10px 0', display: 'flex', gap: 5 } }>
                <Button icon={ <SlRefresh /> } onClick={ handleRefresh } />
                <Button onClick={ handleOpenDrawer } type='primary'>Create Account</Button>
                <Button onClick={ handleShowModalSignup } type='default'>Create PIN</Button>
                <Input onChange={ handleSearch } placeholder='Cari Pemilik Akun' addonBefore={ <RiUserSearchLine /> } />
            </div>
            <Table
                loading={ loading }
                dataSource={ filteredListAccount }
                columns={ accountColumns }
                size='small'
                bordered
            />
            <Drawer
                title="New Account"
                placement="right"
                closable={ true }
                onClose={ handleCloseDrawer }
                visible={ drawerCreate }
                width="35%"
            >
                <CreateAccount
                    onClose={ handleCloseDrawer }
                    fetchData={ fetchListAccount }
                />
            </Drawer>
            <Modal
                title='Buat Pin Akses'
                visible={ modalSignup }
                onCancel={ () => setModalSignup( false ) }
                onOk={ false }
                footer={ null }
            >
                <AccessPin />
            </Modal>
        </div>
    )
}

export default ListClientAccount
