import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Popconfirm } from 'antd';
import '../payments.css';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const EditCashTempo = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const [ dataPembayaran, setDataPembayaran ] = useState( [] );
    const [ paymentId, setPaymentId ] = useState( null );
    const [ selectedYear, setSelectedYear ] = useState( [] );
    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const updateData = response.data
                .filter( f => f.jenisPembelian === 'CASH' || f.jenisPembelian === 'CASH TEMPO' );
            setCustomers( updateData );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        };
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] );
    useEffect( () =>
    {
        setPlotBlockList( [] );
    }, [ plotLocations ] );
    useEffect( () =>
    {
        setSelectedYear( [] );
    }, [ plotBlockList, plotLocations ] );
    const handleDelete = () =>
    {
        axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/cashTempo/${ paymentId }` );
        message.success( 'Berhasil menghapus data pembayaran' );
        fetchCustomers();
        form.resetFields();
        onClose();
        fetchData();
    };
    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasiKavling === value );
        const sortLokasi = lokasiKav.sort( ( a, b ) => a.blokKavling - b.blokKavling );
        setPlotLocations( sortLokasi );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        };
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'namaCustomer',
            'tahunAngsuran',
            'listPembayaran',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] );
    };
    const handleBlokChange = ( value ) =>
    {
        const blokKav = plotLocations.filter( item => item.blokKavling === value );
        const sortBlok = blokKav.sort( ( a, b ) => a.noKavling - b.noKavling );
        setPlotBlockList( sortBlok );
        if ( blokKav.length > 0 )
        {
            message.success( `${ blokKav.length } nomor ditemukan` );
        } else
        {
            message.error( 'Tidak ada nomor ditemukan' );
        };
        form.resetFields( [
            'noKavling',
            'namaCustomer',
            'tahunAngsuran',
            'listPembayaran',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] );
    }
    const handleKavlingNumber = ( value ) =>
    {
        const noKav = plotBlockList.find( ( item ) => item.noKavling === value );
        const tempoData = noKav.pembayaranTempo;
        form.setFieldsValue( {
            namaCustomer: noKav.namaCustomer,
        } );
        setDataPembayaran( tempoData );
        setCustomerId( noKav._id );
        if ( tempoData.length > 0 )
        {
            message.success( 'Data pembayaran ditemukan' );
        } else
        {
            message.error( 'Tidak ada pembayaran ditemukan' );
        };
        form.resetFields( [
            'tahunAngsuran',
            'listPembayaran',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] );
    };
    const handleYearChange = ( date ) =>
    {
        const filterTahun = dataPembayaran.filter( item => dayjs( item.tanggalByrTempo, 'DD/MM/YYYY' ).isSame( date, 'year' ) );
        setSelectedYear( filterTahun );
        if ( filterTahun.length > 0 )
        {
            message.success( `${ filterTahun.length } pembayaran ditemukan` );
        } else
        {
            message.error( 'Tidak ada pembayaran ditemukan' );
        };
        form.resetFields( [
            'listPembayaran',
            'bayarTempoKe',
            'metodeByrTempo',
            'jumlahByrTempo',
            'tanggalByrTempo',
        ] );
    };
    const handleChangePayment = ( value ) =>
    {
        const paymentList = selectedYear.find( item => item._id === value );
        form.setFieldsValue( {
            bayarTempoKe: paymentList.bayarTempoKe,
            jumlahByrTempo: paymentList.jumlahByrTempo,
            tanggalByrTempo: dayjs( paymentList.tanggalByrTempo, 'DD/MM/YYYY' ),
            metodeByrTempo: paymentList.metodeByrTempo,
        } );
        setPaymentId( value );
    };
    const onFinish = async ( values ) =>
    {
        const dataPayment = {
            ...values,
            metodeByrTempo: values.metodeByrTempo,
            jumlahByrTempo: values.jumlahByrTempo,
            tanggalByrTempo: dayjs( values.tanggalByrTempo ).format( 'DD/MM/YYYY' ),
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/cashTempo/${ paymentId }`, dataPayment );
            form.resetFields();
            message.success( 'Pembayaran angsuran berhasil diperbarui' );
            fetchCustomers();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat memperbarui angsuran' );
        }
        finally
        {
            setLoading( false );
        };
    };
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }>
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }>
                            { plotBlockList.map( ( no ) => (
                                <Option key={ no._id } value={ no.noKavling }>
                                    { no.noKavling }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pembeli"
                    >
                        <Input placeholder="Nama Pembeli" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='tahunAngsuran'
                        label="Tahun Angsuran"
                    >
                        <DatePicker.YearPicker
                            style={ { width: '100%' } }
                            onChange={ handleYearChange }
                            placeholder="Pilih Tahun Pembayaran"
                            format='YYYY' />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="listPembayaran"
                        label="List Pembayaran"
                    >
                        <Select
                            placeholder='Pilih List Pembayaran'
                            onChange={ handleChangePayment }
                            allowClear
                            style={ { width: '100%' } }>
                            { selectedYear.map( ( list ) => (
                                <Option key={ list._id } value={ list._id }>
                                    { list.bayarTempoKe }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bayarTempoKe"
                        label="Pembayaran Ke"
                    >
                        <InputNumber addonBefore='Ke' style={ { width: '100%' } } placeholder="Pembayaran ke" />
                    </Form.Item>
                </div>
                <div className='payments-flex' >
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrTempo"
                        label="Jumlah Bayar"
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Pembayaran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeByrTempo"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] } />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="tanggalByrTempo"
                    label="Tanggal Pembayaran"
                    rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                >
                    <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                </Form.Item>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Button type="primary" htmlType="submit">
                        Update Pembayaran
                    </Button>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        { paymentId && (
                            <Button danger type='default' dangerloading={ loading }>
                                Hapus
                            </Button>
                        ) }
                    </Popconfirm>
                </div>

            </Form>
        </Spin >
    );
};

export default EditCashTempo;
