import React, { useState } from 'react';
import { Form, Button, DatePicker, message, Spin, InputNumber, Input, Select } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const AddPTT = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const onFinish = async ( values ) =>
    {
        const pttData = {
            metodePTT: values.metodePTT,
            catatanPTT: values.catatanPTT,
            hargaPTT: values.hargaPTT,
            tanggalPTT: dayjs( values.tanggalPTT ).format( 'DD/MM/YYYY' )
        }
        setLoading( true );
        try
        {
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/additionalExpenses/list`, pttData );
            message.success( 'Penambahan data PTT berhasil' );
            form.resetFields( [
                'metodePTT',
                'catatanPTT',
                'hargaPTT',
            ] );
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat menambahkan data' );
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Pilih Catatan"
                        name="catatanPTT"
                        rules={ [ { required: true, message: 'Catatan diperlukan!' } ] }
                    >
                        <Input placeholder="Masukkan Catatan" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalPTT"
                        label="Tanggal Pengeluaran"
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker
                            placeholder='Pilih Tanggal'
                            style={ { width: '100%' } }
                            format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="hargaPTT"
                        label="Harga"
                        rules={ [ { required: true, message: 'Harga diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Harga' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodePTT"
                        label="Metode Bayar"
                        rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode Bayar'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" >
                    Bayar
                </Button>
            </Form>
        </Spin>
    );
};

export default AddPTT;
