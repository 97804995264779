import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, InputNumber, DatePicker, Spin, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const EditCustomer = ( { onClose, fetchData, recordId } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ kavlingList, setKavlingList ] = useState( [] );
    const [ listBlock, setListBlock ] = useState( [] );
    const [ typePay, setTypePay ] = useState();
    const [ idKavling, setIdKavling ] = useState();
    const [ plotNumbers, setPlotNumbers ] = useState( [] );
    const [ statusBuy, setStatusBuy ] = useState( null );
    useEffect( () =>
    {
        const fetchListKavling = async () =>
        {
            setLoading( true );
            try
            {
                const response1 = await axiosConfig.get( `${ apiUrl }/customer/data/${ recordId }` );
                if ( response1.status !== 200 )
                {
                    onClose();
                    return message.error( 'Data tidak valid' );
                };
                const data1 = response1.data;

                const response2 = await axiosConfig.get( `${ apiUrl }/kavling/list/data/kavling`, {
                    params: {
                        plotBlock: data1.blokKavling,
                        plotNumber: data1.noKavling
                    }
                } );
                const data2 = response2.data;

                form.setFieldsValue( {
                    ...data1,
                    tanggalBooking: dayjs( data1.tanggalBooking, 'DD/MM/YYYY' ).isValid()
                        ? dayjs( data1.tanggalBooking, 'DD/MM/YYYY' )
                        : null,
                    tanggalIJB: dayjs( data1.tanggalIJB, 'DD/MM/YYYY' ).isValid()
                        ? dayjs( data1.tanggalIJB, 'DD/MM/YYYY' )
                        : null,
                    batasAngsuran2: dayjs( data1.batasAngsuran2, 'DD/MM/YYYY' ).isValid()
                        ? dayjs( data1.batasAngsuran2, 'DD/MM/YYYY' )
                        : null,
                } );
                setKavlingList( data1 );
                setIdKavling( data2._id );
            } catch ( error )
            {
                onClose();
                message.error( 'Data customer tidak valid!' );
                console.error( error )
            } finally
            {
                setLoading( false );
            }
        };
        if ( recordId )
        {
            fetchListKavling()
        }
    }, [ form, recordId, onClose ] )

    const handleLokasiKavling = ( value ) =>
    {
        const dataLokasi = kavlingList.filter( ( list ) => list.lokasiKav === value );
        if ( dataLokasi )
        {
            setListBlock( dataLokasi );
            form.resetFields( [
                'blokKavling',

            ] );
        } if ( dataLokasi.length > 0 )
        {
            message.success( `${ dataLokasi.length } blok ditemukan` );
        } else
        {
            message.error( 'Tidak ada blok ditemukan' );
        }
    }
    const handleBlokKavling = ( value ) =>
    {
        const blockList = listBlock.filter( ( data ) => data.block === value );
        if ( blockList )
        {
            setPlotNumbers( blockList );
            form.resetFields( [
                'noKavling',
                'ukuranKavling',
                'luasKavling',
                'hargaKavling',
            ] );
            message.success( `${ blockList.length } nomor ditemukan` );
        } else
        {
            setPlotNumbers( [] );
            message.error( 'Tidak ada data nomor ditemukan' );
        }
    };
    const handleNoKavling = ( value ) =>
    {
        const numbersPlot = plotNumbers.find( ( data ) => data.number === value );
        if ( numbersPlot )
        {
            form.setFieldsValue( {
                ukuranKavling: numbersPlot.sizeKav,
                luasKavling: numbersPlot.luasKav,
                hargaKavling: numbersPlot.hargaKav,
            } );
            setIdKavling( numbersPlot._id );
        } else
        {
            form.resetFields( [
                'ukuranKavling',
                'luasKavling',
                'hargaKavling',
            ] );
            setIdKavling( null );
        };
    };
    useEffect( () =>
    {
    }, [ idKavling ] );
    const handleJenisProperty = () =>
    {
        form.resetFields( [
            'luasBangunanKavling'
        ] )
    }
    const handlePaymentType = ( value ) =>
    {
        form.resetFields( [
            'tenorKavling',
            'angsuranKavling',
            'batasAngsuran2',
            'angsuranKavling2',
        ] )
        setTypePay( value );
    }
    const handleCancel = () =>
    {
        onClose();
    };
    const handleStatusBuy = ( value ) =>
    {
        setStatusBuy( value );
    };
    const onFinish = async ( values ) =>
    {

        const newData = {
            ...values,
            namaCustomer: values.namaCustomer,
            alamatCustomer: values.alamatCustomer,
            telpCustomer: values.telpCustomer,
            lokasiKavling: values.lokasiKavling,
            blokKavling: values.blokKavling,
            jenisProperty: values.jenisProperty,
            tanggalBooking: dayjs( values.tanggalBooking, 'DD/MM/YYYY' ).isValid()
                ? dayjs( values.tanggalBooking ).format( 'DD/MM/YYYY' )
                : null,
            tanggalIJB: dayjs( values.tanggalIJB, 'DD/MM/YYYY' ).isValid()
                ? dayjs( values.tanggalIJB ).format( 'DD/MM/YYYY' )
                : null,
            jenisPembelian: values.jenisPembelian,
            ukuranKavling: values.ukuranKavling,
            luasKavling: values.luasKavling,
            luasBangunanKavling: values.jenisProperty === 'KAVLING' ? undefined : values.luasBangunanKavling,
            hargaKavling: values.hargaKavling - ( values.potonganHarga || 0 ),
            uangMukaKavling: values.uangMukaKavling,
            angsuranKavling: values.angsuranKavling,
            angsuranKavling2: values.angsuranKavling2 ? values.angsuranKavling2 : null,
            tenorKavling: values.tenorKavling,
            jumlahUTJ: ( values.jumlahUTJ || 0 ),
            potonganHarga: ( values.potonganHarga || 0 ),
            noKavling: values.noKavling,
            statusPembelian: values.statusPembelian,
            methodByrUTJ: values.methodByrUTJ,
            air: values.air === 'ya' ? values.air : null,
            dendaCustomer: values.dendaCustomer,
            batasAngsuran2: dayjs( values.batasAngsuran2, 'DD/MM/YYYY' ).isValid()
                ? dayjs( values.batasAngsuran2 ).format( 'DD/MM/YYYY' )
                : null,
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/customer/data/${ recordId }`, newData );
            message.success( 'Data customer berhasil diperbarui' );
            fetchData();
            form.resetFields();
            onClose();
            setIdKavling( null );
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat memproses permintaan.' );
        } finally
        {
            setLoading( false );
        };
    };
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Lokasi Kavling'
                            onChange={ handleLokasiKavling }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok Kavling'
                            onChange={ handleBlokKavling }
                            allowClear
                        >
                            { Array.from( new Set( listBlock.map( list => list.block ) ) ).map( ( block, index ) => (
                                <Option key={ index } value={ block }>
                                    { block }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Pilih Blok Kavling!' } ] }
                    >
                        <Select
                            placeholder='Pilih Blok Kavling'
                            onChange={ handleNoKavling }
                        >
                            { plotNumbers.map( ( list ) => (
                                <Option key={ list._id } value={ list.number }>
                                    { list.number }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="ukuranKavling"
                        label='Ukuran Kavling'
                    >
                        <Input
                            placeholder='Pilih Blok Kavling'
                            readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="hargaKavling"
                        label='Harga Kavling'
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Pilih Blok Kavling' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="luasKavling"
                        label='Luas Kavling'
                    >
                        <Input
                            placeholder='Pilih Blok Kavling'
                            readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="luasBangunanKavling"
                        label="Luas Bangunan"
                        rules={ [
                            {
                                validator: ( _, value ) =>
                                {
                                    if ( !value || /^[0-9,]*$/.test( value ) )
                                    {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject( new Error( 'Gunakan angka / koma!' ) );
                                },
                            },
                        ] }
                    >
                        <Input
                            prefix="M²"
                            style={ { width: '100%' } }
                            placeholder="Masukkan Luas Bangunan"
                        />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pelanggan"
                        rules={ [ { required: true, message: 'Nama diperlukan!' } ] }
                    >
                        <Input placeholder='Masukkan Nama Pelanggan' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="alamatCustomer"
                        label="Alamat Pelanggan"
                        rules={ statusBuy !== 'BATAL' ? [ { required: true, message: 'Alamat diperlukan!' } ]
                            : [ { required: false } ] }
                    >
                        <Input placeholder='Masukkan Alamat Pelanggan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="telpCustomer"
                        label="Telepon Customer"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            prefix='+62'
                            min={ 1 }
                            placeholder='Masukkan Nomor Telepon' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jenisProperty"
                        label="Jenis Properti"
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Properti'
                            onChange={ handleJenisProperty }
                            rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }

                            options={ [
                                { value: 'KAVLING', label: 'KAVLING' },
                                { value: 'RUMAH', label: 'RUMAH' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jenisPembelian"
                        label="Jenis Pembelian"
                        rules={ statusBuy !== 'BATAL' ? [ { required: true, message: 'Opsi diperlukan!' } ]
                            : [ { required: false } ] }
                    >
                        <Select
                            placeholder='Pilih Jenis'
                            allowClear
                            onChange={ handlePaymentType }
                            options={ [
                                { value: 'KREDIT', label: 'KREDIT' },
                                { value: 'CASH', label: 'CASH' },
                                { value: 'CASH TEMPO', label: 'CASH TEMPO' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="uangMukaKavling"
                        label="Uang Muka"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Uang Muka' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="angsuranKavling"
                        label="Angsuran"
                        rules={ typePay === 'KREDIT' || statusBuy !== 'BATAL' ? [ { required: true, message: 'Angsuran diperlukan!' } ] : [ { required: false } ] }
                    >
                        <InputNumber
                            disabled={ typePay !== 'KREDIT' && typePay !== undefined }
                            style={ { width: '100%' } }
                            placeholder='Masukkan Angsuran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tenorKavling"
                        label="Jangka Waktu"
                        rules={ typePay === 'CASH' || !typePay || statusBuy === 'BATAL' ? [ { required: false } ]
                            : [ { required: true, message: 'Angsuran diperlukan!' } ] }
                    >
                        <InputNumber
                            disabled={ typePay === 'CASH' }
                            style={ { width: '100%' } }
                            placeholder='Pilih Jangka Waktu'
                            suffix='Bulan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        label='Batas Angsuran 1'
                        name='batasAngsuran2'
                        style={ { width: '100%' } }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                    <Form.Item
                        label='Angsuran Ke 2'
                        name='angsuranKavling2'
                        tooltip='Opsional'
                        style={ { width: '100%' } }
                    >
                        <InputNumber
                            disabled={ typePay !== 'KREDIT' && typePay !== undefined }
                            style={ { width: '100%' } }
                            placeholder='Masukkan Angsuran' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahUTJ"
                        label="Jumlah UTJ"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah UTJ' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="potonganHarga"
                        label="Jumlah Diskon"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jumlah Potongan' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalBooking"
                        label="Tanggal Booking"
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalIJB"
                        label="Tanggal IJB"
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        name='statusPembelian'
                        label='Status Pembelian'
                        style={ { width: '100%' } }
                    >
                        <Select
                            placeholder='Pilih Status Pembelian'
                            onChange={ handleStatusBuy }
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'BELUM LUNAS', label: 'BELUM LUNAS' },
                                { value: 'LUNAS', label: 'LUNAS' },
                                { value: 'BATAL', label: 'BATAL' },
                            ] } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="methodByrUTJ"
                        label="Metode Bayar UTJ"
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Metode Bayar UTJ'
                            options={ [
                                { value: 'CASH', label: 'CASH' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'BRI', label: 'BRI' }
                            ] } />
                    </Form.Item>
                </div>
                <div className='infocust-flex'>
                    <Form.Item
                        label='Denda'
                        name='dendaCustomer'
                        style={ { width: '100%' } }
                    >
                        <InputNumber placeholder='Denda Customer' style={ { width: '100%' } } />
                    </Form.Item>
                    <Form.Item
                        label='Air'
                        name='air'
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Opsi'
                            options={ [
                                { value: 'ya', label: 'YA' },
                                { value: 'tidak', label: 'TIDAK' },
                            ] } />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' style={ { marginRight: 5 } } >
                            Update
                        </Button>
                    </Popconfirm>
                    <Button style={ { marginLeft: 5 } } onClick={ handleCancel }>Batal</Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default EditCustomer;
