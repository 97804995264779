import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Button, message, Table, Input, InputNumber, Modal } from 'antd';
import { SlRefresh } from 'react-icons/sl';
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
import { useAuth } from '../../../Authentication/contexts/authContext';
dayjs.extend( isSameOrBefore );

const BillingData = () =>
{
    const { userData } = useAuth();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ database, setDatabase ] = useState( [] );
    const [ searchName, setSearchName ] = useState( '' );
    const [ notPaided, setNotPaided ] = useState( 0 );

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/billing/billing-data` );
            const data = response.data;
            const belumDibayar = data.reduce( ( total, item ) => total + item.underPayment, 0 );
            setDatabase( data );
            setNotPaided( convertToIDR( belumDibayar ) )

        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    if ( userData.role !== 'Developer' )
    {
        return navigate( '/underdevelopment-page' );
    }
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const sendWhatsAppMessage = ( customerNumber, messageText ) =>
    {
        const encodedMessage = encodeURIComponent( messageText );
        const fullCustomerNumber = `62${ customerNumber }`;
        const whatsappURL = `https://wa.me/${ fullCustomerNumber }?text=${ encodedMessage }`;
        window.open( whatsappURL, '_blank' );
    };
    const handleSendBilling = async ( recordId ) =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.post( `${ apiUrl }/billing/create-billing`, {
                customerId: recordId,
            } );
            const data = response.data;
            const whatsappMessage =
                `Selamat pagi pak/bu ${ data.customerName },\nIni adalah sebuah pesan pengingat pembayaran angsuran otomatis.\n\nNama : ${ data.customerName }\nKavling :${ data.customerBlock }\nLokasi : ${ data.customerLocation }\n\nIni adalah link detail tagihannya \nhttp://localhost:3000/payment-billing/${ data.uniqueCode }`
            sendWhatsAppMessage( data.customerNumber, whatsappMessage );
        } catch ( error )
        {
            console.error( error );
            message.error( 'Gagal membuat tagihan!' );
        } finally
        {
            setLoading( false );
        }
    };
    const handleSearch = ( e ) =>
    {
        const { value } = e.target;
        setSearchName( value );
    };
    const filteredCustomerData = database.filter( customer =>
        customer.customerName.toLowerCase().includes( searchName.toLowerCase() )
    );
    const billingColumns = [
        {
            title: 'Block',
            dataIndex: 'customerBlock',
            width: '8%',
            align: 'center',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Customer</p>,
            dataIndex: 'customerName',
            align: 'left',
            width: 'auto',
        },
        {
            title: 'Angsuran Bulan Ini',
            dataIndex: 'angsuranCustomer',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value ),
        },
        {
            title: 'Total Tagihan',
            dataIndex: 'underPayment',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value )
        },
        {
            title: 'Aksi',
            width: 'auto',
            align: 'center',
            render: ( value ) => (
                <div style={ { display: 'flex', gap: 5, justifyContent: 'center' } }>
                    <Button
                        type='default'
                        onClick={ () => handleSendBilling( value.customerId ) }
                        disabled={ value.underPayment === 0 }
                    >
                        Tagih
                    </Button>
                    <Button
                        type='default'
                        onClick={ () => handleCheckBilling( value.customerId ) }
                        disabled={ value.underPayment === 0 }
                    >
                        Cek
                    </Button>
                </div>
            )
        },
    ];
    const handleCheckBilling = ( recordId ) =>
    {
        const customer = filteredCustomerData.find( cs => cs.customerId === recordId );
        Modal.info( {
            title: 'Detail Tagihan',
            content: (
                <>
                    <p>Nama : { customer.customerName }</p>
                    <p>Blok : { customer.customerBlock }</p>
                    <p>Jumlah Angsuran : { convertToIDR( customer.angsuranCustomer ) }</p>
                    <p>Angsuran Ke : { customer.angsuranKe }</p>
                    { customer.underPayment > 0 ? (
                        <p>Sisa Angs. Sebelumnya : { convertToIDR( customer.underPayment ) }</p>
                    ) : null }
                    { customer.sisaDenda ? (
                        <p>Denda : { convertToIDR( customer.sisaDenda ) }</p>
                    ) : null }
                </>
            ),
        } )
    }
    return (
        <div style={ { margin: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, margin: '10px 0' } }>
                <Button
                    type='primary'
                    icon={ <SlRefresh /> }
                    onClick={ () => fetchData() }
                    style={ { padding: 5 } }
                />
                <Input
                    addonBefore={ <CiSearch /> }
                    placeholder="Masukkan nama customer"
                    value={ searchName }
                    onChange={ handleSearch }
                />
                <InputNumber
                    addonBefore='Belum Bayar'
                    value={ notPaided }
                    addonAfter='Pelanggan'
                    style={ { width: '100%' } }
                    readOnly />
            </div>
            <Table
                dataSource={ database }
                columns={ billingColumns }
                loading={ loading }
                rowKey="customerId"
                size='small'
                scroll={ { y: 420 } }
            />
        </div>
    );
}

export default BillingData;
