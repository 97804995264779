import { Button, DatePicker, InputNumber, Select, Table } from 'antd';
import { SlRefresh } from 'react-icons/sl';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const CashFlow = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ cashFlowData, setCashFlowData ] = useState( [] );
    const [ action, setAction ] = useState( null );
    const [ selectedDate, setSelectedDate ] = useState( dayjs() );
    const [ totalPengeluaran, setTotalPengeluaran ] = useState( 0 );
    const [ totalPemasukan, setTotalPemasukan ] = useState( 0 );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ filterJenis, setFilterJenis ] = useState( [] );
    const fetchCashFlow = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/statistic/data/finance` )
            setCashFlowData( response.data );
            setFilterJenis( getUniqueJenis( response.data ) );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };
    const getUniqueJenis = ( cashFlowData ) =>
    {
        const uniqueTypes = [ ...new Set( cashFlowData.map( item => item.jenis ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchCashFlow();
    }, [] );
    const handleChangeDate = ( date ) =>
    {
        if ( date )
        {
            setSelectedDate( date );
        } else
        {
            setSelectedDate( dayjs() );
        }
    };
    const handleSelectedAct = ( act ) =>
    {
        if ( act )
        {
            setAction( act );
        } else
        {
            setAction( null )
        }
    };
    useEffect( () =>
    {
        const dateSelected = dayjs( selectedDate, 'DD/MM/YYYY' )
        const filterData = cashFlowData.filter( f =>
            dayjs( f.tanggal, 'DD/MM/YYYY' ).isSame( dateSelected, ( action ? action : 'day' ) ) )

        const pengeluaran = filterData
            .filter( f => f.jenis === 'Pengeluaran' )
            .reduce( ( total, item ) => total + ( item.jumlah || 0 ), 0 );
        const pemasukan = filterData
            .filter( f => f.jenis === 'Pemasukan' )
            .reduce( ( total, item ) => total + ( item.jumlah || 0 ), 0 );
        const sortData = filterData
            .map( fd => ( {
                keterangan: fd.keterangan,
                jenis: fd.jenis,
                jumlah: fd.jumlah,
                tanggal: dayjs( fd.tanggal, 'DD/MM/YYYY' ).format( 'DD/MM/YYYY' ),
                metode: fd.metode,
            } ) )
        setTotalPengeluaran( pengeluaran );
        setTotalPemasukan( pemasukan );
        setFilteredData( sortData );
    }, [ action, cashFlowData, selectedDate ] );
    const cashflowColumns = [
        {
            title: <p style={ { textAlign: 'center' } }>Keterangan</p>,
            dataIndex: 'keterangan',
            key: 'keterangan',
            align: 'left',
            render: value => value.toUpperCase(),
        },
        {
            title: 'Jenis',
            dataIndex: 'jenis',
            key: 'jenis',
            align: 'center',
            width: '10%',
            filters: filterJenis,
            onFilter: ( value, record ) => record.jenis.includes( value ),
        },
        {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'center',
            width: '15%',
            render: value => convertToIDR( value ),
        },
        {
            title: 'Tanggal',
            dataIndex: 'tanggal',
            key: 'tanggal',
            align: 'center',
            width: '15%',
            sorter: ( a, b ) => dayjs( a.tanggal, 'DD/MM/YYYY' ).diff( dayjs( b.tanggal, 'DD/MM/YYYY' ) ),
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Metode',
            dataIndex: 'metode',
            key: 'metode',
            align: 'center',
            width: '15%',
        },
    ];
    return (
        <div style={ { margin: '0 5px' } }>
            <div style={ { margin: '10px 0', display: 'flex', gap: 5, alignItems: 'center' } }>
                <Button
                    type='primary'
                    icon={ <SlRefresh /> }
                    onClick={ () => fetchCashFlow() }
                    style={ { padding: 5 } }
                />
                <Select
                    style={ { width: '20%' } }
                    placeholder='Pilih Tipe'
                    onChange={ handleSelectedAct }
                    options={ [
                        { value: 'day', label: 'Harian' },
                        { value: 'week', label: 'Mingguan' },
                        { value: 'month', label: 'Bulanan' },
                        { value: 'year', label: 'Tahunan' },
                    ] } />
                { action === 'day' && (
                    <DatePicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Hari'
                        picker='day'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'dddd' ) }
                    />
                ) }
                { action === 'week' && (
                    <DatePicker.WeekPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Minggu'
                        picker='week'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'dddd' ) }
                    />
                ) }
                { action === 'month' && (
                    <DatePicker.MonthPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Bulan'
                        picker='month'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    />
                ) }
                { action === 'year' && (
                    <DatePicker.YearPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Tahun'
                        picker='year'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'YYYY' ) }
                    />
                ) }
                <InputNumber
                    readOnly
                    style={ { width: '100%' } }
                    addonBefore={ totalPengeluaran > 0 && 'Pengeluaran' }
                    value={ totalPengeluaran > 0 && convertToIDR( totalPengeluaran ) }
                    placeholder={ totalPengeluaran < 1 && 'Tidak ada uang keluar' } />
                <InputNumber
                    readOnly
                    style={ { width: '100%' } }
                    addonBefore={ totalPemasukan > 0 && 'Pemasukan' }
                    value={ totalPemasukan > 0 && convertToIDR( totalPemasukan ) }
                    placeholder={ totalPemasukan < 1 && 'Tidak ada uang masuk' } />
            </div>
            <Table
                dataSource={ filteredData }
                columns={ cashflowColumns }
                size='small'
                loading={ loading }
                showSorterTooltip={ false }
                scroll={ { y: 400 } }
            />
        </div>
    );
};

export default CashFlow;
