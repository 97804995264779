import React, { useEffect, useState } from 'react';
import { Button, Drawer, InputNumber, Popconfirm, Table, message } from 'antd';
import dayjs from 'dayjs';
import { CiEdit } from 'react-icons/ci';
import { SlRefresh } from "react-icons/sl";
import { AiOutlineDelete } from 'react-icons/ai';
import 'dayjs/locale/id';
import NewPlotList from './KavlingBaru';
import EditPlotList from './EditKavling';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const PlotListData = () =>
{
    const [ data, setData ] = useState( [] );
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerTambah, setDrawerTambah ] = useState( false );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const [ filterStatus, setFilterStatus ] = useState( [] );
    const [ remainingKavling, setRemainingKavling ] = useState( 0 );
    const [ soldKavling, setSoldKavling ] = useState( 0 );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/data` );
            const data = response.data;
            const kavlingReady = data.filter( item => item.statusKav === 'READY' )
            const kavlingTerjual = data.filter( item => item.statusKav === 'SOLD' )
            setData( data );
            setRemainingKavling( kavlingReady.length );
            setSoldKavling( kavlingTerjual.length );
            setFilterLocation( getUniqueLocation( data ) );
            setFilterStatus( getUniqueStatus( data ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };
    const getUniqueLocation = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.lokasiKav ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueStatus = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.statusKav ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <div style={ {
                    display: 'flex',
                    gap: 5,
                    justifyContent: 'center'
                } }>
                    <Button type='default' onClick={ () => handleOpenEdit( record._id ) } icon={ <CiEdit /> } />
                    <Popconfirm
                        title="Anda yakin ingin menghapus kavling ini?"
                        onConfirm={ () => handleDelete( record._id ) }
                        okText="Ya"
                        cancelText="Tidak"
                    >
                        <Button danger type='default' icon={ <AiOutlineDelete /> } />
                    </Popconfirm>
                </div>
            ),
        },
        {
            title: 'Blok',
            dataIndex: 'block',
            align: 'center',
            width: 'auto',
        },
        {
            title: 'Nomor',
            dataIndex: 'number',
            width: 'auto',
            align: 'center',
            sorter: ( a, b ) => ( a.number ) - ( b.number ),
        },
        {
            title: 'Harga Kavling',
            dataIndex: 'hargaKav',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value ),
            sorter: ( a, b ) => ( a.hargaKav ) - ( b.hargaKav ),
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasiKav',
            width: 'auto',
            align: 'center',
            filters: filterLocation,
            onFilter: ( value, record ) => record.lokasiKav.includes( value ),
        },
        {
            title: 'Luas',
            dataIndex: 'luasKav',
            width: 'auto',
            align: 'center',
            render: value => value + ' M²',
        },
        {
            title: 'Ukuran',
            dataIndex: 'sizeKav',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'statusKav',
            width: 'auto',
            align: 'center',
            filters: filterStatus,
            onFilter: ( value, record ) => record.statusKav.includes( value ),
        },
    ];
    const handleCloseAdd = () =>
    {
        setDrawerTambah( false );
    };
    const handleOpenAdd = () =>
    {
        setDrawerTambah( true );
    };
    const handleOpenEdit = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEdit( true );
    };
    const handleCloseEdit = () =>
    {
        setDrawerEdit( false );
        setEditRecordId( null );
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    const handleDelete = async ( recordId ) =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/kavling/list/${ recordId }` );
            if ( response.status === 200 )
            {
                message.success( 'Kavling berhasil dihapus' );
                fetchData();
            } else
            {
                message.error( 'Gagal menghapus kavling' );
            }
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat menghapus data' );
        } finally
        {
            setLoading( false );
        }
    };
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleOpenAdd }>
                        Tambah Kavling
                    </Button>
                    <InputNumber
                        addonBefore={ remainingKavling > 0 && 'Ready' }
                        style={ { width: '100%' } }
                        placeholder={ remainingKavling < 1 && 'Tidak Ada Kavling Ready' }
                        value={ remainingKavling > 0 && `${ remainingKavling } Unit` }
                        readOnly
                    />
                    <InputNumber
                        addonBefore={ soldKavling > 0 && 'Sold' }
                        style={ { width: '100%' } }
                        value={ soldKavling > 0 && `${ soldKavling } Unit` }
                        placeholder={ soldKavling < 1 && 'Tidak Ada Kavling Terjual' }
                        readOnly
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ data }
                    rowKey="_id"
                    loading={ loading }
                    size="small"
                    scroll={ { y: 470 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Kavling"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEdit }
                    visible={ drawerEdit }
                    width="50%"
                >
                    <EditPlotList
                        recordId={ editRecordId }
                        onClose={ handleCloseEdit }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Kavling Baru"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseAdd }
                    visible={ drawerTambah }
                    width="40%"
                >
                    <NewPlotList onClose={ handleCloseAdd } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default PlotListData;
