import React, { useState, useEffect } from 'react';
import { Form, Button, DatePicker, message, Spin, InputNumber, Input, Popconfirm, Select } from 'antd';
import dayjs from 'dayjs'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const EditPTT = ( { onClose, fetchData, recordId } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    useEffect( () =>
    {
        const fetchListPTT = async () =>
        {
            setLoading( true );
            try
            {
                const response1 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/additionalExpenses/list/${ recordId }` )
                const data1 = response1.data;
                form.setFieldsValue( {
                    metodePTT: data1.metodePTT,
                    catatanPTT: data1.catatanPTT,
                    hargaPTT: data1.hargaPTT,
                    tanggalPTT: dayjs( data1.tanggalPTT, 'DD/MM/YYYY' ),
                } )
                setLoading( false );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };
        if ( recordId )
        {
            fetchListPTT();
        }
    }, [ recordId, form, onClose ] );
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/additionalExpenses/list/${ recordId }` );
            onClose();
            message.success( 'Berhasil menghapus data' );
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menghapus data' );
        }
    }
    const onFinish = async ( values ) =>
    {
        const pttData = {
            ...values,
            catatanPTT: values.catatanPTT,
            hargaPTT: values.hargaPTT,
            tanggalPTT: dayjs( values.tanggalPTT ).format( 'DD/MM/YYYY' )
        }
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/additionalExpenses/list/${ recordId }`, pttData );
            message.success( 'Pembaruan data PTT berhasil' );
            form.resetFields();
            setLoading( false );
            onClose();
            fetchData();
        } catch ( error )
        {
            setLoading( false );
            console.error( 'Terjadi kesalahan saat menyimpan data:', error );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Pilih Catatan"
                        name="catatanPTT"
                        rules={ [ { required: true, message: 'Catatan diperlukan!' } ] }
                    >
                        <Input placeholder="Masukkan Catatan" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalPTT"
                        label="Tanggal Gaji"
                        rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                    >
                        <DatePicker
                            placeholder='Pilih Tanggal'
                            style={ { width: '100%' } }
                            format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="hargaPTT"
                        label="Harga"
                        rules={ [ { required: true, message: 'Harga diperlukan!' } ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Harga' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodePTT"
                        label="Metode Bayar"
                        rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode Bayar'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' loading={ loading }>
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='default' danger loading={ loading }>
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin>
    );
};

export default EditPTT;
