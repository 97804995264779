import React, { useEffect, useState } from 'react';
import { Card, message, notification, Progress, Spin, Statistic } from 'antd';
import { useAuth } from '../Authentication/contexts/authContext';
import './home.css';
import { RiEqualLine } from "react-icons/ri";
import dayjs from 'dayjs';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from '../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrBefore );

const Homepage = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const { userData, isLoading } = useAuth();
    const [ loading, setLoading ] = useState( false );
    const [ currentTime, setCurrentTime ] = useState( dayjs() );
    const [ allData, setAllData ] = useState( [] );
    const [ validated, setValidated ] = useState( false );
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            setLoading( true );
            try
            {
                const loginValidate = await axiosConfig.post( `${ apiUrl }/api/auth/validate-status` );
                if ( loginValidate.status === 200 )
                {
                    setValidated( true );
                    try
                    {
                        const response = await axiosConfig.get( `${ apiUrl }/statistic/data/home` );
                        setAllData( response.data );
                    } catch ( error )
                    {
                        message.error( 'Gagal memuat data' );
                    }
                }
            } catch ( error )
            {
                console.error( error );
            } finally
            {
                setLoading( false );
            }
        };

        fetchData();
    }, [] );

    useEffect( () =>
    {
        if ( validated && !isLoading )
        {
            if ( userData )
            {
                notification.open( {
                    message: 'Selamat Datang!',
                    description: `Halo ${ userData.name }, selamat datang di aplikasi kami!`,
                    placement: 'topRight',
                } );
            }
        }
    }, [ userData, validated, isLoading ] );

    useEffect( () =>
    {
        const intervalId = setInterval( () =>
        {
            setCurrentTime( dayjs() );
        }, 1000 );
        return () => clearInterval( intervalId );
    }, [] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const conicColors = {
        '0%': '#87d068',
        '50%': '#ffe58f',
        '100%': '#ffccc7',
    };
    return (
        <div>
            <Spin spinning={ loading }>
                <div className='homeDate'>
                    <Statistic title="Waktu Sekarang" value={ currentTime.format( 'HH:mm:ss' ) } />
                    <Statistic title="Hari" value={ currentTime.format( 'dddd' ) } />
                    <Statistic title="Bulan" value={ currentTime.format( 'MMMM' ) } />
                    <Statistic
                        title="Tahun"
                        value={ currentTime.format( 'YYYY' ) }
                        formatter={ value => value.replace( /,/g, '' ) }
                    />
                </div>
                <div className='homeSect'>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='Total Uang Masuk Hari Ini'
                                value={ convertToIDR( allData.totalPemasukanHariIni || 0 ) } />
                        </div>
                    </Card>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='Total Uang Keluar Hari Ini'
                                value={ convertToIDR( allData.totalPengeluaranHariIni || 0 ) } />
                        </div>
                    </Card>
                </div>
                <div className='homeSect'>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='User Belum Bayar Angsuran Bulanan'
                                value={ allData.userBlmByrAngs || 0 }
                                suffix={ `/ ${ allData.listUsrByrAngs || 0 }` } />
                            <Statistic title='Angsuran Bulan Ini' value={ convertToIDR( allData.totalByrAngsNow || 0 ) } />
                            <Statistic
                                title='Jumlah Tunggakan Angsuran Bulanan'
                                value={ convertToIDR( allData.totalKrgByrBlnIni || 0 ) }
                                prefix={ allData.totalKrgByrBlnBfr === allData.totalKrgByrBlnNow
                                    ? <RiEqualLine style={ { color: 'blue' } } />
                                    : allData.totalKrgByrBlnBfr > allData.totalKrgByrBlnNow
                                        ? <ArrowUpOutlined style={ { color: 'green' } } />
                                        : <ArrowDownOutlined style={ { color: 'red' } } /> } />
                            <Statistic
                                title='Jumlah Angsuran Terbayar Bulanan'
                                value={ convertToIDR( allData.totalByrAngsNow || 0 ) }
                                suffix={ `/ ${ convertToIDR( allData.totalAngsuranShrsny || 0 ) }` }
                                prefix={ allData.totalByrAngsBfr === allData.totalByrAngsNow
                                    ? <RiEqualLine style={ { color: 'blue' } } />
                                    : allData.totalByrAngsBfr > allData.totalByrAngsNow
                                        ? <ArrowDownOutlined style={ { color: 'red' } } />
                                        : <ArrowUpOutlined style={ { color: 'green' } } /> } />
                            <Progress percent={ allData.percentAngsFix } strokeColor={ conicColors } />
                        </div>
                    </Card>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='User Belum Lunas DP'
                                value={ allData.userBlmByrDpNow || 0 }
                                suffix={ `/ ${ allData.totalUserDpNow || 0 }` } />
                            <Statistic title='Jumlah Bayar DP Bulan Ini' value={ convertToIDR( allData.totalByrDpNow || 0 ) } />
                            <Statistic title='Jumlah Tunggakan Dp' value={ convertToIDR( allData.totalKrgDpNow || 0 ) } />
                            <Statistic title='Jumlah Dp Terbayar' value={ convertToIDR( allData.totalAllByrDpNow || 0 ) }
                                suffix={ `/ ${ convertToIDR( allData.totalDPShrsnyNow || 0 ) }` } />
                            <Progress percent={ allData.percentDPFix } strokeColor={ conicColors } />
                        </div>
                    </Card>
                </div>
            </Spin>
        </div>
    );
};

export default Homepage;
