import React, { useEffect, useState } from 'react';
import { Button, DatePicker, InputNumber, Table } from 'antd';
import dayjs from 'dayjs';
import { SlRefresh } from 'react-icons/sl';
import CreateAxiosInstance from '../../Authentication/contexts/axiosConfig';

const DataLogs = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ logData, setLogData ] = useState( [] );
    const [ selectedDate, setSelectedDate ] = useState( dayjs() );
    const [ tableData, setTableData ] = useState( [] );
    const [ actionCount, setActionCount ] = useState( 0 );
    const fetchLog = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.get( `${ apiUrl }/logsautomatic` );
            const data = response.data
                .map( ( item ) => ( {
                    ...item,
                    action: `[ ${ item.userName } ]  ${ item.action.toUpperCase() }`,
                    dateAction: item.dateAction,
                } ) )
                .sort( ( a, b ) => dayjs( b.dateAction, 'DD/MM/YYYY HH:mm:ss' ).unix() - dayjs( a.dateAction, 'DD/MM/YYYY HH:mm:ss' ).unix() );

            setLogData( data );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchLog();
    }, [] );
    const handleRefresh = () =>
    {
        fetchLog();
    };
    const handleChangeDate = ( date ) =>
    {
        if ( date )
        {
            setSelectedDate( date );
        } else
        {
            setSelectedDate( dayjs() );
        }
    };

    useEffect( () =>
    {
        const filterData = logData.filter( f =>
            dayjs( f.dateAction, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' )
        );
        setActionCount( filterData.length );
        setTableData( filterData );
    }, [ logData, selectedDate ] );

    const logColumns = [
        {
            title: <p style={ { textAlign: 'center' } }>Riwayat</p>,
            align: 'left',
            width: 'auto',
            dataIndex: 'action',
        },
        {
            title: 'Waktu',
            width: '10%',
            align: 'center',
            dataIndex: 'dateAction',
        }
    ];

    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                <Button onClick={ handleRefresh } icon={ <SlRefresh /> } style={ { padding: 5 } } />
                <DatePicker
                    style={ { width: '100%' } }
                    placeholder='Pilih Tanggal'
                    picker='day'
                    onChange={ handleChangeDate }
                    format='DD/MM/YYYY'
                />
                <InputNumber style={ { width: '100%' } } readOnly
                    addonAfter={ actionCount > 0 && 'Tindakan' }
                    value={ actionCount > 0 && actionCount }
                    placeholder={ actionCount < 1 && 'Tidak Ada Tindakan' }
                />
            </div>
            <Table
                dataSource={ tableData }
                columns={ logColumns }
                size='small'
                scroll={ { y: 400 } }
                rowKey='_id'
                bordered
                loading={ loading }
            />
        </div>
    );
};

export default DataLogs;
