import React, { useEffect, useState } from "react";
import { Descriptions, message, Typography, List, DatePicker } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import CreateAxiosInstance from "../../../Authentication/contexts/axiosConfig";
dayjs.locale( 'id' );

const DataLahanCek = ( { recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ dataLahan, setDataLahan ] = useState( null );
    const [ pembayaran, setPembayaran ] = useState( [] );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );
    const fetchDataById = async () =>
    {
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data/${ recordId }`, {
                params: {
                    selectedYear: dayjs( selectedYear ).format( 'DD/MM/YYYY' ),
                }
            } );
            const { updatedData, paymentData } = response.data;
            setDataLahan( updatedData );
            setPembayaran( paymentData );
        } catch ( error )
        {
            onclose();
            message.error( "Gagal mengambil data dari server" );
        }
    };

    const handleYearChange = ( date ) =>
    {

        setSelectedYear( date )
    };

    useEffect( () =>
    {
        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId ] );

    useEffect( () =>
    {
        fetchDataById();
    }, [ selectedYear ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    if ( !dataLahan )
    {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Descriptions title={ `Data Lahan ${ dataLahan.farmerName }` } column={ 1 }>
                <Descriptions.Item label="Harga Lahan">{ convertToIDR( dataLahan.landPrice ) }</Descriptions.Item>
                { dataLahan.surfaceArea ? (
                    <Descriptions.Item label="Luas Lahan">{ dataLahan.surfaceArea }</Descriptions.Item>
                ) : null }
                <Descriptions.Item label="Lokasi Lahan">{ dataLahan.landLocation }</Descriptions.Item>
                <Descriptions.Item label="Kode Lahan">{ dataLahan.landCode }</Descriptions.Item>
                { dataLahan.timePeriod ? (
                    <Descriptions.Item label="Tenor Lahan">{ dataLahan.timePeriod }</Descriptions.Item>
                ) : null }
                { dataLahan.seriousDateSign ? (
                    <Descriptions.Item label="Tanggal Tanda Serius">{ dayjs( dataLahan.seriousDateSign ).format( 'DD/MM/YYYY' ) }</Descriptions.Item>
                ) : null }
                { dataLahan.seriousPaySign ? (
                    <Descriptions.Item label="Jumlah Tanda Serius">{ convertToIDR( dataLahan.seriousPaySign ) }</Descriptions.Item>
                ) : null }
                <Descriptions.Item label="Sudah Terbayar">{ convertToIDR( dataLahan.amountPaid ) }</Descriptions.Item>
                <Descriptions.Item label="Belum Terbayar">{ convertToIDR( dataLahan.remainingPayment ) }</Descriptions.Item>
                <Descriptions.Item label="Total Kompensasi">{ convertToIDR( dataLahan.amountCommissionPaid ) }</Descriptions.Item>
            </Descriptions>
            <DatePicker.YearPicker
                placeholder="Pilih Tahun Pembayaran"
                onChange={ handleYearChange }
                style={ { width: '100%', marginBottom: 10 } } />
            <List
                bordered
                dataSource={ pembayaran }
                header='Riwayat Pembayaran'
                renderItem={ ( item ) => (
                    <List.Item>
                        <div>
                            { item.tanggalByrLhn ? (
                                <>
                                    <Typography.Text strong>Tanggal Pembayaran Lahan: </Typography.Text>{ dayjs( item.tanggalByrLhn, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.jumlahByrLhn ? (
                                <>
                                    <Typography.Text strong>Jumlah Pembayaran Lahan: </Typography.Text>{ convertToIDR( item.jumlahByrLhn ) }<br />
                                </>
                            ) : null }
                            { item.metodeByrLhn ? (
                                <>
                                    <Typography.Text strong>Metode Pembayaran Lahan: </Typography.Text>{ item.metodeByrLhn }<br />
                                </>
                            ) : null }
                            { item.bayarLhnKe ? (
                                <>
                                    <Typography.Text strong>Bayar Lahan Ke: </Typography.Text>{ item.bayarLhnKe }<br />
                                </>
                            ) : null }
                            { item.kompensasiLhn ? (
                                <>
                                    <Typography.Text>Kompensasi Pembayaran Lahan: </Typography.Text>{ convertToIDR( item.kompensasiLhn ) }<br />
                                </>
                            ) : null }
                        </div>
                    </List.Item>
                ) }
            />
        </div>
    );
};

export default DataLahanCek;
