import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, List, Spin, InputNumber, DatePicker, Select, Popconfirm, Input } from 'antd';
import { SlRefresh } from "react-icons/sl";
import AddFee from './tambahKomisi';
import { CiEdit, CiSquareInfo } from 'react-icons/ci'
import EditFee from './editKomisi';
import AddNewFinance from './customerBaru';
import DataKomisiCek from './cekDataKomisi';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { PiCursorClickThin } from 'react-icons/pi';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const KomisiPenjualan = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ drawerAddFee, setDrawerAddFee ] = useState( false );
    const [ drawerEditFee, setDrawerEditFee ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ loading, setLoading ] = useState( false );
    const [ drawerCheckData, setDrawerCheckData ] = useState( false );
    const [ filterLokasiUser, setFilterLokasiUser ] = useState( '' );
    const [ drawerNewFee, setDrawerNewFee ] = useState( false );
    const [ dataSales, setDataSales ] = useState( [] );
    const [ selectedAction, setSelectedAction ] = useState();
    const [ sisaKomisi, setSisaKomisi ] = useState( 0 );
    const [ searchName, setSearchName ] = useState( '' );
    const fetchData = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/sales/data` );
            const data = response.data;
            const updateData = data.map( db =>
            {
                const komisiTerbayar = db.dataKomisi.reduce( ( total, p ) => total + p.jumlahByrKms, 0 );
                return {
                    nama: db.namaSales,
                    blok: ( db.blokKavUser === 'TANPABLOK' ? db.noKavUser : db.blokKavUser + db.noKavUser ),
                    lokasi: db.lahanUser,
                    totalKomisi: db.totalKomisi,
                    komisiDbyr: komisiTerbayar,
                    sisaKms: db.totalKomisi - komisiTerbayar,
                    _id: db._id

                };
            } );
            const totalSisaKomisi = updateData.reduce( ( total, item ) => total + item.sisaKms, 0 );
            setSisaKomisi( totalSisaKomisi );
            setDataSales( updateData );
            setFilterLokasiUser( getUniqueLahanUser( updateData ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };

    const getUniqueLahanUser = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.lokasi ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            maximumFractionDigits: 0,
            currency: 'IDR',
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleRefresh = () =>
    {
        fetchData();
    };
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarKomisi' )
        {
            handleAddPaym();
        } if ( selectedAction === 'newClient' )
        {
            handleNewCust();
        } if ( selectedAction === 'editKomisi' )
        {
            handleEditFee();
        }
    }
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <Button
                    type='default'
                    onClick={ () => handleCheckData( record._id ) }
                    icon={ <CiSquareInfo /> }
                />
            ),
        },
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '7%',
            sorter: ( a, b ) =>
            {
                const aBlok = a.blok;
                const bBlok = b.blok;

                if ( !isNaN( aBlok ) && !isNaN( bBlok ) )
                {
                    return Number( aBlok ) - Number( bBlok );
                }

                if ( typeof aBlok === 'string' && typeof bBlok === 'string' )
                {
                    return aBlok.localeCompare( bBlok );
                }

                return typeof aBlok === 'number' ? -1 : 1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend'
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Marketing</p>,
            dataIndex: 'nama',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Lahan',
            dataIndex: 'lokasi',
            width: 'auto',
            align: 'center',
            filters: filterLokasiUser,
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Rincian Komisi',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Komisi',
                    dataIndex: 'totalKomisi',
                    align: 'center',
                    width: 'auto',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Dibayar',
                    dataIndex: 'komisiDbyr',
                    align: 'center',
                    width: 'auto',
                    render: value => convertToIDR( value ),
                    sorter: ( a, b ) => a.komisiDbyr - b.komisiDbyr,
                },
                {
                    title: 'Sisa',
                    dataIndex: 'sisaKms',
                    align: 'center',
                    width: 'auto',
                    render: value => value > 0 ? convertToIDR( value ) : "LUNAS",
                    sorter: ( a, b ) => a.sisaKms - b.sisaKms,
                },
            ]
        },
    ];
    const handleCheckData = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerCheckData( true );
    };

    const handleCloseCheckData = () =>
    {
        setDrawerCheckData( false );
        setEditRecordId( null );
    };

    const handleEditFee = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEditFee( true );
    };
    const handleCloseEditDrawer = () =>
    {
        setDrawerEditFee( false );
        setEditRecordId( null );
    };
    const handleAddPaym = () =>
    {
        setDrawerAddFee( true );
    };

    const handleCloseDrawer = () =>
    {
        setDrawerAddFee( false );
    };
    const handleNewCust = () =>
    {
        setDrawerNewFee( true );
    };
    const handleCloseNewDrawer = () =>
    {
        setDrawerNewFee( false );
    };
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/sales/data/${ editRecordId }` );
            message.success( 'Berhasil menghapus seluruh komisi' );
            fetchData();
            setDrawerCheckData( false )
        } catch ( error )
        {
            message.error( 'Gagal menghapus seluruh komisi' );
        }
    };
    const handleSearch = ( e ) =>
    {
        const { value } = e.target;
        setSearchName( value );
    };
    const filteredSalesData = dataSales.filter( customer =>
        customer.nama.toLowerCase().includes( searchName.toLowerCase() )
    );
    return (
        <div>
            <div style={ {
                padding: '0 5px'
            } }>
                <div style={ {
                    display: 'flex',
                    gap: 5,
                    padding: '10px 0'
                } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        style={ { padding: 5 } }
                        type='primary'
                        onClick={ handleSelectAction }
                        icon={ <PiCursorClickThin /> } />
                    <Select
                        style={ { width: '30%' } }
                        placeholder='Pilih Aksi'
                        onChange={ handleSelectChange }
                        allowClear
                        options={ [
                            { value: 'newClient', label: 'Pembeli Baru' },
                            { value: 'bayarKomisi', label: 'Bayar Komisi' },
                            { value: 'editKomisi', label: 'Edit Komisi' },
                        ] } />
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        value={ sisaKomisi >= 1 && convertToIDR( sisaKomisi ) }
                        addonBefore={ sisaKomisi >= 1 && 'Total komisi' }
                        placeholder={ sisaKomisi < 1 && 'Tidak ada sisa komisi yang perlu dibayar' } />
                    <Input
                        placeholder='Cari Sales'
                        onChange={ handleSearch }
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ filteredSalesData }
                    rowKey="_id"
                    loading={ loading }
                    size="small"
                    scroll={ { y: 420 } }
                />
            </div>
            <div>
                <Drawer
                    title="Bayar Komisi"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawer }
                    visible={ drawerAddFee }
                    width="50%"
                >
                    <AddFee
                        onClose={ handleCloseDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Edit Komisi"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditDrawer }
                    visible={ drawerEditFee }
                    width="50%"
                >
                    <EditFee
                        onClose={ handleCloseEditDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Cek Detail Komisi"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseCheckData }
                    visible={ drawerCheckData }
                    width="50%"
                    extra={ (
                        <Popconfirm
                            title="Apakah kamu yakin melakukan update data ini ?"
                            onConfirm={ handleDelete }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='default' danger >
                                Hapus
                            </Button>
                        </Popconfirm>
                    ) }
                >
                    <DataKomisiCek
                        recordId={ editRecordId }
                        fetchData={ fetchData }
                        onClose={ handleCloseCheckData }
                    />
                </Drawer>
                <Drawer
                    title="Komisi Customer Baru"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseNewDrawer }
                    visible={ drawerNewFee }
                    width="40%"
                >
                    <AddNewFinance onClose={ handleCloseNewDrawer } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default KomisiPenjualan;
