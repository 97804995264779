import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, DatePicker, InputNumber } from 'antd';
import BayarGaji from './drawerBayarGaji';
import { FiTrash } from "react-icons/fi";
import DrawerEditGajiTanggal from './drawerEditGajiTanggal';
import { CiSquareInfo } from 'react-icons/ci';
import DrawerEditGajiHarian from './drawerEditGajiHarian';
import DrawerEditGajiLembur from './drawerEditGajiLembur';
import DataGajiCek from './cekDataGaji';
import dayjs from 'dayjs'
import 'dayjs/locale/id';
import { SlRefresh } from 'react-icons/sl';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const DataGajiTukang = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEditTanggal, setDrawerEditTanggal ] = useState( false );
    const [ drawerEditGajiHarian, setDrawerEditGajiHarian ] = useState( false );
    const [ drawerEditGajiLembur, setDrawerEditGajiLembur ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerBayarGaji, setDrawerBayarGaji ] = useState( false );
    const [ drawerCekGaji, setDrawerCekGaji ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalSalary, setTotalSalary ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data` );
            const data = response.data;
            const updatedData = data.map( db => ( {
                ...db,
                totalGajiMingguan: db.totalGajiMingguan || 0,
                totalGajiLembur: db.totalGajiLembur || 0,
            } ) );
            setData( updatedData );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }

    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    const filteredData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.tanggalGaji, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const totalBayarGaji = filteredData.reduce( ( total, item ) =>
            {
                return total + item.totalPembayaranGaji;
            }, 0 );
            setTotalSalary( totalBayarGaji );
        } else
        {
            const totalPengeluaranGajiTukang = data.reduce( ( total, item ) =>
            {
                return total + item.totalPembayaranGaji;
            }, 0 );
            setTotalSalary( totalPengeluaranGajiTukang );
        }
    }, [ filteredData, selectedMonth, data ] );
    const handleRefresh = () =>
    {
        fetchData();
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '7%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleCekGaji( record._id ) }
                    icon={ <CiSquareInfo /> } />
            ),
        },
        {
            title: 'Tanggal Gaji',
            dataIndex: 'tanggalGaji',
            align: 'center',
            width: 'auto',
        },
        {
            title: 'Uang Makan',
            dataIndex: 'uangMakanTukang',
            width: 'auto',
            align: 'center',
            render: value => value ? convertToIDR( value ) : undefined,
        },
        {
            title: 'Gaji Mingguan',
            dataIndex: 'totalGajiMingguan',
            width: 'auto',
            align: 'center',
            render: value => value ? convertToIDR( value ) : undefined,
        },
        {
            title: 'Gaji Lemburan',
            dataIndex: 'totalGajiLembur',
            width: 'auto',
            align: 'center',
            render: value => value ? convertToIDR( value ) : undefined,
        },
        {
            title: 'Total',
            dataIndex: 'totalPembayaranGaji',
            width: 'auto',
            align: 'center',
            render: value => value ? convertToIDR( value ) : undefined,
        },
    ];
    const handleCekGaji = ( recordId ) =>
    {
        setEditRecordId( recordId );
        setDrawerCekGaji( true );
    };
    const handleCloseCekGaji = () =>
    {
        setDrawerCekGaji( false );
        setEditRecordId( null );
    };
    const handleBayarGaji = () =>
    {
        setDrawerBayarGaji( true );
    };
    const handleCloseBayarGaji = () =>
    {
        setDrawerBayarGaji( false );
    };
    const handleEditItem = ( recordId ) =>
    {
        setEditRecordId( recordId );
        setDrawerEditTanggal( true );
    };
    const handleCloseEditDrawer = () =>
    {
        setDrawerEditTanggal( false );
        setEditRecordId( null );
    };
    const handleEditGajiHarian = ( recordId ) =>
    {
        setEditRecordId( recordId );
        setDrawerEditGajiHarian( true );
    };
    const handleCloseEditGajiHarian = () =>
    {
        setDrawerEditGajiHarian( false );
        setEditRecordId( null );
    };
    const handleEditGajiLembur = ( recordId ) =>
    {
        setEditRecordId( recordId );
        setDrawerEditGajiLembur( true );
    };
    const handleDeleteGaji = async ( recordId ) =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }` );
            fetchData();
            message.success( 'Berhasil menghapus gaji tukang' );
        } catch ( error )
        {
            message.error( 'Gagal menghapus gaji tukang' );
        }
    };
    const handleCloseEditGajiLembur = () =>
    {
        setDrawerEditGajiLembur( false );
        setEditRecordId( null );
    };

    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: '5px', padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleBayarGaji }>Bayar Gaji</Button>
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        placeholder={ totalSalary < 1 && 'TIdak ada pembayaran gaji' }
                        value={ totalSalary >= 1 && convertToIDR( totalSalary ) }
                        addonBefore={ totalSalary >= 1 && `Gaji Bulan ${ dayjs( selectedMonth ).format( 'MMMM YYYY' ) }` }
                    />
                    <DatePicker.MonthPicker
                        style={ { width: '30%' } }
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker="month"
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        placeholder='Pilih Bulan'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    dataSource={ filteredData }
                    loading={ loading }
                    rowKey="_id"
                    pagination={ false }
                    size='small'
                    expandable={ {
                        expandedRowRender: ( record ) => (
                            <div style={ { display: 'flex', gap: 5, justifyContent: 'right' } }>
                                <Button type="dashed" onClick={ () => handleEditItem( record._id ) }>
                                    Edit Uang Makan
                                </Button>
                                <Button type="dashed" onClick={ () => handleEditGajiHarian( record._id ) }>
                                    Edit Gaji Mingguan
                                </Button>
                                <Button type="dashed" onClick={ () => handleEditGajiLembur( record._id ) }>
                                    Edit Gaji Lembur
                                </Button>
                                <Button type="dashed" danger onClick={ () => handleDeleteGaji( record._id ) }
                                    icon={ <FiTrash /> } />
                            </div>
                        ),
                    } }
                />
            </div>
            <div>
                <Drawer
                    title="Bayar Gaji"
                    placement="bottom"
                    closable={ true }
                    onClose={ handleCloseBayarGaji }
                    visible={ drawerBayarGaji }
                    width="50%"
                >
                    <BayarGaji onClose={ handleCloseBayarGaji } fetchData={ fetchData } />
                </Drawer>
                {/* Edit Tanggal Gaji */ }
                <Drawer
                    title="Edit Gaji"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditDrawer }
                    visible={ drawerEditTanggal }
                    width='30%'
                >
                    <DrawerEditGajiTanggal
                        recordId={ editRecordId }
                        onClose={ handleCloseEditDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                {/* Edit Gaji Mingguan */ }
                <Drawer
                    title="Edit Gaji Mingguan"
                    placement="bottom"
                    closable={ true }
                    onClose={ handleCloseEditGajiHarian }
                    visible={ drawerEditGajiHarian }
                >
                    <DrawerEditGajiHarian
                        recordId={ editRecordId }
                        onClose={ handleCloseEditGajiHarian }
                        fetchData={ fetchData }
                    />
                </Drawer>
                {/* Edit Gaji Lembur */ }
                <Drawer
                    title="Edit Gaji Lembur"
                    placement="bottom"
                    closable={ true }
                    onClose={ handleCloseEditGajiLembur }
                    visible={ drawerEditGajiLembur }
                >
                    <DrawerEditGajiLembur
                        recordId={ editRecordId }
                        onClose={ handleCloseEditGajiLembur }
                        fetchData={ fetchData }
                    />
                </Drawer>
                {/* Drawer Cek Gaji */ }
                <Drawer
                    title="Cek Data Gaji"
                    placement="bottom"
                    closable={ true }
                    onClose={ handleCloseCekGaji }
                    visible={ drawerCekGaji }
                >
                    <DataGajiCek
                        recordId={ editRecordId }
                        onClose={ handleCloseCekGaji }
                        fetchData={ fetchData }
                    />
                </Drawer>
            </div>
        </div>
    );
};

export default DataGajiTukang;
