import React, { useEffect, useState } from 'react';
import { Table, Button, DatePicker, Drawer, Select, message, InputNumber } from 'antd';
import { PiCursorClickThin } from 'react-icons/pi';
import dayjs from 'dayjs';
import EditAngsuran from './AngsuranEdit';
import * as XLSX from 'xlsx';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import BayarAngsuran from './AngsuranBayar';
import { SlRefresh } from 'react-icons/sl';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );
dayjs.extend( isSameOrBefore );
const CustomersTable = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ kalkulasiData, setKalkulasiData ] = useState( 0 );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const [ drawerPayment, setDrawerPayment ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ filterMethod, setFilterMethod ] = useState( [] );

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/customer/angsuran/data`, {
                params: {
                    thisMonth: dayjs( selectedMonth ).format( 'DD/MM/YYYY' )
                },
            } );
            const data = response.data;
            const { dataAngsuranFilter, totalByrAngs, totalBlmByr, totalSelisih, methodList, locationList } = data;
            setFilteredData( dataAngsuranFilter );
            setKalkulasiData( { totalByrAngs, totalBlmByr, totalSelisih } );
            setFilterMethod( methodList )
            setFilterLocation( locationList )
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    const handleSelectMonth = ( value ) =>
    {
        setSelectedMonth( value );
    }
    useEffect( () =>
    {
        fetchData()
    }, [] );
    useEffect( () =>
    {
        fetchData();
    }, [ selectedMonth ] );

    const handleRefresh = () =>
    {
        fetchData();
    };

    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        };
    };

    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarAngsuran' )
        {
            handlePayment();
        } if ( selectedAction === 'editAngsuran' )
        {
            handleEdit();
        } if ( selectedAction === 'export' )
        {
            exportToExcel();
        };
    };

    const handleEdit = () =>
    {
        setDrawerEdit( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerEdit( false );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const handlePayment = () =>
    {
        setDrawerPayment( true );
    };

    const handleClosePayment = () =>
    {
        setDrawerPayment( false );
    };

    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '8%',
            sorter: ( a, b ) =>
            {
                const regex = /^(\d+|[a-zA-Z]+)(\d+|[a-zA-Z]*)$/;
                const aMatch = a.blok.match( regex );
                const bMatch = b.blok.match( regex );

                if ( !aMatch || !bMatch ) return 0;

                const aPart1 = aMatch[ 1 ];
                const aPart2 = aMatch[ 2 ] || '';
                const bPart1 = bMatch[ 1 ];
                const bPart2 = bMatch[ 2 ] || '';

                if ( isNaN( aPart1 ) && isNaN( bPart1 ) )
                {
                    if ( aPart1 !== bPart1 )
                    {
                        return aPart1.localeCompare( bPart1 );
                    }
                    return aPart2.localeCompare( bPart2 );
                };

                if ( !isNaN( aPart1 ) && !isNaN( bPart1 ) )
                {
                    const numCompare = parseInt( aPart1 ) - parseInt( bPart1 );
                    if ( numCompare !== 0 )
                    {
                        return numCompare;
                    }
                    return aPart2.localeCompare( bPart2 );
                };
                return isNaN( aPart1 ) ? 1 : -1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nama',
            align: 'left',
            width: 'auto',
            sorter: ( a, b ) => a.nama.localeCompare( b.nama ),
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            align: 'center',
            width: '10%',
            filters: filterLocation.map( location => ( { text: location, value: location } ) ),
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Angsuran',
            dataIndex: 'angsuran',
            align: 'center',
            width: '10%',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Jumlah Tenor',
            align: 'center',
            children: [
                {
                    title: 'Tenor',
                    dataIndex: 'tenor',
                    align: 'center',
                    width: '5%',
                },
                {
                    title: 'Sisa',
                    dataIndex: 'sisaTenor',
                    align: 'center',
                    width: '5%',
                },
            ],
        },
        {
            title: 'Pembayaran',
            align: 'center',
            children: [
                {
                    title: 'Ke',
                    dataIndex: 'angs',
                    align: 'center',
                    width: '5%',
                },
                {
                    title: 'Jumlah Bayar',
                    dataIndex: 'jumlahByr',
                    align: 'center',
                    width: '10%',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Tanggal Bayar',
                    dataIndex: 'tanggalByr',
                    align: 'center',
                    width: '10%',
                    sorter: ( a, b ) => dayjs( a.tanggalByr, 'DD/MM/YYYY' ) - dayjs( b.tanggalByr, 'DD/MM/YYYY' ),
                },
                {
                    title: 'Metode',
                    dataIndex: 'metodeByr',
                    align: 'center',
                    width: '7%',
                    filters: filterMethod.map( method => ( { text: method, value: method } ) ),
                    onFilter: ( value, record ) => record.metodeByr.includes( value ),
                }
            ],
        },
    ];
    const exportToExcel = () =>
    {
        if ( !filteredData || filteredData.length === 0 )
        {
            message.error( 'Tidak ada data untuk diekspor' );
            return;
        }

        const ws = XLSX.utils.json_to_sheet( filteredData.map( item => ( {
            'Blok': item.blok,
            'Nama Pelanggan': item.nama,
            'Angsuran': item.angsuran,
            'Tenor': item.tenor,
            'Sisa': item.sisaTenor,
            'Ke': item.angs,
            'Jumlah Bayar': item.jumlahByr,
            'Tanggal Bayar': item.tanggalByr,
            'Metode Bayar': item.metodeByr,
        } ) ), { header: [ "Blok", "Nama Pelanggan", "Angsuran", "Tenor", "Sisa", "Ke", "Jumlah Bayar", "Tanggal Bayar", "Metode Bayar" ] } );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, 'Data Customers' );
        XLSX.writeFile( wb, `Data Angsuran ${ selectedMonth.format( 'MMMM YYYY' ) }.xlsx` );
    };
    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                <Button
                    style={ { padding: 5 } }
                    onClick={ handleRefresh }
                    icon={ <SlRefresh /> } />

                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleSelectAction }
                    icon={ <PiCursorClickThin /> } />

                <Select
                    style={ { width: '50%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    allowClear
                    options={ [
                        { value: 'bayarAngsuran', label: 'Bayar Angsuran' },
                        { value: 'editAngsuran', label: 'Edit Angsuran' },
                        { value: 'export', label: 'Ekspor Ke Excel' },
                    ] } />

                <InputNumber
                    style={ { width: '100%' } }
                    value={ kalkulasiData.totalByrAngs >= 1 && convertToIDR( kalkulasiData.totalByrAngs ) }
                    placeholder={ kalkulasiData.totalByrAngs < 1 && 'Tidak Ada Angsuran Terbayar' }
                    addonBefore={ kalkulasiData.totalByrAngs >= 1 && 'Terbayar' }
                    readOnly />

                <InputNumber
                    style={ { width: '100%' } }
                    value={ kalkulasiData.totalBlmByr >= 1 && convertToIDR( kalkulasiData.totalBlmByr ) }
                    placeholder={ kalkulasiData.totalBlmByr < 1 && 'Tidak Ada Angsuran Tertagih' }
                    addonBefore={ kalkulasiData.totalBlmByr >= 1 && 'Tertagih' }
                    readOnly />

                <InputNumber
                    style={ { width: '100%' } }
                    value={ kalkulasiData.totalSelisih < 0 ? convertToIDR( kalkulasiData.totalSelisih )
                        : kalkulasiData.totalSelisih > 0 ? convertToIDR( kalkulasiData.totalSelisih ) : null
                    }
                    placeholder={ kalkulasiData.totalSelisih === 0 && 'Tidak Ada Selisih' }
                    addonBefore={ kalkulasiData.totalSelisih < 0 ? 'Selisih'
                        : kalkulasiData.totalSelisih > 0 ? 'Lebihan' : null
                    }
                    readOnly />

                <DatePicker.MonthPicker
                    style={ { width: '50%' } }
                    onChange={ handleSelectMonth }
                    allowClear={ false }
                    value={ selectedMonth }
                    picker="month"
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    placeholder='Pilih Bulan'
                />
            </div>
            <Table
                size="small"
                scroll={ { y: 420 } }
                dataSource={ filteredData }
                columns={ columns }
                rowKey="_id"
                loading={ loading }
                showSorterTooltip={ false }
            />
            <Drawer
                title="Edit Angsuran"
                placement="right"
                closable={ true }
                onClose={ handleCloseDrawer }
                visible={ drawerEdit }
                width='40%'
            >
                <EditAngsuran
                    onClose={ handleCloseDrawer }
                    fetchData={ fetchData }
                />
            </Drawer>
            <Drawer
                title="Bayar Angsuran"
                placement="right"
                closable={ true }
                onClose={ handleClosePayment }
                visible={ drawerPayment }
                width='40%'
            >
                <BayarAngsuran
                    onClose={ handleClosePayment }
                    fetchData={ fetchData }
                />
            </Drawer>
        </div>
    );
};

export default CustomersTable;
