import React, { useState, useEffect } from 'react';
import { Table, Select, message, Button, DatePicker, InputNumber } from 'antd';
import { PiMicrosoftExcelLogoThin } from "react-icons/pi";
import { SlRefresh } from 'react-icons/sl';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrAfter );
dayjs.extend( isSameOrBefore );

const PiutangCust = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const [ dataPelanggan, setDataPelanggan ] = useState( [] );
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalUangMasuk, setTotalUangMasuk ] = useState( 0 );
    const [ totalPiutang, setTotalPiutang ] = useState( 0 );
    const [ lokasiTerpilih, setLokasiTerpilih ] = useState( null );
    const fetchData = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.get( `${ apiUrl }/customer/piutang/data`, {
                params: {
                    selectedMonth: dayjs( selectedMonth ).format( 'DD/MM/YYYY' ),
                    selectedLocation: lokasiTerpilih,
                },
            } );
            const { filteredTable, TPT, TUM } = response.data;
            setDataPelanggan( filteredTable );
            setTotalPiutang( TPT );
            setTotalUangMasuk( TUM );
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    useEffect( () =>
    {
        fetchData();
    }, [ selectedMonth, lokasiTerpilih ] )
    const handleSelectLocation = ( value ) =>
    {
        setLokasiTerpilih( value );
    }
    const handleSelectMonth = ( date ) =>
    {
        setSelectedMonth( date );
    };

    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '7%',
            sorter: ( a, b ) =>
            {
                const regex = /^([a-zA-Z]*)(\d*)$/;

                const aMatch = a.blok.match( regex ) || [];
                const bMatch = b.blok.match( regex ) || [];

                const aText = aMatch[ 1 ] || '';
                const aNumber = aMatch[ 2 ] !== '' ? parseInt( aMatch[ 2 ], 10 ) : 0;

                const bText = bMatch[ 1 ] || '';
                const bNumber = bMatch[ 2 ] !== '' ? parseInt( bMatch[ 2 ], 10 ) : 0;

                if ( aText < bText ) return -1;
                if ( aText > bText ) return 1;
                return aNumber - bNumber;
            },
            sortDirections: [ 'ascend', 'descend' ],
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nama',
            width: 'auto',
            align: 'left',
            sorter: ( a, b ) => a.nama.localeCompare( b.nama ),
        },
        {
            title: 'Uang Masuk',
            dataIndex: 'uangMasuk',
            width: '20%',
            align: 'center',
            sorter: ( a, b ) => a.uangMasuk - b.uangMasuk,
            render: value => convertToIDR( value ),
        },
        {
            title: 'Piutang',
            dataIndex: 'piutang',
            width: '20%',
            align: 'center',
            sorter: ( a, b ) => a.piutang - b.piutang,
            render: value => convertToIDR( value ),
        },
    ]
    const exportToExcel = () =>
    {
        const ws = XLSX.utils.json_to_sheet( dataPelanggan.map( item => ( {
            'Blok': item.blok,
            'Nama Pelanggan': item.nama,
            'Uang Masuk': item.uangMasuk,
            'Piutang': item.piutang,
        } ) ), {
            header: [ "Blok", "Nama Pelanggan", "Uang Masuk", "Piutang" ]
        } );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, `${ lokasiTerpilih ? `Piutang ${ lokasiTerpilih }` : 'Piutang Pelanggan' }` );
        XLSX.writeFile( wb, `${ lokasiTerpilih ? `Piutang Pelanggan - ${ lokasiTerpilih }` : 'Piutang Pelanggan' }.xlsx` );
    };
    const handleRefresh = () =>
    {
        fetchData();
    }

    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { padding: '10px 0' } }>
                    <div style={ {
                        display: 'flex',
                        gap: 5
                    } }>
                        <Button
                            style={ { padding: 5 } }
                            onClick={ handleRefresh }
                            icon={ <SlRefresh /> } />
                        <InputNumber
                            style={ { width: '100%' } }
                            value={ totalUangMasuk >= 1 && convertToIDR( totalUangMasuk ) }
                            placeholder={ totalUangMasuk < 1 && 'Tidak ada uang masuk' }
                            addonBefore={ totalUangMasuk >= 1 && 'Uang Masuk' }
                            readOnly />
                        <InputNumber
                            style={ { width: '100%' } }
                            value={ totalPiutang >= 1 && convertToIDR( totalPiutang ) }
                            placeholder={ totalPiutang < 1 && 'Tidak ada piutang' }
                            addonBefore={ totalPiutang >= 1 && 'Piutang' }
                            readOnly />
                        <DatePicker.MonthPicker
                            style={ { width: '35%' } }
                            onChange={ handleSelectMonth }
                            allowClear={ false }
                            value={ selectedMonth }
                            picker="month"
                            format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                            placeholder='Pilih Bulan'
                        />
                        <Select
                            style={ { width: '25%' } }
                            placeholder='Lokasi'
                            onChange={ handleSelectLocation }
                            allowClear
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                        <Button
                            style={ { padding: 10 } }
                            onClick={ exportToExcel }
                            icon={ <PiMicrosoftExcelLogoThin /> } />
                    </div>
                </div>
                <Table
                    columns={ columns }
                    dataSource={ dataPelanggan }
                    scroll={ { y: 420 } }
                    bordered
                    size="small"
                    loading={ loading }
                    showSorterTooltip={ false }
                />
            </div>
        </div>
    );
};

export default PiutangCust;
