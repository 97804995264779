import React from 'react';
import { Result } from 'antd';
import InputAccessPin from './inputAccessPin';

const LimitAccess = () =>
{
    return (
        <div>
            <Result
                status="403"
                title="Not Working Hours!"
                subTitle="Aplikasi tidak dapat diakses di luar jam kerja."
            />
            <InputAccessPin />
        </div>
    );
};

export default LimitAccess;
