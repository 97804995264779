import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Drawer, InputNumber, message, Select, Spin, Table } from 'antd';
import PayLampAndWater from './bayarAirLampu'; // Komponen harus dimulai dengan huruf besar
import dayjs from 'dayjs';
import { SlRefresh } from 'react-icons/sl';
import EditPaymentWaterAndLamp from './editAirLampu';
import { PiCursorClickThin } from 'react-icons/pi';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const WaterAndElectricData = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ drawerByrAirLampu, setDrawerByrAirLampu ] = useState( false );
    const [ tableData, setTableData ] = useState( [] );
    const [ totalTerbayar, setTotalTerbayar ] = useState( 0 );
    const [ selectedLocation, setSelectedLocation ] = useState( null );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const fetchData = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/airlampu/data`, {
                params: {
                    selectedMonth: dayjs( selectedMonth ).format( 'DD/MM/YYYY' ),
                    selectedLocation,
                }
            } );
            const { filterData, totalBayarAir, totalBayarLampu } = response.data;
            setTableData( filterData );
            setTotalTerbayar( totalBayarAir + totalBayarLampu );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data customer' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    const handleSelectLocation = ( value ) =>
    {
        setSelectedLocation( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [ selectedMonth, selectedLocation ] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '8%',
            sorter: ( a, b ) =>
            {
                const regex = /^(\d+|[a-zA-Z]+)(\d+|[a-zA-Z]*)$/;
                const aMatch = a.blok.match( regex );
                const bMatch = b.blok.match( regex );
                if ( !aMatch || !bMatch ) return 0;
                const aPart1 = aMatch[ 1 ];
                const aPart2 = aMatch[ 2 ] || '';
                const bPart1 = bMatch[ 1 ];
                const bPart2 = bMatch[ 2 ] || '';
                if ( isNaN( aPart1 ) && isNaN( bPart1 ) )
                {
                    if ( aPart1 !== bPart1 )
                    {
                        return aPart1.localeCompare( bPart1 );
                    }
                    return aPart2.localeCompare( bPart2 );
                }
                if ( !isNaN( aPart1 ) && !isNaN( bPart1 ) )
                {
                    const numCompare = parseInt( aPart1 ) - parseInt( bPart1 );
                    if ( numCompare !== 0 )
                    {
                        return numCompare;
                    }
                    return aPart2.localeCompare( bPart2 );
                }
                return isNaN( aPart1 ) ? 1 : -1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nameClient',
            width: '23vw',
            align: 'left',
        },
        {
            title: 'Total Kubik',
            width: 'auto',
            align: 'center',
            dataIndex: 'kubikTotal'
        },
        {
            title: 'Pembayaran',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Air',
                    dataIndex: 'waterPayment',
                    width: 'auto',
                    align: 'center',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Kubik',
                    dataIndex: 'waterMeters',
                    width: 'auto',
                    align: 'center',
                },
                {
                    title: 'Tanggal Air',
                    dataIndex: 'waterDate',
                    width: '10%',
                    align: 'center',
                },
                {
                    title: 'Metode Air',
                    dataIndex: 'waterPayMethod',
                    width: 'auto',
                    align: 'center',
                },
                {
                    title: 'Lampu',
                    dataIndex: 'lampPayment',
                    width: 'auto',
                    align: 'center',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Tanggal Lampu',
                    dataIndex: 'lampDate',
                    width: '10%',
                    align: 'center',
                },
                {
                    title: 'Metode Lampu',
                    dataIndex: 'lampPayMethod',
                    width: 'auto',
                    align: 'center',
                },
            ]
        }
    ];
    const handleEdit = () =>
    {
        setDrawerEdit( true );
    }
    const handleCloseEdit = () =>
    {
        setDrawerEdit( false );
    }
    const handleOpenPayDrawer = () =>
    {
        setDrawerByrAirLampu( true );
    };

    const handleClosePayDrawer = () =>
    {
        setDrawerByrAirLampu( false );
    };
    const handleRefresh = () =>
    {
        fetchData();
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayar' )
        {
            handleOpenPayDrawer();
        }; if ( selectedAction === 'edit' )
        {
            handleEdit();
        };
    };
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        };
    };
    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, alignItems: 'center', padding: '10px 0' } }>
                <Button
                    style={ { padding: 5 } }
                    onClick={ handleRefresh }
                    icon={ <SlRefresh /> } />
                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleSelectAction }
                    icon={ <PiCursorClickThin /> } />
                <Select
                    style={ { width: '30%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    allowClear
                    options={ [
                        { value: 'bayar', label: 'Bayar' },
                        { value: 'edit', label: 'Edit' },
                    ] } />
                <InputNumber
                    readOnly
                    style={ { width: '100%' } }
                    addonBefore={ totalTerbayar >= 1 && 'Terbayar' }
                    placeholder={ totalTerbayar < 1 && 'Tidak ada pembayaran air / listrik' }
                    value={ totalTerbayar >= 1 && convertToIDR( totalTerbayar ) } />
                <DatePicker.MonthPicker
                    style={ { width: '30%' } }
                    onChange={ handleMonthChange }
                    value={ selectedMonth }
                    picker="month"
                    allowClear={ false }
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    placeholder='Pilih Bulan'
                />
                <Select
                    placeholder='Pilih Lokasi'
                    allowClear
                    style={ { width: '30%' } }
                    onChange={ handleSelectLocation }
                    options={ [
                        { value: 'PETAL', label: 'PETAL' },
                        { value: 'DOMAS', label: 'DOMAS' },
                        { value: 'TANJUNG', label: 'TANJUNG' },
                    ] } />
            </div>
            <Table
                size="small"
                pagination={ { pageSize: 20 } }
                scroll={ { y: 430 } }
                dataSource={ tableData }
                columns={ columns }
                rowKey="_id"
                loading={ loading }
                showSorterTooltip={ false }
            />
            <Drawer
                title="Bayar Air / Lampu"
                placement="right"
                closable={ true }
                onClose={ handleClosePayDrawer }
                visible={ drawerByrAirLampu }
                width='40%'
            >
                <PayLampAndWater
                    onClose={ handleClosePayDrawer }
                    fetchData={ fetchData }
                />
            </Drawer>
            <Drawer
                title="Edit Pembayaran Air / Lampu"
                placement="right"
                closable={ true }
                onClose={ handleCloseEdit }
                visible={ drawerEdit }
                width='40%'
            >
                <EditPaymentWaterAndLamp
                    onClose={ handleCloseEdit }
                    fetchData={ fetchData }
                />
            </Drawer>
        </div>
    );
};

export default WaterAndElectricData;
